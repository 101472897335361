import i18n from "../../translations/i18n";
import {ErrorCode} from "react-dropzone";

export const acceptableFileTypes = {
    'application/pdf': [],
    'application/zip': ['.zip'],
    'application/msword': ['.doc'],
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
    'image/jpeg': [],
    'image/png': [],
    'application/x-zip-compressed': [],
    'multipart/x-zip': [],
};

export const maxFiles = 1;

export const maxSize = 41943040; // 40 MB


export interface DropzoneErrorsType {
    [key: string]: string;
}

export const dropzoneErrors: DropzoneErrorsType = {
    [ErrorCode.FileInvalidType]: i18n.t('dropzone.fileTypeError'),
    [ErrorCode.TooManyFiles]: i18n.t('dropzone.fileTooManyError'),
    [ErrorCode.FileTooLarge]: i18n.t('dropzone.fileTooLarge'),
    [ErrorCode.FileTooSmall]: i18n.t('dropzone.fileTooSmall'),
};
