import {useEffect} from 'react';
import {useLocation} from 'react-router-dom';

declare global {
    interface Window {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        UE: any;
    }
}

window.UE = window.UE || {};
const UserComUrlChange = (): null => {
    const location = useLocation();

    useEffect(() => {
        if (window.UE) {
            window.UE.pageHit();
        }
    }, [location, location.pathname]);

    return null;
};

export default UserComUrlChange;
