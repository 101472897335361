import {Button, Container, Link, Typography, useTheme} from "@mui/material";
import {CookieConsentStyles} from "./styles";
import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import cookieHelper from "../../utils/cookieHelper";

const CookieConsent = () => {
    const {t} = useTranslation()
    const theme = useTheme();
    const [cookieAccepted, setCookieAccepted] = useState(true)

    useEffect(() => {
        if (!cookieHelper.getCookie('cookie-consent-accepted')) {
            setCookieAccepted(false)
        }
    }, [])

    const onButtonClick = () => {
        cookieHelper.setCookie('cookie-consent-accepted', true)
        setCookieAccepted(true)
    }

    if (cookieAccepted) return null

    return (
        <div className={CookieConsentStyles.wrapper}>

            <Container>
                <div>
                    <Typography variant="h5">
                        {t('cookieConsent.title')}
                    </Typography>
                </div>
                <div className={CookieConsentStyles.content}>
                    <div className={CookieConsentStyles.description}>
                        {t('cookieConsent.description')}
                        <Link className={CookieConsentStyles.link(theme.palette.primary.main)}
                              href={t('links.cookie')}>
                            {t('cookieConsent.policy')}
                        </Link>

                    </div>
                    <div className={CookieConsentStyles.button}>
                        <Button variant="contained"
                                color="primary"
                                onClick={onButtonClick}
                        >
                            {t('cookieConsent.button')}
                        </Button>
                    </div>
                </div>
            </Container>
        </div>

    )
}

export default CookieConsent
