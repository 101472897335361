import React from "react";
import {Box, TableCell, TableHead, TableRow, TableSortLabel, Typography, useTheme} from "@mui/material";
import {visuallyHidden} from "@mui/utils";
import IconTooltip from "../../IconTooltip/IconTooltip";
import {Data, EnhancedTableProps, headCells} from "../utils";
import {SearchResultsStyles} from "../styles";
import {useTranslation} from "react-i18next";

const EnhancedTableHead = ({order, orderBy, onRequestSort}: EnhancedTableProps) => {
    const theme = useTheme();
    const {t} = useTranslation();
    const createSortHandler =
        (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
            onRequestSort(event, property);
        };

    return (
        <TableHead className={SearchResultsStyles.thead(theme.palette.grey[800])}>
            <TableRow>
                {headCells(t).map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={'left'}
                        padding={'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            hideSortIcon={orderBy !== headCell.id}
                            IconComponent={e => {
                                const isDesc = e.className.includes('MuiTableSortLabel-iconDirectionDesc')
                                return <div className={`${SearchResultsStyles.sortArrow(isDesc)} ${e.className}`}/>
                            }}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            <IconTooltip message={headCell.tooltip}/>
                            <Typography>
                                {headCell.label}
                            </Typography>
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

export default EnhancedTableHead;
