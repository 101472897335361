import {alpha, Fade} from "@mui/material";

export default ({palette}) => ({
    defaultProps: {
        marginThreshold: 8,
        elevation: 12,
        transitionDuration: 200,
        TransitionComponent: Fade,
    },
    styleOverrides: {
        root: {},
        paper: {
            border: `1px solid ${alpha(palette.divider, 0.5)}`,
        },
    },
});
