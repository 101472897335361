import {css} from "@emotion/css";
import {mediaQueryMax} from "../../theme/options/breakpoints";

const pageWrapper = css`
  padding: 40px 50px;

  & .MuiGrid-item {
    flex-basis: 100%;
  }

  ${mediaQueryMax.md} {
    margin: 0 -8px;
    padding: 15px;
  }
`
const loaderWrapper = css`
  min-height: calc(100vh - 300px);
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const titleTextWrapper = css`
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`


export const DocumentPageStyles = {
    pageWrapper,
    loaderWrapper,
    titleTextWrapper
}
