import {breakpointValues} from '../breakpoints';

export default {
    defaultProps: {
        titleTypographyProps: {
            variant: 'h5',
        },
    },
    styleOverrides: {
        root: {
            paddingTop: 16,
            paddingLeft: 16,
            paddingRight: 16,
            paddingBottom: 0,
            [`@media (min-width: ${breakpointValues.sm}px)`]: {
                paddingTop: 16,
                paddingLeft: 16,
                paddingRight: 16,
                paddingBottom: 0,
            },
            [`@media (min-width: ${breakpointValues.md}px)`]: {
                paddingTop: 24,
                paddingLeft: 24,
                paddingRight: 24,
            },
            [`@media (min-width: ${breakpointValues.lg}px)`]: {
                paddingTop: 40,
                paddingLeft: 40,
                paddingRight: 40,
                paddingBottom: 0,
                '& + [class^="MuiCardContent-root"], & + [class*="MuiCardContent-root"]': {
                    marginTop: -8,
                },
            },
        },
        action: {
            marginTop: 0,
            marginRight: 0,
            alignSelf: 'center',
        },
    },
};
