import {lighten} from "@mui/material";

export default ({palette,}) => ({
    styleOverrides: {
        root: {
            height: 6,
            padding: '6px 0',
        },
        valueLabel: {
            left: 'calc(-50% - 8px)',
        },
        thumb: {
            height: 16,
            width: 16,
            backgroundColor: palette.common.white,
            border: '4px solid currentColor',
            marginTop: -5,
            marginLeft: -8,
            '&:focus,&:hover,&$active': {
                boxShadow: 'inherit',
            },
            '&$disabled': {
                '& $thumb': {
                    border: '2px solid #fff',
                    backgroundColor: 'currentColor',
                    width: '10px !important',
                    height: '10px !important',
                    marginLeft: '-5px !important',
                },
                '& $track': {
                    opacity: 0.65,
                },
                '& $rail': {
                    opacity: 0.25,
                },
            },
        },
        track: {
            height: 6,
            borderRadius: 3,
        },
        rail: {
            height: 6,
            borderRadius: 3,
            opacity: 0.35,
            backgroundColor: palette.text.secondary,
        },
        mark: {
            width: 4,
            height: 4,
            borderRadius: 3,
            border: `1px solid ${palette.common.white}`,
            backgroundColor: lighten(palette.text.secondary, 0.2),
            marginTop: 0,
            marginLeft: -3,
        },
        markActive: {
            border: '1px solid currentColor',
            backgroundColor: palette.background.paper,
            opacity: 1,
        },
    },
});
