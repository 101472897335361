import {createAsyncThunk} from "@reduxjs/toolkit";
import axios from "../../utils/axios";
import {AxiosError} from "axios";
import cookieHelper from "../../utils/cookieHelper";

const moduleName = 'auth'


const getProfileData = createAsyncThunk(
    "auth/getProfileData", async (_, thunkAPI) => {
        try {
            const response = await axios.get(`/rest/${moduleName}/profile`);
            return response.data.data;
        } catch (error: unknown) {
            if (error instanceof AxiosError)
                return thunkAPI.rejectWithValue({error: error.message});
        }
    });

const logout = createAsyncThunk(
    "auth/logout", async (_, thunkAPI) => {
        try {
            const response = await axios.delete(`/rest/${moduleName}/token`);
            cookieHelper.removeCookie('activeIdentity')
            window.location.reload();
            return response.data.data;
        } catch (error: unknown) {
            if (error instanceof AxiosError)
                return thunkAPI.rejectWithValue({error: error.message});
        }
    });

export const authServices = {
    getProfileData,
    logout
}

