import {breakpointValues} from '../breakpoints';

export default {
    styleOverrides: {
        root: {
            flexWrap: 'nowrap',
            padding: 12,
            borderRadius: 'unset',
            [`@media (min-width: ${breakpointValues.sm}px)`]: {
                left: 12,
                minWidth: 'auto',
                maxWidth: 'none',
                width: 300,
                borderRadius: 8,
            },
            [`@media (min-width: ${breakpointValues.xl}px)`]: {
                width: 320,
            },
        },
        message: {
            padding: `0 30px`,
            position: 'relative',
            width: '100%',
            '& > svg': {
                position: 'absolute',
                top: -2,
                left: -2,
                fontSize: 24,
            },
        },
        action: {
            position: 'absolute',
            top: 4,
            right: 4,
            marginRight: 0,
            paddingLeft: 12,
        },
    }
};
