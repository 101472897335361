import {createAsyncThunk} from "@reduxjs/toolkit";
import axios from "../../utils/axios";
import {getDownloadTokenInputType} from "../../models/file.model";


const fetchDocumentImagePreview = createAsyncThunk(
    "file/fetchDocumentImagePreview", async (token: string) => {
        const response = await axios
            .get(`/rest/files-module/v1/file?token=${token}`, {responseType: 'blob'});

        return URL.createObjectURL(response.data);
    });

const getDownloadToken = createAsyncThunk(
    "file/getDownloadToken", async ({documentId, index, page, service}: getDownloadTokenInputType) => {

        const response = await axios
            .get(`/rest/${service}/documents/${documentId}/files/download-token?index=${index}&page=${page}`)

        return response.data;
    });

export const fileServices = {
    fetchDocumentImagePreview,
    getDownloadToken
}
