export default {
    styleOverrides: {
        root: {},
        penIcon: {
            display: 'none',
        },
        dateTextContainer: {
            display: 'flex',
        },
    },
};
