export default ({typography}) => ({
    styleOverrides: {
        root: {
            textTransform: 'lowercase',
            opacity: 0.65,
            fontSize: typography.h4.fontSize,
            '&:hover:not($selected)': {
                opacity: 0.8,
            },
            '&$selected': {
                opacity: 1,
            },
        },
        selected: {},
    }
});
