export default ({palette}) => ({
    styleOverrides: {
        root: {},
        loadingContainer: {},
        weekContainer: {},
        week: {},
        iconButton: {
            backgroundColor: palette.background.paper,
        },
        previousMonthButton: {
            marginRight: 12,
        },
        daysHeader: {},
        weekDayLabel: {
            width: 32,
            height: 48,
            margin: '0 2px',
            color: palette.text.secondary,
        },
    },
});
