import {breakpointValues} from '../breakpoints';
import shadows from '../shadows';

export default ({typography, palette}) => ({
    styleOverrides: {
        root: {
            borderRadius: 6,
            padding: '0 8px',
            minHeight: 48,
            lineHeight: 1.2,
            fontSize: typography.htmlFontSize,
            minWidth: 'auto',
            [`@media (min-width: ${breakpointValues.sm}px)`]: {
                minWidth: 'auto',
            },
            [`@media (min-width: ${breakpointValues.md}px)`]: {
                minWidth: 'auto',
                padding: '0 12px',
            },
            [`@media (min-width: ${breakpointValues.lg}px)`]: {
                padding: '0 16px',
            },
        },
        wrapper: {},
        labelIcon: {
            minHeight: 48,
            paddingTop: 0,
            '& $wrapper': {
                flexDirection: 'row',
                '& > *:first-child': {
                    fontSize: 20,
                    marginBottom: 0,
                    marginRight: 6,
                    [`@media (min-width: ${breakpointValues.md}px)`]: {
                        marginRight: 8,
                    },
                },
            },
        },
        selected: {},
        textColorPrimary: {
            color: palette.text.primary,
            '& svg': {
                fill: palette.text.disabled,
            },
            '&$selected': {
                boxShadow: shadows[6],
                backgroundColor: palette.background.paper,
                '& svg': {
                    fill: palette.primary.light,
                },
            },
        },
    },
});
