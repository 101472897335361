export default {
    defaultProps: {
        focusRipple: true,
    },
    styleOverrides: {
        root: {
            fontFamily: 'inherit',
        },
    },
};
