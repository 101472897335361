import axios, {AxiosRequestConfig} from "axios";
import cookieHelper from "./cookieHelper";

const instance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    withCredentials: true,
});

instance.interceptors.request.use((config) => {
    const identityValue = cookieHelper.getCookie('activeIdentity');

    const newConfig: AxiosRequestConfig = {
        ...config,
    };
    if (identityValue) {
        newConfig.headers = {
            ...newConfig.headers,
            'X-Identity-Id': identityValue
        };
    }

    return newConfig;
});


export default instance;
