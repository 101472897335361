import {css} from "@emotion/css";
import {mediaQueryMax} from "../../theme/options/breakpoints";

const wrapper = css`
  position: relative;
`

const textWrapper = css`
  display: flex;
  flex-direction: column;
  z-index: 10;
`
const wrapText = css`
  max-width: 65%;
  word-break: break-word;

  ${mediaQueryMax.sm} {
    max-width: 100%;
  }
`


export const NoMatchesFoundStyles = {
    wrapper,
    textWrapper,
    wrapText
}
