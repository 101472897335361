import {css} from '@emotion/css';
import {Theme} from "@mui/material";

const wrapper = css`
  margin-left: 16px;
  display: inline-flex;

`
const menuItemIcon = css`
  min-width: unset !important;
`

const itemUser = css`
  cursor: default;

  &:hover {
    background: transparent;
  }
`

const menuItem = ({palette}: Theme) => css`
  color: ${palette.text.primary};
  padding: 16px 14px;

  &:hover {
    color: inherit;
  }
`
const menuItemText = (color: string) => css`
  color: ${color};
  display: flex;
  flex-direction: column;
  max-width: 250px;
  
  p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

export const UserMenuStyles = {
    wrapper,
    menuItemIcon,
    menuItem,
    menuItemText,
    itemUser
}
