import {
    Chip,
    Fab,
    lighten,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TablePagination,
    TableRow,
    Typography,
    useTheme,
} from "@mui/material";
import React, {useEffect} from "react";
import EnhancedTableHeadTable from "./components/EnhancedTableHead";
import {DocumentHistoryStyles} from "./styles";
import {ChevronRight} from "../CustomIcon";
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import {setDocumentHistoryDetailsIndex} from "../../redux/reducers/document.reducer";
import {createData, Data} from "./utils";
import {getComparator, Order, stableSort} from "../../utils/table";
import {documentServices} from "../../redux/services/document.services";
import {useTranslation} from "react-i18next";
import {getDocumentActionName} from "../../utils/global";
import moment from "moment";

export interface PaperTableProps {
    // items: PaperDataGridItems
    withShadow?: boolean,
}

const DocumentHistoryTable = ({withShadow = false}: PaperTableProps) => {
    const dispatch = useAppDispatch();
    const {t} = useTranslation();
    const theme = useTheme();
    const {documentData, documentHistory} = useAppSelector(state => state.documentReducer)
    const [order, setOrder] = React.useState<Order>('asc');
    const [orderBy, setOrderBy] = React.useState<keyof Data>('date');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    const rows = documentHistory
        ? documentHistory.events.map(event => {
                let executor = '-'
                if (event.executor) {
                    executor = event.executor.name !== '' ? event.executor.name : event.executor.uuid
                }
                return createData({
                    action: event.action,
                    date: event.time ? moment(event.time).format('YYYY-MM-DD HH:mm:ss') : '-',
                    blockNumber: event.blockNumber,
                    executor: executor
                })
            }
        )
        : [];


    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: keyof Data,
    ) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };


    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const onItemClick = (index: number) => {
        dispatch(setDocumentHistoryDetailsIndex(index))
    }

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    useEffect(() => {
        if (documentData && documentData.length !== 0) {
            dispatch(documentServices.fetchDocumentHistory(documentData[0].uuid))
        }
    }, [documentData])

    if (!documentHistory) return null;

    return <>
        <Typography variant="h3" mb="16px" mt="64px">
            {t('documentPage.documentHistoryTitle')}
        </Typography>
        <Paper className={DocumentHistoryStyles.container} elevation={withShadow ? 0 : 12}>
            <TableContainer>
                <Table
                    sx={{minWidth: 750}}
                    aria-labelledby="tableTitle"
                    size={'medium'}
                >
                    <EnhancedTableHeadTable
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                        rowCount={rows.length}
                    />
                    <TableBody>
                        {stableSort(rows, getComparator(order, orderBy))
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row: Data, index) => {
                                const labelId = `enhanced-table-checkbox-${index}`;

                                return (
                                    <TableRow
                                        hover
                                        role="checkbox"
                                        tabIndex={-1}
                                        className={DocumentHistoryStyles.row(theme.palette.primary.main, lighten(theme.palette.primary.main, 0.925))}
                                        key={`${row.blockNumber}_${index}`}
                                        onClick={() => onItemClick(page * rowsPerPage + index)}
                                    >
                                        <TableCell
                                            id={labelId}
                                            scope="row"
                                            padding="none"
                                        >
                                            {row.date}
                                        </TableCell>
                                        <TableCell>
                                            <Chip
                                                color={getDocumentActionName(row.action, t).variant}
                                                label={getDocumentActionName(row.action, t).short}/>
                                        </TableCell>
                                        <TableCell>{row.blockNumber}</TableCell>
                                        <TableCell>{row.executor}</TableCell>
                                        <TableCell>
                                            <Fab size={"small"} color={"primary"}>
                                                <ChevronRight className={DocumentHistoryStyles.tableButtonIcon}/>
                                            </Fab>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        {emptyRows > 0 && (
                            <TableRow
                                style={{
                                    height: (53) * emptyRows,
                                }}
                            >
                                <TableCell colSpan={6}/>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                labelRowsPerPage={t('common.rowsPerPage')}
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Paper>
    </>
}

export default DocumentHistoryTable;
