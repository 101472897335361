import {Button, Menu, MenuItem, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import i18next from "i18next";
import {LanguageType} from "../../models/global.model";
import {useState} from "react";
import {ArrowDownSLine} from "../CustomIcon";


const LanguageSelect = () => {
    const {t, i18n} = useTranslation();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const changeLanguage = (locale: LanguageType) => {
        return i18next.changeLanguage(locale)
    }

    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        if (e) setAnchorEl(e.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const isOpen = Boolean(anchorEl);
    return (<>
        <Button
            size="large"
            variant="text"
            onClick={handleClick}
            endIcon={<ArrowDownSLine/>}
        >
            {i18n.language === LanguageType.PL ? t("language.pl") : t("language.en")}
        </Button>
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            open={isOpen}
            onClose={handleClose}

        >
            <MenuItem
                value={LanguageType.PL}
                selected={i18n.language === LanguageType.PL}
                onClick={() => {
                    changeLanguage(LanguageType.PL).then();
                    handleClose();
                }}
            >
                <Typography>{t("language.pl")}</Typography>
            </MenuItem>
            <MenuItem
                value={LanguageType.EN}
                selected={i18n.language === LanguageType.EN}
                onClick={() => {
                    changeLanguage(LanguageType.EN).then();
                    handleClose();
                }}
            >
                <Typography>{t("language.en")}</Typography>
            </MenuItem>
        </Menu>
    </>)
}

export default LanguageSelect;
