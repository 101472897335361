import {useEffect, useRef} from 'react';

/**
 * a type-safe version of the `usePrevious` hook described here:
 */
export function usePrevious<T>(
    value: T,
): ReturnType<typeof useRef<T>>['current'] {
    const ref = useRef<T>();
    useEffect(() => {
        ref.current = value;
    }, [value]);
    return ref.current;
}
