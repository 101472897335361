import {Order} from "../../utils/table";
import React from "react";
import {DocumentActionType} from "../../models/document.model";
import {TFunction} from "i18next";

export interface Data {
    date: string;
    action: DocumentActionType;
    blockNumber: number;
    executor: string;
}

export function createData({
                               date,
                               action,
                               blockNumber,
                               executor,
                           }: Data
): Data {
    return {
        date,
        action,
        blockNumber,
        executor,
    };
}

export const headCells = (t: TFunction): readonly HeadCell[] => [
    {
        id: 'date',
        disablePadding: true,
        label: t('common.date'),
        tooltip: t('common.dateTooltip'),
    },
    {
        id: 'action',
        disablePadding: false,
        label: t('documentPage.action'),
        tooltip: t('documentPage.actionTooltip'),
    },
    {
        id: 'blockNumber',
        disablePadding: false,
        label: t('documentPage.blockNumber'),
        tooltip: t('documentPage.blockNumberTooltip'),
    },
    {
        id: 'executor',
        disablePadding: false,
        label: t('documentPage.executor'),
        tooltip: t('documentPage.executorTooltip'),
    },
];


export interface HeadCell {
    disablePadding: boolean;
    id: keyof Data;
    label: string;
    tooltip: string;
}

export interface EnhancedTableProps {
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
    order: Order;
    orderBy: string;
    rowCount: number;
}
