import Logo from "../Logo/Logo";
import Search from "../Search/Search";
import {Button, Container, IconButton, Menu, useMediaQuery, useTheme} from "@mui/material";
import {HeaderStyles} from "./styles";
import {useMatch} from "react-router-dom";
import {RoutesObj} from "../../Router";
import {Globe as GlobeIcon, Menu as MenuIcon} from "../CustomIcon";
import {useTranslation} from "react-i18next";
import LanguageSelect from "../LanguageSelect/LanguageSelect";
import React from "react";
import {breakpointValues} from "../../theme/options/breakpoints";
import UserMenu from "../UserMenu/UserMenu";


const DesktopHeader = () => {
    const {t} = useTranslation();

    const isHomePage = useMatch(RoutesObj.HomePage.toPath())

    return <>
        <Logo height={34} width={148}/>
        {!isHomePage && <div className={HeaderStyles.searchWrapper}>
            <div className={HeaderStyles.search}>
                <Search showUploadIcon={true}/>
            </div>
        </div>
        }
        <div className={HeaderStyles.buttonsWrapper}>
            <LanguageSelect/>
            <a target="_blank" href="https://doxychain.com">
                <Button size="large" color="secondary" variant="contained"
                        endIcon={<GlobeIcon/>}>
                    {t("header.goToWebsite")}
                </Button>
            </a>
            <UserMenu/>
        </div>
    </>

}

const MobileHeader = () => {
    const {t} = useTranslation();
    const isHomePage = useMatch(RoutesObj.HomePage.toPath())
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div className={HeaderStyles.mobileMenu}>
            <div className={HeaderStyles.mobileTopContent}>
                <Logo height={30} width={130}/>
                <IconButton
                    onClick={handleClick}
                    size="large"
                    edge="start"
                    color="inherit"
                    aria-label="open drawer"
                    sx={{mr: 2}}
                >
                    <MenuIcon/>
                </IconButton>
            </div>
            {!isHomePage && <Search showUploadIcon={true}/>}
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{horizontal: 'right', vertical: 'top'}}
                anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
            >
                <div className={HeaderStyles.mobileLangButtons}>
                    <LanguageSelect/>
                </div>
                <a target="_blank" href={process.env.REACT_APP_AUTH_FRONT_URL}>
                    <Button color="secondary" variant="contained"
                            endIcon={<GlobeIcon/>}>
                        {t("header.goToWebsite")}
                    </Button>
                </a>
            </Menu>
        </div>
    )
}


const Header = () => {
    const isMobile = useMediaQuery(`(max-width:${breakpointValues.sm}px)`);
    const theme = useTheme()

    return (<header className={HeaderStyles.wrapper(theme.palette.background.paper)}>
        <Container>
            <div className={HeaderStyles.innerWrapper}>
                {isMobile ? <MobileHeader/> : <DesktopHeader/>}
            </div>
        </Container>
    </header>)
}

export default Header;
