import {breakpointValues} from "../breakpoints";

export default {
    styleOverrides: {
        root: {
            width: '100%',
            marginLeft: 'auto',
            boxSizing: 'border-box',
            marginRight: 'auto',
            paddingLeft: 8,
            paddingRight: 8,
            [`@media (min-width: ${breakpointValues.xs}px)`]: {
                paddingLeft: 12,
                paddingRight: 12,
            },
            [`@media (min-width: ${breakpointValues.md}px)`]: {
                paddingLeft: 16,
                paddingRight: 16,
            },
            [`@media (min-width: ${breakpointValues.lg}px)`]: {
                paddingLeft: 20,
                paddingRight: 20,
            },
        },
    },
};
