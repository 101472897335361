import React from "react";
import {Typography} from "@mui/material";
import {SystemAlertStyles} from "./styles";
import {useMatch} from "react-router-dom";
import {RoutesObj} from "../../Router";
import {useAppSelector} from "../../redux/hooks";
import {selectDocumentStatus, selectDocumentType,} from "../../redux/selectors/document.selector";
import {DocumentStatusType} from "../../models/document.model";
import {mapDocumentStatusTypeToChipType} from "../../utils/global";
import {
    CheckedInCircle,
    Edit as EditIcon,
    HourglassProgress as HourglassProgressIcon,
    Info as InfoIcon,
    Remove as RemoveIcon,
    Stopwatch as StopWatchIcon,
    Time as TimeIcon
} from "../CustomIcon";
import {useTranslation} from "react-i18next";


type SystemAlertVariantType = {
    color: string,
    icon: React.ReactNode
}

export const getSystemAlertVariant = (status: DocumentStatusType | string, documentType?: string): SystemAlertVariantType => {
    if (documentType === 'durableMedium') {
        return {
            color: '#16B15C',
            icon: <CheckedInCircle/>
        }
    }

    switch (status) {
        case DocumentStatusType.ISSUING:
        case DocumentStatusType.SIGNING:
            return {
                color: '#188CE0 ',
                icon: <HourglassProgressIcon/>
            };
        case DocumentStatusType.TEMPORARILY_INVALIDATE:
            return {
                color: '#EE7A0F ',
                icon: <StopWatchIcon/>
            };
        case DocumentStatusType.VALID:
        case DocumentStatusType.READY_FOR_PREVIEW:
        case DocumentStatusType.SIGNED:
        case DocumentStatusType.FINISHED:
            return {
                color: '#16B15C',
                icon: <CheckedInCircle/>
            };
        case DocumentStatusType.EXPIRED:
            return {
                color: '#DD1C1C',
                icon: <TimeIcon/>
            };
        case DocumentStatusType.INVALIDATED:
        case DocumentStatusType.REJECTED:
            return {
                color: '#DD1C1C ',
                icon: <RemoveIcon/>
            };
        case DocumentStatusType.EDITING:
            return {
                color: '#A5B4CA',
                icon: <EditIcon/>
            };
        default:
            return {
                color: '#A5B4CA',
                icon: <InfoIcon/>
            }
    }
}

const SystemAlert = () => {
    const {t} = useTranslation();
    const documentStatus = useAppSelector(selectDocumentStatus)
    const documentType = useAppSelector(selectDocumentType)
    const isDocumentPage = useMatch(RoutesObj.DocumentPage.path)
    const documentData = useAppSelector(state => state.documentReducer.documentData)


    if (!isDocumentPage || !documentStatus || documentData && documentData.length > 1) return null
    const {long} = mapDocumentStatusTypeToChipType(documentStatus, t, documentType)
    const {icon, color} = getSystemAlertVariant(documentStatus, documentType)

    return (<>
        <div className={SystemAlertStyles.wrapper} style={{backgroundColor: color}}>
            <div className={SystemAlertStyles.icon}>{icon}</div>
            <Typography variant="h5">{long}</Typography>
        </div>
    </>)
}

export default SystemAlert;
