export default {
    styleOverrides: {
        root: {},
        iconButton: {
            backgroundColor: 'transparent',
        },
        previousMonthButtonMargin: {
            marginRight: 0,
        },
        hidden: {},
    }
};
