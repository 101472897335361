import React, {useCallback} from 'react'
import {FileRejection, useDropzone} from 'react-dropzone'
import {ArrowTop as ArrowTopIcon, FileColor as FileColorIcon} from '../CustomIcon';
import {DropzoneStyles} from "./styles";
import {Typography, useTheme} from "@mui/material";
import {cx} from "@emotion/css";
import {useTranslation} from "react-i18next";
import {acceptableFileTypes, dropzoneErrors, maxFiles, maxSize} from "./config";
import {useSnackbar} from "notistack";
import {sha3_256} from 'js-sha3';
import {RoutesObj} from "../../Router";
import {useNavigate} from "react-router-dom";
import {resetSearch} from "../../redux/reducers/document.reducer";
import {resetFiles} from "../../redux/reducers/file.reducer";
import {documentServices} from "../../redux/services/document.services";
import {useAppDispatch} from "../../redux/hooks";


function Dropzone() {
    const {enqueueSnackbar} = useSnackbar()
    const {t} = useTranslation()
    const dispatch = useAppDispatch()
    const theme = useTheme()
    const navigate = useNavigate()

    const onDrop = useCallback((acceptedFiles: File[]) => {
        const file = acceptedFiles[0]
        file.arrayBuffer().then(res => {
            const hash = sha3_256(res).toString().toUpperCase()
            dispatch(resetSearch())
            dispatch(resetFiles())
            dispatch(documentServices.fetchDocumentData(hash))
            navigate(RoutesObj.SearchPage.toPath(hash))
        })

    }, [])

    const onDropRejected = useCallback((rejectedFiles: FileRejection[]) => {
        if (rejectedFiles && rejectedFiles.length) {
            rejectedFiles.forEach((file) => {
                file.errors.forEach((error) => {
                    if (dropzoneErrors[error.code]) {
                        enqueueSnackbar(dropzoneErrors[error.code], {
                            variant: 'error',
                        })
                    }
                });
            });
        }
    }, [])

    const {getRootProps, getInputProps, isDragAccept} = useDropzone({
        accept: acceptableFileTypes,
        maxFiles,
        maxSize,
        onDrop, onDropRejected,
        multiple: false
    })

    return (
        <div {...getRootProps()}

             className={
                 cx(
                     DropzoneStyles.dropzone(theme.palette.background.paper, theme.palette.primary.light),
                     {[DropzoneStyles.dropzoneAccept(theme.palette.success.main)]: isDragAccept},
                 )
             }>
            <input {...getInputProps()} />
            <div className={DropzoneStyles.iconBox}>
                <FileColorIcon className={DropzoneStyles.icon}/>
                <div className={DropzoneStyles.smallIconBox}>
                    <ArrowTopIcon className={DropzoneStyles.smallIcon(theme.palette.primary.main)}/>
                </div>
            </div>
            {
                isDragAccept ? <Typography variant="h5" align="center">
                        {t('dropzone.titleOnDragHover')}
                    </Typography>
                    : <Typography variant="h5" align="center">
                        {t('dropzone.titleBeforeDrag')}
                    </Typography>

            }

            <Typography variant="body1" align="center" className={DropzoneStyles.textSmall}>
                {t('dropzone.descriptionBeforeDrag')}
            </Typography>
        </div>
    )
}

export default Dropzone;
