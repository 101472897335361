import breakpoints from './options/breakpoints';
import mixins from './options/mixins';
import components from './options/overrides';
import shadows from './options/shadows';
import typography from './options/typography';
import custom from './options/custom';
import {createTheme, responsiveFontSizes} from "@mui/material";

export const getTheme = (palette) => {
    const theme = createTheme({
        shape: {
            borderRadius: 6,
        },
        breakpoints,
        mixins,
        components: components({palette, typography, shadows}),
        palette,
        shadows,
        typography,
        custom,
    });

    theme.spacing(8);

    return responsiveFontSizes(theme)
}

