export const breakpointValues = {
    xs: 400,
    sm: 640,
    md: 960,
    lg: 1280,
    xl: 1520,
}

export const mediaQueryMin = {
    xs: `@media (min-width: ${breakpointValues.xs}px)`,
    sm: `@media (min-width: ${breakpointValues.sm}px)`,
    md: `@media (min-width: ${breakpointValues.md}px)`,
    lg: `@media (min-width: ${breakpointValues.lg}px)`,
    xl: `@media (min-width: ${breakpointValues.xl}px)`,
}

export const mediaQueryMax = {
    xs: `@media (max-width: ${breakpointValues.xs}px)`,
    sm: `@media (max-width: ${breakpointValues.sm}px)`,
    md: `@media (max-width: ${breakpointValues.md}px)`,
    lg: `@media (max-width: ${breakpointValues.lg}px)`,
    xl: `@media (max-width: ${breakpointValues.xl}px)`,
}

export default {
    values: {
        xs: breakpointValues.xs,
        sm: breakpointValues.sm,
        md: breakpointValues.md,
        lg: breakpointValues.lg,
        xl: breakpointValues.xl,
    }
}
