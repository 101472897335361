import {Box, Button, Typography, useTheme} from "@mui/material";
import {useTranslation} from "react-i18next";
import {NoMatchesFoundStyles} from "./styles";
import React from "react";
import {RoutesObj} from "../../Router";
import {Link} from "react-router-dom";
import {useAppSelector} from "../../redux/hooks";

type NoMatchesFoundProps = { term?: string }

declare global {
    interface Window {
        userengage: (param: string) => void;
    }
}

const NoMatchesFound = ({term}: NoMatchesFoundProps) => {
    const theme = useTheme()
    const {t} = useTranslation()
    const {searchFileName} = useAppSelector(state => state.documentReducer)
    const isFile = !!searchFileName;
    const isDocumentId = term?.includes('-')
    const isAddressOrHash = !isDocumentId && !isFile;


    return (<div className={NoMatchesFoundStyles.wrapper}>
        <div className={NoMatchesFoundStyles.textWrapper}>
            <Typography variant="h1" mb="24px">
                {t('noMatchesFound.title')}
            </Typography>
            {term && <Typography variant="h2" mb="24px">
                {t('noMatchesFound.searchedFor')}
                <div className={NoMatchesFoundStyles.wrapText} style={{
                    color: theme.palette.primary.main,
                }}> {searchFileName ? searchFileName : term}</div>
            </Typography>}
            {searchFileName && <Typography variant="h3" mt="-4px" mb="8px">
                <div style={{color: theme.palette.grey[600]}}> {term}</div>
            </Typography>}
            {isFile && <Typography variant="h5" className={NoMatchesFoundStyles.wrapText}>
                {t('noMatchesFound.fileNotFound')}
            </Typography>}
            {isDocumentId && <Typography variant="h5" className={NoMatchesFoundStyles.wrapText}>
                {t('noMatchesFound.documentIdNotFound')}
            </Typography>}
            {isAddressOrHash && <Typography variant="h5" className={NoMatchesFoundStyles.wrapText}>
                {t('noMatchesFound.hashNotFound')}
            </Typography>}
            <Typography variant="h5" mb="16px" mt="24px" className={NoMatchesFoundStyles.wrapText}>
                {t('noMatchesFound.description')}
            </Typography>
            <Box>
                <Link to={RoutesObj.HomePage.toPath()}>
                    <Button color="secondary" variant="contained">
                        {t('noMatchesFound.backButton')}
                    </Button>
                </Link>
                <Link to='#' onClick={(e) => {
                    e.preventDefault();
                    if (window?.userengage) window.userengage('widget.open');
                }}>
                    <Button variant="text" style={{
                        color: theme.palette.primary.main,
                        marginLeft: '8px'
                    }}>
                        {t("noMatchesFound.contactSupport")}
                    </Button>
                </Link>
            </Box>

        </div>
    </div>)
}

export default NoMatchesFound;
