export default {
    styleOverrides: {
        root: {
            background: 'transparent',
            padding: '2px 4px',
            minWidth: 64,
            maxWidth: 'auto',
        },
    },
};
