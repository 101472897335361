import {css} from "@emotion/css";
import {mediaQueryMax} from "../../theme/options/breakpoints";

const chip = css`
  margin-left: 14px;

  span {
    line-height: 1;
  }

  svg {
    margin-left: 4px !important;
  }

  ${mediaQueryMax.sm} {
    margin-left: unset;
    margin-top: 12px;
  }
`
const titleIcon = css`
  margin-right: 12px;
  display: flex;
`
const titleWrapper = css`
  display: flex;
  align-items: center;
  padding-right: 36px;
`

export const VerificationButtonStyles = {
    chip,
    titleIcon,
    titleWrapper
}
