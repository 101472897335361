export default {
    styleOverrides: {
        root: {
            marginLeft: 0,
            '& .MuiRadio-root, & .MuiCheckbox-root': {
                marginLeft: -6,
            },
        },
        label: {
            lineHeight: 1.3,
        },
        labelPlacementStart: {
            marginRight: 0,
            '& > .MuiSwitch-root': {
                marginLeft: 8,
                '&.MuiSwitch-sizeSmall': {
                    marginLeft: 4,
                },
            },
        },
        labelPlacementTop: {
            marginLeft: 0,
            marginRight: 0,
        },
        labelPlacementBottom: {
            marginLeft: 0,
            marginRight: 0,
        },
    },
};
