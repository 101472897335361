export default {
    styleOverrides: {
        select: {
            '&:focus': {
                backgroundColor: 'transparent',
            },
        },
        selectMenu: {
            paddingRight: 16,
        },
        icon: {
            width: 18,
            height: 18,
            top: 'calc(50% - 10px)',
            right: -3,
        },
    },
};
