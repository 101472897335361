import React, {useState} from "react";
import {issuer} from "../../models/document.model";
import {useTranslation} from "react-i18next";
import {Report, Verified} from "../CustomIcon";
import {Chip, Typography} from "@mui/material";
import {VerificationButtonStyles} from "./styles";
import PaperDataGrid from "../PaperDataGrid/PaperDataGrid";
import CustomDialog from "../CustomDialog/CustomDialog";
import {useAppSelector} from "../../redux/hooks";
import {selectIssuerModalData} from "../../redux/selectors/document.selector";

type props = {
    issuer: issuer
}

const VerificationButton = ({
                                issuer: {
                                    verified,
                                }
                            }: props): JSX.Element => {
    const {t} = useTranslation()
    const [showVerificationModal, setShowVerificationModal] = useState(false);
    const modalData = useAppSelector(state => selectIssuerModalData(state, t))

    const openVerificationModal = () => {
        setShowVerificationModal(true)
    }

    const closeVerificationModal = () => {
        setShowVerificationModal(false)
    }

    return <>
        <Chip
            className={VerificationButtonStyles.chip}
            variant="outlined"
            onClick={openVerificationModal}
            size="small"
            color={verified ? 'success' : 'warning'}
            icon={verified ? <Verified/> : <Report/>}
            label={verified ? t('documentPage.verified') : t('documentPage.unverified')}/>

        {modalData && <CustomDialog open={showVerificationModal}
                                    title={<div className={VerificationButtonStyles.titleWrapper}>
                                        {verified ?
                                            <>
                                                <Typography className={VerificationButtonStyles.titleIcon} variant="h4"
                                                            color="success.main">
                                                    <Verified/>
                                                </Typography>
                                                <Typography variant="h4" color="success.main">

                                                    {t('documentPage.verifiedFull')}
                                                </Typography>
                                            </> :
                                            <>
                                                <Typography className={VerificationButtonStyles.titleIcon} variant="h4"
                                                            color="warning.main">
                                                    <Report/>
                                                </Typography>
                                                <Typography variant="h4" color="warning.main">
                                                    {t('documentPage.unverifiedFull')}
                                                </Typography>
                                            </>
                                        }
                                    </div>}
                                    onClose={closeVerificationModal}>
            <PaperDataGrid withShadow={false} items={modalData}/>
        </CustomDialog>}
    </>
}

export default VerificationButton;
