import {createAsyncThunk,} from "@reduxjs/toolkit";
import axios from "../../utils/axios";
import {getDownloadTokenInputType} from "../../models/file.model";
import {AxiosError} from "axios";

const moduleName = 'blockchain-viewer'

const documentDataRequest = (name: string) => createAsyncThunk(
    name, async (id: string, thunkAPI) => {
        try {
            let response;

            if (id && id.includes('-')) {
                response = await axios.get(`/rest/${moduleName}/document-data?id=${id}`);
            } else {
                response = await axios.get(`/rest/${moduleName}/document-data?address=${id}`);
            }
            return response.data;
        } catch (error: unknown) {
            if (error instanceof AxiosError)
                try {
                    const response = await axios.get(`/rest/${moduleName}/document-data?hash=${id}`);

                    return response.data;
                } catch (error: unknown) {
                    if (error instanceof AxiosError)
                        return thunkAPI.rejectWithValue({error: error.message});
                }

        }
    });

const fetchDocumentData = documentDataRequest('search/fetchDocumentData')
const fetchDocumentDataAutocomplete = documentDataRequest('search/fetchDocumentDataAutocomplete')

const fetchDocumentHistory = createAsyncThunk(
    "search/fetchDocumentHistory", async (id: string, thunkAPI) => {
        try {
            const response = await axios.get(`/rest/${moduleName}/document-history/${id}`);

            return response.data;
        } catch (error: unknown) {
            if (error instanceof AxiosError)
                return thunkAPI.rejectWithValue({error: error.message});
        }
    });

const getDownloadTokens = createAsyncThunk(
    "file/getDownloadTokens", async ({
                                         documentId,
                                         index,
                                         startPage,
                                         page,
                                         service
                                     }: getDownloadTokenInputType) => {
        const tokenPromises = [];
        for (let i = startPage; i < page; i += 1) {
            tokenPromises.push(axios.get(`/rest/${service}/documents/${documentId}/files/download-token?index=${index}&page=${i}`));
        }
        const response = await Promise.all(tokenPromises)

        return response.map(res => {
            const url = new URL(res.request.responseURL);
            const args = new URLSearchParams(url.search);
            const index = args.get('index')
            const page = args.get('page')
            
            return {
                token: res.data.data.token,
                page: page ? parseInt(page) : 0,
                indexFile: index ? parseInt(index) : 0
            }
        })
    });

type downloadFileType = {
    index: number,
    token: string,
    page: number
}

const downloadFile = createAsyncThunk(
    "file/downloadFile", async ({token, index, page}: downloadFileType) => {
        const response = await axios
            .get(`/rest/files-module/v1/file?token=${token}`, {responseType: 'blob'});

        return {file: URL.createObjectURL(response.data), indexFile: index, page};
    });


export const documentServices = {
    fetchDocumentData,
    fetchDocumentHistory,
    fetchDocumentDataAutocomplete,
    getDownloadTokens,
    downloadFile
}
