import {Chip} from "@mui/material";
import * as React from "react";
import {DocumentStatusType} from "../../models/document.model";
import {mapDocumentStatusTypeToChipType} from "../../utils/global";
import {useTranslation} from "react-i18next";

type DocumentStatusProps = {
    status: DocumentStatusType, type: string
}

const DocumentStatus = ({status, type}: DocumentStatusProps) => {
    const {t} = useTranslation()
    const documentStatus = mapDocumentStatusTypeToChipType(status, t, type)
    if (!status) return null

    return <Chip color={documentStatus.variant} label={documentStatus.short}/>
}

export default DocumentStatus;
