export default {
    defaultProps: {
        disableTypography: true,
    },
    styleOverrides: {
        root: {
            marginTop: 0,
            marginBottom: 0,
        },
        inset: {
            paddingLeft: 32,
        },
    },
};
