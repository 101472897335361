export default {
    fontFamily: "'Poppins', 'Inter', 'Arial', sans-serif",
    htmlFontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 600,
    h1: {
        fontFamily: "'Poppins', 'Inter', 'Arial', sans-serif",
        lineHeight: 1.5,
        color: 'inherit',
        letterSpacing: 0,
        fontSize: '3rem', // 42px
    },
    h2: {
        fontFamily: "'Poppins', 'Inter', 'Arial', sans-serif",
        lineHeight: 1.5,
        color: 'inherit',
        letterSpacing: 0,
        fontSize: '1.715rem', // 24px
    },
    h3: {
        fontFamily: "'Poppins', 'Inter', 'Arial', sans-serif",
        lineHeight: 1.5,
        color: 'inherit',
        letterSpacing: '0.01em',
        fontSize: '1.43rem', // 20px
    },
    h4: {
        fontFamily: "'Poppins', 'Inter', 'Arial', sans-serif",
        lineHeight: 1.5,
        color: 'inherit',
        letterSpacing: '0.01em',
        fontSize: '1.285rem', // 18px
    },
    h5: {
        fontFamily: "'Poppins', 'Inter', 'Arial', sans-serif",
        lineHeight: 1.5,
        color: 'inherit',
        letterSpacing: '0.01em',
        fontSize: '1.15rem', // 16px
    },
    h6: {
        fontFamily: "'Poppins', 'Inter', 'Arial', sans-serif",
        lineHeight: 1.5,
        color: 'inherit',
        letterSpacing: '0.01em',
        fontSize: '1rem', // 14px
    },
    body1: {
        fontWeight: 'inherit',
        fontFamily: "'Poppins', 'Inter', 'Arial', sans-serif",
        lineHeight: 1.5,
        color: 'inherit',
        letterSpacing: '0.01em',
        fontSize: '1rem', // 14px
    },
    body2: {
        fontWeight: 'inherit',
        fontFamily: 'inherit',
        lineHeight: 1.6,
        color: 'inherit',
        letterSpacing: '0.012em',
        fontSize: 12, // 12px
    },
    caption: {
        fontWeight: 'inherit',
        fontFamily: 'inherit',
        lineHeight: 1.6,
        letterSpacing: '0.015em',
        fontSize: 10, // 10px
    },
    subtitle1: {
        fontWeight: 'inherit',
        fontFamily: 'inherit',
        lineHeight: 1.6,
        color: 'inherit',
        letterSpacing: '0.012em',
        fontSize: '1rem', // 14px
    },
    subtitle2: {
        fontWeight: 'inherit',
        fontFamily: 'inherit',
        lineHeight: 1.6,
        color: 'inherit',
        letterSpacing: '0.015em',
        fontSize: 12, // 14px
    },
    overline: {
        fontWeight: 'inherit',
        fontFamily: 'inherit',
        lineHeight: 1.5,
        fontSize: 12, // 12px
        textTransform: 'uppercase',
    },
    button: {
        textTransform: 'none',
        fontFamily: "'Poppins', 'Inter', 'Arial', sans-serif",
        color: 'inherit',
        fontSize: '1.0715rem', // 15px
    },
};
