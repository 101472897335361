import React from 'react';
import {getTheme} from "./index";
import {CssBaseline, StyledEngineProvider, ThemeProvider} from "@mui/material";
import {useAppSelector} from "../redux/hooks";
import {selectPalette} from "../redux/selectors/ui.selector";

type Props = {
    children: React.ReactNode
}

const CustomPaletteThemeProvider = ({children}: Props) => {
    const palette = useAppSelector(selectPalette)
    const theme = getTheme(palette)

    return (
        <StyledEngineProvider injectFirst={true}>
            <ThemeProvider theme={theme}>
                <CssBaseline enableColorScheme={true}/>
                {children}
            </ThemeProvider>
        </StyledEngineProvider>
    )
}

export default CustomPaletteThemeProvider;
