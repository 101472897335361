import {darken} from "@mui/material";

export default ({typography, palette}) => ({
    styleOverrides: {
        root: {
            fontWeight: typography.fontWeightMedium,
            fontSize: '1rem',
        },
        underline: {
            '&:before': {
                zIndex: 1,
                borderBottom: `1px solid ${palette.bottomLineColor}`,
            },
            '&:after': {
                zIndex: 1,
                borderBottom: `2px solid ${palette.primary.main}`,
            },
            '&:hover:not($disabled):not($focused):not($error):before': {
                borderBottom: `2px solid ${darken(palette.bottomLineColor, 0.5)}`,
            },
            '&$disabled': {
                color: palette.text.secondary,
            },
            '&$disabled:before': {
                borderBottom: `1px solid ${palette.bottomLineColor}`,
            },
        },
        multiline: {
            padding: `${10 - 2}px 0 ${10 - 1}px`,
        },
        input: {
            padding: `${10 - 2}px 0 ${10 - 1}px`,
        },
        inputMarginDense: {
            paddingTop: `${10 - 2}px`,
            paddingBottom: `${10 - 2}px`,
        },
        formControl: {
            'label + &': {
                marginTop: '1rem',
            },
        },
    }
});
