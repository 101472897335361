import {alpha} from "@mui/material";

export default ({palette}) => ({
    defaultProps: {
        color: 'primary',
    },
    styleOverrides: {
        root: {
            color: palette.bottomLineColor,
            '&$disabled': {
                '& svg': {
                    filter: 'none !important',
                },
            },
        },
        checked: {},
        disabled: {},
        colorPrimary: {
            '&$checked': {
                color: palette.primary.main,
                '& svg': {
                    filter: `drop-shadow(0 3px 6px ${alpha(palette.primary.main, 0.175)})`,
                },
            },
            '&$disabled': {
                color: palette.action.disabledBackground,
            },
        },
        colorSecondary: {
            color: palette.error.main,
            '&$checked': {
                color: palette.error.main,
                '& svg': {
                    filter: `drop-shadow(0 3px 6px ${alpha(palette.error.main, 0.175)})`,
                },
            },
            '&$disabled': {
                color: palette.action.disabledBackground,
            },
        },
    },
});
