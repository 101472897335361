import {
    chipType,
    chipTypeVariant,
    DocumentActionType,
    DocumentRoleStatusType,
    DocumentRoleType,
    DocumentStatusType,
    RecipientRole
} from "../models/document.model";
import {TFunction} from "i18next";

type shorthandOptStringType = {
    term?: string, limit: number
}
export const shorthandOptString = ({term, limit}: shorthandOptStringType): string =>
    term
        ? `${term.toString().substring(0, limit)}...${term.toString().substring(term.toString().length, term.toString().length - 3)}`
        : ''


const recipientStatusToChipVariant = (status: DocumentRoleStatusType): chipTypeVariant => {
    switch (status) {
        case DocumentRoleStatusType.SIGNED:
            return 'success';
        case DocumentRoleStatusType.REJECTED:
            return 'error';
        case DocumentRoleStatusType.AWAITING:
            return 'info';
        default:
            return 'default';
    }
}

const recipientStatusToText = (status: DocumentRoleStatusType, t: TFunction): string => {
    switch (status) {
        case DocumentRoleStatusType.SIGNED:
            return t('roleStatus.signed');
        case DocumentRoleStatusType.REJECTED:
            return t('roleStatus.rejected');
        case DocumentRoleStatusType.AWAITING:
            return t('roleStatus.awaiting');
        default:
            return ''
    }
}

const roleTextFromRole = (role: DocumentRoleType, t: TFunction): string => {
    switch (role) {
        case DocumentRoleType.SIGNER:
            return t('role.signer');
        case DocumentRoleType.ADMIN:
            return t('role.admin');
        case DocumentRoleType.VIEWER:
            return t('role.viewer');
        case DocumentRoleType.AUDITOR:
            return t('role.auditor');
        case DocumentRoleType.HOLDER:
            return t('role.holder');
        case DocumentRoleType.EDITOR:
            return t('role.editor');
    }
}


export const mapRoleToChipType = (recipientRole: RecipientRole, t: TFunction): chipType => {
    const statusText = recipientStatusToText(recipientRole.status, t);
    const roleText = roleTextFromRole(recipientRole.role, t);
    const variant = recipientStatusToChipVariant(recipientRole.status);
    const text = `${roleText}${recipientRole.status ? `: ${statusText}` : ''}`;
    
    return {
        variant,
        short: text,
        long: text,
    }
}

export const getDocumentActionName = (action: DocumentActionType, t: TFunction): chipType => {
    switch (action) {
        case DocumentActionType.DocumentCreated:
            return {
                short: t(`actions.DocumentCreated.short`),
                long: t(`actions.DocumentCreated.long`),
                variant: 'default'
            };
        case DocumentActionType.DocumentHolderChanged:
            return {
                short: t(`actions.DocumentHolderChanged.short`),
                long: t(`actions.DocumentHolderChanged.long`),
                variant: 'info'
            };
        case DocumentActionType.DocumentRolesUpdated:
            return {
                short: t(`actions.DocumentRolesUpdated.short`),
                long: t(`actions.DocumentRolesUpdated.long`),
                variant: 'info'
            };
        case DocumentActionType.DocumentSigned:
            return {
                short: t(`actions.DocumentSigned.short`),
                long: t(`actions.DocumentSigned.long`),
                variant: 'success'
            };
        case DocumentActionType.DocumentRejection:
        case DocumentActionType.DocumentRejected:
            return {
                short: t(`actions.DocumentRejected.short`),
                long: t(`actions.DocumentRejected.long`),
                variant: 'error'
            };
        case DocumentActionType.DocumentSignersModified:
            return {
                short: t(`actions.DocumentSignersModified.short`),
                long: t(`actions.DocumentSignersModified.long`),
                variant: 'info'
            };
        case DocumentActionType.DocumentSignersUpdated:
            return {
                short: t(`actions.DocumentSignersUpdated.short`),
                long: t(`actions.DocumentSignersUpdated.long`),
                variant: 'info'
            };
        case DocumentActionType.DocumentInvalidated:
            return {
                short: t(`actions.DocumentInvalidated.short`),
                long: t(`actions.DocumentInvalidated.long`),
                variant: 'error'
            };
        case DocumentActionType.DocumentValidated:
            return {
                short: t(`actions.DocumentValidated.short`),
                long: t(`actions.DocumentValidated.long`),
                variant: 'success'
            };
        case DocumentActionType.DocumentExpired:
            return {
                short: t(`actions.DocumentExpired.short`),
                long: t(`actions.DocumentExpired.long`),
                variant: 'error'
            };
        case DocumentActionType.TransferabilityChanged:
            return {
                short: t(`actions.TransferabilityChanged.short`),
                long: t(`actions.TransferabilityChanged.long`),
                variant: 'default'
            };
        case DocumentActionType.DocumentTemporarilyInvalidated:
            return {
                short: t(`actions.DocumentTemporarilyInvalidated.short`),
                long: t(`actions.DocumentTemporarilyInvalidated.long`),
                variant: 'warning'
            };
        case DocumentActionType.DocumentUnlinked: // grey
        case DocumentActionType.DocumentSignatureRejected: //red
        case DocumentActionType.DocumentUpdated: //grey
        case DocumentActionType.DocumentLinked: //grey
            return {
                short: action,
                long: action,
                variant: 'default'
            };

        default:
            return {
                short: action,
                long: action,
                variant: 'default'

            }
    }
}

type getStatusNameType = {
    status: DocumentStatusType,
    isShort: boolean,
    t: TFunction,
    isCertificate?: boolean
}

export const getStatusName = ({status, isShort, t, isCertificate = false}: getStatusNameType) => {
    const prefix = isCertificate ? t('common.certificate') : t('common.document');
    const type = isShort ? null : prefix;
    switch (status) {
        case DocumentStatusType.EDITING:
        case DocumentStatusType.SIGNING:
        case DocumentStatusType.ISSUING:
        case DocumentStatusType.SIGNED:
        case DocumentStatusType.REJECTED:
        case DocumentStatusType.FINISHED:
        case DocumentStatusType.VALID:
        case DocumentStatusType.INVALIDATED:
        case DocumentStatusType.TEMPORARILY_INVALIDATE:
        case DocumentStatusType.EXPIRED:
        case DocumentStatusType.READY_FOR_PREVIEW:
            return t(`statuses.${status}.${isShort ? 'short' : 'long'}`, {type});
        default:
            return status;
    }
};


export const mapDocumentStatusTypeToChipType = (status: DocumentStatusType, t: TFunction, documentType?: string): chipType => {
    const isCertificate = documentType === 'dsl';
    const isDurableMedium = documentType === 'durableMedium';

    if (isDurableMedium) {
        return {
            variant: 'success',
            short: getStatusName({status: DocumentStatusType.READY_FOR_PREVIEW, isShort: true, t}),
            long: getStatusName({status: DocumentStatusType.READY_FOR_PREVIEW, isShort: false, t}),
        };
    }

    switch (status) {
        case DocumentStatusType.ISSUING:
        case DocumentStatusType.SIGNING:
            return {
                variant: 'info',
                short: getStatusName({status, isShort: true, t, isCertificate}),
                long: getStatusName({status, isShort: false, t, isCertificate}),
            };
        case DocumentStatusType.VALID:
        case DocumentStatusType.FINISHED:
        case DocumentStatusType.READY_FOR_PREVIEW:
        case DocumentStatusType.SIGNED:
            return {
                variant: 'success',
                short: getStatusName({status, isShort: true, t, isCertificate}),
                long: getStatusName({status, isShort: false, t, isCertificate}),
            };

        case DocumentStatusType.EXPIRED:
        case DocumentStatusType.INVALIDATED:
        case DocumentStatusType.REJECTED:
            return {
                variant: 'error',
                short: getStatusName({status, isShort: true, t, isCertificate}),
                long: getStatusName({status, isShort: false, t, isCertificate}),
            };
        case DocumentStatusType.EDITING:
            return {
                variant: 'default',
                short: getStatusName({status, isShort: true, t, isCertificate}),
                long: getStatusName({status, isShort: false, t, isCertificate}),
            };
        case DocumentStatusType.TEMPORARILY_INVALIDATE:
            return {
                variant: 'warning',
                short: getStatusName({status, isShort: true, t, isCertificate}),
                long: getStatusName({status, isShort: false, t, isCertificate}),
            };
        default:
            return {
                variant: 'info',
                short: status,
                long: status,
            }
    }
}

