import {css} from "@emotion/css";

const wrapper = css`
  display: flex;
  justify-content: center;
  padding-bottom: 18px;

  a {
    text-decoration: underline;
  }
`
const image = css`
  max-width: 350px;
  cursor: zoom-in;
`

const textWrapper = css`
  padding: 80px 0 100px 0;
  text-align: center;
`
const fullScreenIcon = css`
  position: absolute;
  right: 0;
`

export const DocumentImageStyles = {
    wrapper,
    image,
    textWrapper,
    fullScreenIcon
}
