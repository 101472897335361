import {css} from '@emotion/css';
import {mediaQueryMax} from "../../theme/options/breakpoints";

const wrapper = (backgroundColor: string) => css`
  background-color: ${backgroundColor};
`

const innerWrapper = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 110px;

  ${mediaQueryMax.sm} {
    height: auto;
    padding-top: 15px;
    padding-bottom: 15px;
  }
`
const search = css`
  max-width: 600px;
  padding-left: 16px;
  flex: 1;
`

const searchWrapper = css`
  display: flex;
  flex: 1;
  justify-content: center;
`
const mobileMenu = css`
  display: flex;
  width: 100%;
  flex-direction: column;

  & input {
    height: 44px;
    min-height: 44px;
  }

`

const mobileTopContent = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
  padding-left: 10px;

  & button {
    margin-right: 0;
  }
`

const mobileLangButtons = css`
  display: flex;
  flex-direction: column;
`
const buttonsWrapper = css`
  display: flex;
  align-items: center;
`

export const HeaderStyles = {
    wrapper,
    innerWrapper,
    buttonsWrapper,
    search,
    searchWrapper,
    mobileMenu,
    mobileTopContent,
    mobileLangButtons
}
