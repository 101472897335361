export default {
    styleOverrides: {
        root: {
            position: 'relative',
            fontSize: 12,
            marginTop: 6,
            lineHeight: 1.2,
        },
        contained: {
            marginLeft: 0,
            marginRight: 0,
        },
    },
};
