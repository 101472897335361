export default ({palette}) => ({
    defaultProps: {
        color: 'primary',
        overlap: 'circle',
        anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
        },
    },
    styleOverrides: {
        badge: {
            height: 16,
            minWidth: 16,
            fontSize: 8,
            borderRadius: 16,
            background: palette.secondary.main,
            padding: 0,
        },
        dot: {
            height: 8,
            minWidth: 8,
            padding: 0,
            borderRadius: '50%',
        },
        anchorOriginTopRightRectangle: {
            top: 2,
            right: 2,
            transform: 'scale(1) translate(50%, -50%)',
            transformOrigin: '100% 0%',
            '&$invisible': {
                transform: 'scale(0) translate(50%, -50%)',
            },
        },
        anchorOriginBottomRightRectangle: {
            bottom: 2,
            right: 2,
            transform: 'scale(1) translate(50%, 50%)',
            transformOrigin: '100% 100%',
            '&$invisible': {
                transform: 'scale(0) translate(50%, 50%)',
            },
        },
        anchorOriginTopLeftRectangle: {
            top: 2,
            left: 2,
            transform: 'scale(1) translate(-50%, -50%)',
            transformOrigin: '0% 0%',
            '&$invisible': {
                transform: 'scale(0) translate(-50%, -50%)',
            },
        },
        anchorOriginBottomLeftRectangle: {
            bottom: 2,
            left: 2,
            transform: 'scale(1) translate(-50%, 50%)',
            transformOrigin: '0% 100%',
            '&$invisible': {
                transform: 'scale(0) translate(-50%, 50%)',
            },
        },
    },
});
