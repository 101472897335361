import {ArrowRightSLine as ChevronRightIcon} from "../../../components/CustomIcon";

export default ({palette, typography}) => ({
    defaultProps: {
        separator: <ChevronRightIcon/>,
    },
    styleOverrides: {
        root: {},
        ol: {
            '& svg': {
                fontSize: typography.htmlFontSize,
            },
        },
        li: {
            display: 'flex',
            fontSize: 12,
            '& p': {
                fontSize: 'inherit',
                fontWeight: 'inherit',
            },
            '& a': {
                display: 'flex',
                alignItems: 'center',
                color: palette.text.secondary,
                '&:hover, &:focus': {
                    color: palette.text.primary,
                },
            },
            '& svg': {
                marginRight: 4,
            },
            '&:last-child': {
                fontWeight: typography.fontWeightBold,
            },
            '&:not(:last-child)': {
                textDecoration: 'underline',
            },
        },
        separator: {
            display: 'flex',
            userSelect: 'none',
            marginLeft: 6,
            marginRight: 6,
        },
    },
});
