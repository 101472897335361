export default ({palette, typography}) => ({
    styleOverrides: {
        transitionContainer: {
            height: 20,
            '& > p': {
                fontSize: `16px !important`,
                fontWeight: typography.fontWeightMedium,
            },
        },
        switchHeader: {
            marginTop: 8,
            marginBottom: 4,
            paddingLeft: 8,
            paddingRight: 8,
        },
        daysHeader: {
            maxHeight: 24,
            height: 24,
            borderBottom: `1px solid ${palette.bottomLineColor}`,
        },
    },
});
