export default ({palette}) => ({
    defaultProps: {
        max: 6,
    },
    styleOverrides: {
        root: {},
        avatar: {
            border: `2px solid ${palette.background.paper}`,
            marginLeft: -4,
        },
    },
});
