import {
    Button,
    CircularProgress,
    IconButton,
    ListItemIcon,
    Menu,
    MenuItem,
    Tooltip,
    Typography,
    useTheme
} from "@mui/material";
import LogoutIcon from '@mui/icons-material/Logout';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import {User as UserIcon} from "../CustomIcon";
import {UserMenuStyles} from "./styles";
import {useTranslation} from "react-i18next";
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import UserAvatar from "../UserAvatar/UserAvatar";
import React, {useState} from "react";
import clsx from "clsx";
import {RequestStatus} from "../../models/global.model";
import {authServices} from "../../redux/services/auth.services";


const UserMenu = () => {
    const {t, i18n} = useTranslation();
    const theme = useTheme();
    const dispatch = useAppDispatch();
    const {userData, fetchUserDataStatus} = useAppSelector(state => state.identityReducer)

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const onGoToPlatform = () => window.open(process.env.REACT_APP_APP_FRONT_URL, "_blank")?.focus()
    const onSignIn = () => window.open(`${process.env.REACT_APP_AUTH_FRONT_URL}${i18n.language}?rurl=${encodeURIComponent(window.location.href)}`, "_blank")?.focus()
    const onUserMenuClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => setAnchorEl(null);
    const onLogoutClick = () => dispatch(authServices.logout());

    const name = userData?.userName || userData?.email || '';
    const email = userData?.email;
    const showEmailText = email !== name;
    const isOpen = Boolean(anchorEl);

    if ([RequestStatus.PENDING, RequestStatus.IDLE].includes(fetchUserDataStatus)) return <CircularProgress
        sx={{ml: 16}}/>

    return (
        userData ? <div className={UserMenuStyles.wrapper}>
                <Tooltip
                    title={t('userMenu.iconTooltip')}
                >
                    <IconButton
                        onClick={onUserMenuClick}
                        size="small"
                        aria-controls={isOpen ? 'account-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={isOpen ? 'true' : undefined}
                    >
                        <UserAvatar userData={userData}/>
                    </IconButton>
                </Tooltip>
                <Menu
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: 'center',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={isOpen}
                    onClose={handleClose}
                >

                    <MenuItem divider className={clsx(UserMenuStyles.menuItem(theme), UserMenuStyles.itemUser)}
                    >
                        <ListItemIcon>
                            <UserAvatar userData={userData}/>

                        </ListItemIcon>
                        <div className={UserMenuStyles.menuItemText(theme.palette.text.primary)}>
                            <Typography variant="body1">
                                {name}
                            </Typography>
                            {showEmailText && <Typography color={theme.palette.text.secondary} variant="body2">
                                {email}
                            </Typography>}
                        </div>

                    </MenuItem>
                    <MenuItem divider className={UserMenuStyles.menuItem(theme)}
                              onClick={onGoToPlatform}>
                        <ListItemIcon className={UserMenuStyles.menuItemIcon}>
                            <OpenInNewIcon color="action" fontSize="small"/>
                        </ListItemIcon>
                        <Typography variant="body1">
                            {t('common.goToPlatform')}
                        </Typography>
                    </MenuItem>
                    <MenuItem
                        className={UserMenuStyles.menuItem(theme)}
                        onClick={onLogoutClick}

                    >
                        <ListItemIcon className={UserMenuStyles.menuItemIcon}>
                            <LogoutIcon color="error" fontSize="small"/>
                        </ListItemIcon>
                        <Typography color={theme.palette.error.main} variant="body1">
                            {t('common.logout')}
                        </Typography>
                    </MenuItem>
                </Menu>
            </div>
            :
            <Button
                className={UserMenuStyles.wrapper}
                size="large"
                color="primary"
                onClick={onSignIn}
                endIcon={<UserIcon/>}
            >
                {t('userMenu.signIn')}
            </Button>

    )
}

export default UserMenu;
