export enum DocumentStatusType {
    EDITING = 'editing',
    SIGNING = 'signing',
    ISSUING = 'issuing',
    SIGNED = 'signed',
    REJECTED = 'rejected',
    VALID = 'valid',
    INVALIDATED = 'invalidated',
    TEMPORARILY_INVALIDATE = 'temporarilyInvalidated',
    EXPIRED = 'expired',
    READY_FOR_PREVIEW = 'readyForPreview',
    FINISHED = 'finished',
}

export enum DocumentActionType {
    DocumentHolderChanged = 'DocumentHolderChanged',
    DocumentSigned = 'DocumentSigned',
    DocumentRejected = 'DocumentRejected',
    DocumentRejection = 'DocumentRejection',
    DocumentSignersUpdated = 'DocumentSignersUpdated',
    DocumentRolesUpdated = 'DocumentRolesUpdated',
    DocumentSignersModified = 'DocumentSignersModified',
    DocumentCreated = 'DocumentCreated',
    DocumentUpdated = 'DocumentUpdated',
    TransferabilityChanged = 'TransferabilityChanged',
    DocumentExpired = 'DocumentExpired',
    DocumentValidated = 'DocumentValidated',
    DocumentInvalidated = 'DocumentInvalidated',
    DocumentTemporarilyInvalidated = 'DocumentTemporarilyInvalidated',
    DocumentSignatureRejected = 'DocumentSignatureRejected',
    DocumentUnlinked = 'DocumentUnlinked',
    DocumentLinked = 'DocumentLinked',
}

export enum DocumentRoleType {
    SIGNER = 'Signer',
    ADMIN = 'Admin',
    VIEWER = 'Viewer',
    AUDITOR = 'Auditor',
    HOLDER = 'Holder',
    EDITOR = 'Editor'
}

export enum DocumentRoleStatusType {
    AWAITING = 'AWAITING',
    SIGNED = 'SIGNED',
    REJECTED = 'REJECTED',
}

export type RecipientRole = {
    role: DocumentRoleType,
    status: DocumentRoleStatusType
}

export type participantsDtoType = {
    group: boolean,
    invitation: boolean,
    name: string,
    uuid?: string,
    info?: string,
    roles: RecipientRole[]
}
export type issuer = {
    additionalInfo?: string,
    address?: string,
    city?: string,
    country?: string,
    postcode?: string,
    verified: boolean,
    workspaceName: string
}

export type attachedFile = {
    name: string,
    hash: string
    index: number,
    page: number
}

export type documentDetailsFiles = {
    index: number,
    pages: number,
    name: string
}

export type DocumentDataModel = {
    id: string,
    uuid: string,
    name: string,
    address: string,
    attachedFiles: attachedFile[],
    type: string,
    created: string,
    expiresOn?: string,
    issued?: string,
    participant: boolean,
    publicFile: boolean,
    issuer: issuer,
    status: DocumentStatusType,
    participantsDto: participantsDtoType[]
}


export type DocumentDetailsModel = {
    authorId: string,
    createdAt: string,
    documentType: string,
    files: documentDetailsFiles[],
    id: string,
    name: string,
    signatureLevel: string,
    status: DocumentStatusType,
    workspaceId: string
}

export type DocumentExecutorType = {
    name: string,
    email: string
    uuid: string
}

export type DocumentHistoryEventType = {
    action: DocumentActionType,
    executor: DocumentExecutorType
    signature: string,
    time: string,
    blockNumber: number,
    txIndex: number,
    publicKey: string
}

export type DocumentHistoryModel = {
    id: string,
    events: DocumentHistoryEventType[]
}

export type DownloadFileType = {
    file: string,
    page: number,
    indexFile: number
}
export type DownloadTokenType = {
    token: string,
    page: number,
    indexFile: number
}

export type chipTypeVariant = "default" | "error" | "success" | "warning" | "info" | "primary" | "secondary" | undefined

export type chipType = {
    variant: chipTypeVariant
    short: string,
    long: string
}

