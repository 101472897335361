export default ({palette}) => ({
    styleOverrides: {
        root: {
            height: 'auto',
            '&:hover': {
                background: 'transparent!important',
            },
            '&:hover td': {
                color: palette.primary.main,
            },
            '&:last-child td': {
                borderBottom: 'none'
            }
        },
        head: {
            height: '1.8rem',
        },
        footer: {
            height: '1.8rem',
        },
    },
});
