import React, {useEffect, useState} from 'react';
import {Dialog, DialogContent, DialogTitle, Fab, Fade} from "@mui/material";
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import {resetDocumentPreview, setActiveFileIndex} from "../../redux/reducers/document.reducer";
import {DocumentPreviewStyles} from "./DocumentPreviewStyles";
import {documentServices} from "../../redux/services/document.services";
import {selectDocumentUUID, selectIsCertificate} from "../../redux/selectors/document.selector";
import {usePrevious} from "../../utils/usePrevious";
import {TransitionProps} from "@mui/material/transitions";
import {Close as CloseIcon} from "../CustomIcon";
import DocumentPreviewControls from "./subcomponents/DocumentPreviewControls";
import DocumentImages from "./subcomponents/DocumentImages";
import {RequestStatus} from "../../models/global.model";
import DocumentPreviewLoader from "./subcomponents/DocumentPreviewLoader";


const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Fade in ref={ref} {...props} />;
});

type DocumentPreviewProps = {
    isOpen: boolean,
    onClose: () => void
}

const DocumentPreview = ({isOpen, onClose}: DocumentPreviewProps) => {
    const LOADED_PAGES = 3;
    const dispatch = useAppDispatch();

    const [disabledMenu, setDisabledMenu] = useState(false);
    const isCertificate = useAppSelector(selectIsCertificate)
    const apiService = isCertificate ? 'document-dsl-service' : 'document-service'
    const [documentMaximized, setDocumentMaximized] = useState(false);
    const documentUUID = useAppSelector(selectDocumentUUID)
    const {
        activeFileIndex,
        downloadFiles,
        downloadTokens,
        attachedFiles,
        fetchDownloadTokensStatus,
        fetchDownloadFileStatus
    } = useAppSelector((state) => state.documentReducer, undefined);

    const prevState = usePrevious({
        fetchDownloadFileStatus, fetchDownloadTokensStatus,
        activeFileIndex,
    });

    const checkLoadedThisDocument = (indexFile: number) => {
        if (downloadFiles.filter((item) => item.indexFile === indexFile).length) {
            return -1;
        }
        return indexFile;
    };

    const getDownloadToken = (index?: number) => {
        if (documentUUID) {
            const indexFile = index || activeFileIndex;
            let fileIndexNext = indexFile;
            const activeFilesCount = downloadFiles?.filter(
                (item) => item.indexFile === activeFileIndex
            ).length;
            let pagesSum = activeFilesCount + LOADED_PAGES;
            const filePages = attachedFiles?.filter((item) => item.index === indexFile)[0]?.page;

            if (!filePages && !activeFilesCount) {
                return;
            }

            if (filePages && pagesSum >= filePages) {
                pagesSum = filePages;
            }

            if (pagesSum > activeFilesCount) {
                dispatch(
                    documentServices.getDownloadTokens({
                            documentId: documentUUID,
                            index: indexFile,
                            startPage: activeFilesCount,
                            page: pagesSum,
                            service: apiService
                        }
                    )
                );

            } else {
                if (indexFile < attachedFiles.length - 1) {
                    fileIndexNext = activeFileIndex + 1;
                } else {
                    fileIndexNext = checkLoadedThisDocument(0);
                }
                if (fileIndexNext >= 0) {
                    dispatch(setActiveFileIndex(fileIndexNext));
                }
            }
        }
    };

    const fetchMoreData = () => {
        if (downloadFiles && downloadFiles.length) {
            if (attachedFiles[activeFileIndex].page === 0) {
                getDownloadToken(0);
                return;
            }
            if (fetchDownloadFileStatus !== RequestStatus.PENDING) getDownloadToken();
        }
    };


    const checkShowScroll = () => {
        const scrollElement = document.getElementById('scroll-element-file');

        const hasVerticalScrollbar = scrollElement && scrollElement.scrollHeight > scrollElement.clientHeight;
        if (
            !hasVerticalScrollbar &&
            activeFileIndex === prevState?.activeFileIndex &&
            prevState?.activeFileIndex >= 0 &&
            fetchDownloadTokensStatus !== RequestStatus.PENDING &&
            fetchDownloadFileStatus !== RequestStatus.PENDING
        ) {

            getDownloadToken();
        }
    };


    const fetchDownloadFiles = () => {
        downloadTokens.forEach(
            (token) => {
                if (token.page >=
                    downloadFiles.filter((item) => item.indexFile === activeFileIndex).length &&
                    token.indexFile === activeFileIndex) {
                    dispatch(
                        documentServices.downloadFile(
                            {
                                token: token.token,
                                index: token.indexFile,
                                page: token.page
                            }
                        )
                    );
                }
            }
        )

    };
    useEffect(() => {
        if (fetchDownloadTokensStatus === RequestStatus.SUCCESS) {
            fetchDownloadFiles();
        }
    }, [fetchDownloadTokensStatus]);

    useEffect(() => {
        if (activeFileIndex >= 0) {
            getDownloadToken();
        }
    }, [activeFileIndex]);

    useEffect(() => {
        return () => {
            dispatch(resetDocumentPreview())
        }
    }, [])

    useEffect(() => {
        if (attachedFiles.length > 0) {
            dispatch(setActiveFileIndex(0))
        }
    }, [attachedFiles])


    useEffect(() => {
        if (
            fetchDownloadTokensStatus === RequestStatus.IDLE &&
            prevState?.fetchDownloadTokensStatus !== RequestStatus.IDLE &&
            fetchDownloadFileStatus === RequestStatus.IDLE &&
            prevState?.fetchDownloadFileStatus !== RequestStatus.IDLE &&
            activeFileIndex === prevState?.activeFileIndex
        ) {
            getDownloadToken();
        }
    }, [fetchDownloadTokensStatus, fetchDownloadFileStatus]);

    return <Dialog TransitionComponent={Transition} fullScreen maxWidth={false} open={isOpen}
                   onClose={onClose}>
        <DialogTitle variant={'h4'}>
            <Fab size={"large"} color={"primary"} onClick={onClose} className={DocumentPreviewStyles.closeFab}>
                <CloseIcon/>
            </Fab>
        </DialogTitle>
        <DialogContent className={DocumentPreviewStyles.dialogContent}>
            <div className={DocumentPreviewStyles.root} id="scroll-element-file">
                <DocumentPreviewControls
                    disabledMenu={disabledMenu}
                    documentMaximized={documentMaximized}
                    setDocumentMaximized={setDocumentMaximized}
                    setDisabledMenu={setDisabledMenu}/>
                <DocumentImages
                    documentMaximized={documentMaximized}
                    fetchMoreData={fetchMoreData}
                    checkShowScroll={checkShowScroll}/>
                <DocumentPreviewLoader/>
            </div>
        </DialogContent>
    </Dialog>
}

export default DocumentPreview;
