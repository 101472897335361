export default {
    styleOverrides: {
        root: {
            '& svg': {
                fontSize: 20,
            },
        },
        positionEnd: {
            marginLeft: 8,
            marginRight: 2,
        },
        positionStart: {
            marginLeft: 2,
            marginRight: 8,
        },
    },
};
