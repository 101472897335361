import React from 'react';
import {DocumentPreviewStyles} from "../DocumentPreviewStyles";
import clsx from "clsx";
import {Fade, Typography, useMediaQuery, useTheme} from "@mui/material";
import InfiniteScroll from "react-infinite-scroll-component";
import {useAppSelector} from "../../../redux/hooks";
import {selectDocumentStatus, selectDocumentType} from "../../../redux/selectors/document.selector";
import {DocumentStatusType} from "../../../models/document.model";
import {mapDocumentStatusTypeToChipType} from "../../../utils/global";
import EmptyBanner from "../../EmptyBanner/EmptyBanner";
import {VisibilityOff} from "../../CustomIcon";
import EmptyBannerStyles from "../../EmptyBanner/EmptyBannerStyles";
import {useTranslation} from "react-i18next";


type DocumentImagesProps = {
    fetchMoreData: () => void,
    checkShowScroll: () => void,
    documentMaximized: boolean
}

const DocumentImages = ({fetchMoreData, documentMaximized, checkShowScroll}: DocumentImagesProps) => {
    const theme = useTheme();
    const largeContent = useMediaQuery((theme.breakpoints.up('lg')));
    const documentStatus = useAppSelector(selectDocumentStatus);
    const documentType = useAppSelector(selectDocumentType)
    const isDocumentFinished = documentStatus === DocumentStatusType.FINISHED;
    const {t} = useTranslation();
    const {
        downloadFiles,
        attachedFiles,
    } = useAppSelector((state) => state.documentReducer, undefined);

    return <InfiniteScroll
        dataLength={downloadFiles.length}
        next={fetchMoreData}
        refreshFunction={fetchMoreData}
        pullDownToRefresh
        scrollThreshold="200px"
        pullDownToRefreshThreshold={200}
        hasMore
        style={{
            overflow: 'initial',
            position: 'relative',
        }}
        loader={false}
        scrollableTarget="scroll-element-file"
    >
        <div className={DocumentPreviewStyles.inner}>
            <div className={DocumentPreviewStyles.documentWrapper}>
                {downloadFiles && attachedFiles &&
                    downloadFiles.slice().sort((a, b) => {
                        if (a.indexFile === b.indexFile) {
                            return a.page - b.page
                        } else return 0
                    }).map((src, index) => (
                        <div
                            key={index}
                            className={clsx(
                                DocumentPreviewStyles.imageContainer,
                                !src.file.length && DocumentPreviewStyles.imageEmptyContainer,
                                (documentMaximized || !largeContent) && DocumentPreviewStyles.imageMaximized,
                                `document-number document-number-${src.indexFile}`
                            )}
                            id={src.page === 0 ? `document-number-${src.indexFile}` : undefined}
                        >
                            <div className={DocumentPreviewStyles.imageInner}>
                                {isDocumentFinished && (
                                    <Fade in>
                                        <div className={DocumentPreviewStyles.watermark}>
                                            <div className={DocumentPreviewStyles.watermarkInner}>
                                                <Typography component="span">
                                                    {mapDocumentStatusTypeToChipType(documentStatus, t, documentType).short}
                                                </Typography>
                                            </div>
                                        </div>
                                    </Fade>
                                )}
                                {!src.file.length && attachedFiles.length ? (
                                    <div className={DocumentPreviewStyles.emptyImageBoxContainer}>
                                        <EmptyBanner
                                            src="/images/frames/no-workspace.svg"
                                            disabledImage
                                            title={attachedFiles[src.indexFile]?.name}
                                            onLoad={() => checkShowScroll()}
                                            description={t('documentPage.emptyImageDescription')}
                                            titleVariant="h3"

                                        >
                                            <VisibilityOff className={EmptyBannerStyles.emptyImageIcon}/>
                                        </EmptyBanner>
                                    </div>
                                ) : (
                                    <img src={src.file} onLoad={checkShowScroll}
                                         alt={index.toString()}/>
                                )}
                            </div>
                        </div>
                    ))}
            </div>
        </div>
    </InfiniteScroll>
}

export default DocumentImages;
