import MuiButton from "./MuiButton";
import MuiAlert from "./MuiAlert";
import MuiAlertTitle from "./MuiAlertTitle";
import MuiAppBar from "./MuiAppBar";
import MuiAutocomplete from "./MuiAutocomplete";
import MuiAvatar from "./MuiAvatar";
import MuiInput from "./MuiInput";
import MuiContainer from "./MuiContainer";
import MuiInputAdornment from "./MuiInputAdornment";
import MuiInputBase from "./MuiInputBase";
import MuiAvatarGroup from "./MuiAvatarGroup";
import MuiBackdrop from "./MuiBackdrop";
import MuiBadge from "./MuiBadge";
import MuiBottomNavigation from "./MuiBottomNavigation";
import MuiBottomNavigationAction from "./MuiBottomNavigationAction";
import MuiBreadcrumbs from "./MuiBreadcrumbs";
import MuiButtonBase from "./MuiButtonBase";
import MuiButtonGroup from "./MuiButtonGroup";
import MuiCard from "./MuiCard";
import MuiCardActions from "./MuiCardActions";
import MuiCardContent from "./MuiCardContent";
import MuiCardHeader from "./MuiCardHeader";
import MuiCssBaseline from "./MuiCssBaseline";
import MuiCheckbox from "./MuiCheckbox";
import MuiChip from "./MuiChip";
import MuiDialog from "./MuiDialog";
import MuiDialogActions from "./MuiDialogActions";
import MuiDialogContent from "./MuiDialogContent";
import MuiDialogTitle from "./MuiDialogTitle";
import MuiDrawer from "./MuiDrawer";
import MuiFab from "./MuiFab";
import MuiFormControl from "./MuiFormControl";
import MuiFormControlLabel from "./MuiFormControlLabel";
import MuiFormGroup from "./MuiFormGroup";
import MuiFormHelperText from "./MuiFormHelperText";
import MuiFormLabel from "./MuiFormLabel";
import MuiIconButton from "./MuiIconButton";
import MuiInputLabel from "./MuiInputLabel";
import MuiLinearProgress from "./MuiLinearProgress";
import MuiList from "./MuiList";
import MuiListItem from "./MuiListItem";
import MuiListItemIcon from "./MuiListItemIcon";
import MuiListItemSecondaryAction from "./MuiListItemSecondaryAction";
import MuiListItemText from "./MuiListItemText";
import MuiListSubheader from "./MuiListSubheader";
import MuiNativeSelect from "./MuiNativeSelect";
import MuiMenuItem from "./MuiMenuItem";
import MuiOutlinedInput from "./MuiOutlinedInput";
import MuiPickersArrowSwitcher from "./MuiPickersArrowSwitcher";
import MuiPickersBasePicker from "./MuiPickersBasePicker";
import MuiPickersCalendar from "./MuiPickersCalendar";
import MuiPickersCalendarHeader from "./MuiPickersCalendarHeader";
import MuiPickersDatePickerRoot from "./MuiPickersDatePickerRoot";
import MuiPickersDateRangeDay from "./MuiPickersDateRangeDay";
import MuiPickersDateRangePickerInput from "./MuiPickersDateRangePickerInput";
import MuiPickersDateRangePickerToolbarProps from "./MuiPickersDateRangePickerToolbarProps";
import MuiPickersDay from "./MuiPickersDay";
import MuiPickersDesktopDateRangeCalendar from "./MuiPickersDesktopDateRangeCalendar";
import MuiPickersModalDialog from "./MuiPickersModalDialog";
import MuiPickersPopper from "./MuiPickersPopper";
import MuiPickersToolbar from "./MuiPickersToolbar";
import MuiPickersToolbarButton from "./MuiPickersToolbarButton";
import MuiPickersToolbarText from "./MuiPickersToolbarText";
import MuiPickersYear from "./MuiPickersYear";
import MuiPickersYearSelection from "./MuiPickersYearSelection";
import MuiPaper from "./MuiPaper";
import MuiPopover from "./MuiPopover";
import MuiRadio from "./MuiRadio";
import MuiSelect from "./MuiSelect";
import MuiSlider from "./MuiSlider";
import MuiSnackbar from "./MuiSnackbar";
import MuiSnackbarContent from "./MuiSnackbarContent";
import MuiSwitch from "./MuiSwitch";
import MuiTableCell from "./MuiTableCell";
import MuiTableRow from "./MuiTableRow";
import MuiTableSortLabel from "./MuiTableSortLabel";
import MuiTab from "./MuiTab";
import MuiTabs from "./MuiTabs";
import MuiToolbar from "./MuiToolbar";
import MuiTooltip from "./MuiTooltip";
import MuiTouchRipple from "./MuiTouchRipple";
import MuiTypography from "./MuiTypography";
import PrivateBreadcrumbCollapsed from "./PrivateBreadcrumbCollapsed";
import PrivateNotchedOutline from "./PrivateNotchedOutline";
import PrivateRadioButtonIcon from "./PrivateRadioButtonIcon";
import PrivateSwitchBase from "./PrivateSwitchBase";
import MuiTextField from "./MuiTextField";
import MuiCircularProgress from "./MuiCircularProgress";
import MuiLabel from "./MuiLabel";
import MuiHidden from "./MuiHidden";
import MuiToggleButtonGroup from "./MuiToggleButtonGroup";
import MuiLink from "./MuiLink";
import MuiSkeleton from "./MuiSkeleton";


export default ({palette, typography, shadows}) => ({
    MuiAlert,
    MuiAlertTitle,
    MuiAppBar: MuiAppBar({palette}),
    MuiAutocomplete: MuiAutocomplete({palette}),
    MuiAvatar,
    MuiAvatarGroup: MuiAvatarGroup({palette}),
    MuiBackdrop: MuiBackdrop({palette}),
    MuiBadge: MuiBadge({palette}),
    MuiBottomNavigation: MuiBottomNavigation({palette}),
    MuiBottomNavigationAction,
    MuiBreadcrumbs: MuiBreadcrumbs({palette, typography}),
    MuiButton: MuiButton({palette, typography}),
    MuiButtonBase,
    MuiButtonGroup: MuiButtonGroup({palette}),
    MuiCard,
    MuiCardActions,
    MuiCardContent,
    MuiCardHeader,
    MuiCircularProgress,
    MuiCssBaseline: MuiCssBaseline({palette, typography}),
    MuiCheckbox: MuiCheckbox({palette}),
    MuiChip: MuiChip({palette, typography}),
    MuiContainer,
    MuiDialog,
    MuiDialogActions,
    MuiDialogContent,
    MuiDialogTitle: MuiDialogTitle({typography}),
    MuiDrawer,
    MuiFab: MuiFab({palette, shadows, typography}),
    MuiFormControl,
    MuiFormControlLabel,
    MuiFormGroup,
    MuiFormHelperText,
    MuiFormLabel: MuiFormLabel({palette, typography}),
    MuiIconButton: MuiIconButton({palette}),
    MuiInput: MuiInput({palette, typography}),
    MuiInputAdornment,
    MuiInputBase,
    MuiInputLabel,
    MuiLabel,
    MuiHidden,
    MuiLinearProgress: MuiLinearProgress({palette}),
    MuiList,
    MuiListItem: MuiListItem({palette, typography}),
    MuiListItemIcon,
    MuiListItemSecondaryAction,
    MuiListItemText,
    MuiListSubheader: MuiListSubheader({palette, typography}),
    MuiLink,
    MuiNativeSelect,
    MuiMenuItem: MuiMenuItem({palette, typography}),
    MuiOutlinedInput: MuiOutlinedInput({palette, shadows, typography}),
    MuiPickersArrowSwitcher,
    MuiPickersBasePicker: MuiPickersBasePicker({palette}),
    MuiPickersCalendar: MuiPickersCalendar({palette}),
    MuiPickersCalendarHeader: MuiPickersCalendarHeader({palette, typography}),
    MuiPickersDatePickerRoot,
    MuiPickersDateRangeDay: MuiPickersDateRangeDay({palette}),
    MuiPickersDateRangePickerInput,
    MuiPickersDateRangePickerToolbarProps,
    MuiPickersDay: MuiPickersDay({palette, typography}),
    MuiPickersDesktopDateRangeCalendar: MuiPickersDesktopDateRangeCalendar({palette}),
    MuiPickersModalDialog,
    MuiPickersPopper,
    MuiPickersToolbar: MuiPickersToolbar({palette}),
    MuiPickersToolbarButton,
    MuiPickersToolbarText: MuiPickersToolbarText({typography}),
    MuiPickersYear,
    MuiPickersYearSelection,
    MuiPaper: MuiPaper({palette}),
    MuiPopover: MuiPopover({palette}),
    MuiRadio: MuiRadio({palette}),
    MuiSelect,
    MuiSkeleton,
    MuiSlider: MuiSlider({palette}),
    MuiSnackbar,
    MuiSnackbarContent,
    MuiSwitch: MuiSwitch({palette}),
    MuiTableCell: MuiTableCell({palette, typography}),
    MuiTableRow: MuiTableRow({palette}),
    MuiTableSortLabel: MuiTableSortLabel({palette, typography}),
    MuiTab: MuiTab({palette, typography}),
    MuiTabs,
    MuiTextField,
    MuiToggleButtonGroup,
    MuiToolbar: MuiToolbar({palette}),
    MuiTooltip: MuiTooltip({palette, typography}),
    MuiTouchRipple,
    MuiTypography: MuiTypography({palette, typography}),
    PrivateBreadcrumbCollapsed: PrivateBreadcrumbCollapsed({palette}),
    PrivateNotchedOutline,
    PrivateRadioButtonIcon,
    PrivateSwitchBase,
});
