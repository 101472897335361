import {css} from "@emotion/css";
import {mediaQueryMax} from "../../theme/options/breakpoints";


const container = css`
  padding: 15px 40px;

  ${mediaQueryMax.sm} {
    padding: 15px 20px;
  }
`

const tableButtonIcon = css`
  height: 16px !important;
  width: 16px !important;
`
const row = (color: string, border: string) => css`
  cursor: pointer;

  &:hover button {
    color: ${color};
    border: 1px solid ${border};

  }
`

const sortArrow = (isDesc: boolean) => css`
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid #576273;
  margin-left: 5px;
  border-radius: 2px;
  transform: rotate(0deg);
  margin-top: -2px;
  ${isDesc && `
    transform: rotate(180deg);
    padding-top: 2px;
    margin-top: 0;
  `}
`

const thead = (color: string) => css`
  & th {
    color: ${color};
    font-weight: 500;
  }

  & .MuiTypography-root {
    color: ${color};
  }
`


export const SearchResultsStyles = {
    container,
    tableButtonIcon,
    row,
    sortArrow,
    thead
}
