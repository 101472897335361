import {breakpointValues} from '../breakpoints';

export default {
    styleOverrides: {
        root: {
            padding: `0 16px 16px 16px`,
            [`@media (min-width: ${breakpointValues.md}px)`]: {
                padding: `0 24px 16}px 24px`,
            },
            [`@media (min-width: ${breakpointValues.lg}px)`]: {
                padding: `0 40px 20px 40px`,
            },
        },
    },
};
