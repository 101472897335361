import palette from '../palette';
import {darken, lighten} from "@mui/material";

const getColor = function getColor(color) {
    return palette.type === 'light' ? lighten(color, 0.75) : darken(color, 0.5);
};

export default ({palette}) => ({
    styleOverrides: {
        bar: {
            transition: 'transform 0.15s linear',
        },
        bar1Determinate: {
            transition: 'transform 0.15s linear',
        },
        bar1Buffer: {
            transition: 'transform 0.15s linear',
        },
        bar2Buffer: {
            transition: 'transform 0.15s linear',
        },
        colorPrimary: {
            backgroundColor: getColor(palette.primary.main),
        },
    },
});
