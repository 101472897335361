import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {Container, Typography, useTheme} from "@mui/material";
import {SearchPageStyles} from "./styles";
import Loader from "../../components/Loader/Loader";
import NoMatchesFound from "../../components/NoMatchesFound/NoMatchesFound";
import SearchResultsTable from "../../components/SearchResultsTable/SearchResultsTable";
import {useTranslation} from "react-i18next";
import {documentServices} from "../../redux/services/document.services";
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import {RequestStatus} from "../../models/global.model";
import {shorthandOptString} from "../../utils/global";
import {RoutesObj} from "../../Router";
import {resetSearch} from "../../redux/reducers/document.reducer";

function SearchPage() {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const {t} = useTranslation();
    const {term} = useParams();
    const theme = useTheme();
    const [newSearch, setNewSearch] = useState(false);
    const {documentData: searchResults, fetchDocumentDataStatus} = useAppSelector(state => state.documentReducer)
    const isLoading = fetchDocumentDataStatus === RequestStatus.PENDING;
    const isNoData = !searchResults || searchResults.length === 0;

    useEffect(() => {
        dispatch(resetSearch()) // reset search on mount
    }, [])

    useEffect(() => {
        if (fetchDocumentDataStatus === RequestStatus.PENDING) { // set new search to prevent redirecting
            setNewSearch(true)
        }
    }, [fetchDocumentDataStatus])

    useEffect(() => {
        if (searchResults && searchResults.length === 1 && newSearch) { // if only one result then automatically redirect to document page
            navigate(RoutesObj.DocumentPage.toPath(searchResults[0].uuid))
        }
    }, [searchResults, newSearch])

    useEffect(() => {
        if (term && fetchDocumentDataStatus === RequestStatus.IDLE) { // fetch if no request yet
            dispatch(documentServices.fetchDocumentData(term))
        }
    }, [term, fetchDocumentDataStatus])

    return (
        <Container className={SearchPageStyles.container(isNoData && !isLoading)}>
            <div className={SearchPageStyles.pageWrapper}>
                {isLoading
                    ? <Loader/>
                    : (isNoData
                        ? <NoMatchesFound term={term}/>
                        : <>
                            {searchResults && searchResults.length > 1 && <>
                                <div className={SearchPageStyles.title}>
                                    {searchResults && <Typography variant="h3" mb="16px">
                                        {t('searchPage.title', {
                                            count: searchResults.length
                                        })}
                                    </Typography>}
                                    <Typography variant="h3" mb="16px" color={theme.palette.primary.main}>
                                        {shorthandOptString({term, limit: 13})}
                                    </Typography>
                                </div>
                                <SearchResultsTable/>
                            </>
                            }
                        </>)}
            </div>
        </Container>)
}

export default SearchPage;
