export default ({palette}) => ({
    defaultProps: {
        centerRipple: false,
    },
    styleOverrides: {
        root: {
            fontSize: 24,
            padding: 12,
            '&:hover': {
                backgroundColor: palette.action.hover,
                '@media (hover: none)': {
                    backgroundColor: 'transparent',
                },
                '&$disabled': {
                    backgroundColor: 'transparent',
                },
            },
        },
        sizeSmall: {
            padding: 6,
            fontSize: 20,
        },
    },
});
