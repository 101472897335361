import {css} from "@emotion/css";

const textWrapper = css`
  display: flex;
  margin-top: 16px;
`

const container = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
`
const circular = css`
  width: 80px !important;
  height: 80px !important;
`

const box = css`
  height: 15px;
  width: 105px;
  display: flex;
  position: relative;
  transform: scale(0.3);
`


export const LoaderStyles = {
    textWrapper,
    box,
    container,
    circular
}
