import {css} from "@emotion/css";


const searchWrapper = css``
const search = css`
  width: 100%;
`
const searchPadding = css`
  width: 100%;
  margin-bottom: 14px;

  & .MuiInputBase-input {
    height: 64px;
  }
`
const dropdown = css`
  position: absolute;
  top: 100%;
  margin-top: 8px;
  background-color: #fff;
  border: 1px solid #e7eaf4;
  width: 100%;
  border-radius: 6px;
  z-index: 10;
  max-height: 400px;
  overflow: scroll;
`
const dropdownItem = css`
  width: 100%;

`

export const SearchStyles = {
    search,
    searchPadding,
    searchWrapper,
    dropdown,
    dropdownItem,
}
