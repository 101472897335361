import {DocumentImageStyles} from "../styles";
import {Typography, useTheme} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useAppSelector} from "../../../redux/hooks";

const PreviewNotLoggedIn = () => {
    const {t, i18n} = useTranslation()
    const theme = useTheme();
    const isLoggedIn = useAppSelector(state => state.identityReducer.isLoggedIn)
    const signInUrl = `${process.env.REACT_APP_AUTH_FRONT_URL}${i18n.language}?rurl=${encodeURIComponent(window.location.href)}`;

    return <div className={DocumentImageStyles.textWrapper}>
        <Typography color={theme.palette.text.secondary}
                    variant="body1">
            <b>{isLoggedIn ? t('documentPage.imageUnavailableTitleLoggedIn') : t('documentPage.imageUnavailableTitle')}</b>
        </Typography>
        <Typography whiteSpace="break-spaces" color={theme.palette.text.secondary}
                    variant="body2">
            {isLoggedIn ? <div> {t('documentPage.imageUnavailableDescriptionLoggedIn')}</div> :
                <div>
                    {t('documentPage.imageUnavailableDescriptionLoggedOut1')}
                    <a href={signInUrl} target="_self">{t('documentPage.imageUnavailableSignIn')}</a>
                    {t('documentPage.imageUnavailableDescriptionLoggedOut2')}
                </div>}
        </Typography>
    </div>
}

export default PreviewNotLoggedIn;
