import React from 'react';
import clsx from 'clsx';
import {Box, Button, CircularProgress, Typography} from "@mui/material";
import EmptyBannerStyles from "./EmptyBannerStyles";

type titleVariant = 'body1' |
    'body2' |
    'button' |
    'caption' |
    'h1' |
    'h2' |
    'h3' |
    'h4' |
    'h5' |
    'h6' |
    'inherit' |
    'overline' |
    'subtitle1' |
    'subtitle2'

type EmptyBannerProps = {
    children: JSX.Element
    bottomContent?: JSX.Element,
    src: string,
    title: string,
    titleVariant: titleVariant,
    description: string,
    buttonLabel?: string,
    disabledImage: boolean,
    onClick?: () => void,
    onLoad: () => void,
};


const EmptyBanner = ({
                         children,
                         bottomContent,
                         src,
                         title,
                         titleVariant = 'h1',
                         description,
                         buttonLabel,
                         disabledImage,
                         onClick,
                         onLoad,
                     }: EmptyBannerProps) => {

    return (
        <div className={EmptyBannerStyles.root}>
            <Box textAlign="center" className={EmptyBannerStyles.inner}>
                {src && (
                    <div
                        className={clsx(disabledImage ? EmptyBannerStyles.disabled : EmptyBannerStyles.imageContainer)}>
                        {!disabledImage ? <img
                                src={src}
                                onLoad={onLoad}
                                className={EmptyBannerStyles.image}
                            />
                            : <CircularProgress className={EmptyBannerStyles.imageLoader}/>}
                    </div>
                )}
                <div>
                    {children}

                    {title && (
                        <Typography variant={titleVariant} component="h4" gutterBottom>
                            <strong>{title}</strong>
                        </Typography>
                    )}
                    {description && (
                        <Typography component="p" color="textSecondary">
                            {description}
                        </Typography>
                    )}
                    {bottomContent}
                </div>

                {onClick && buttonLabel && (
                    <Button onClick={onClick} size="large" className={EmptyBannerStyles.button}>
                        {buttonLabel}
                    </Button>
                )}
            </Box>
        </div>
    );
};

export default EmptyBanner;
