import React from 'react';
import {Chip, Paper, Table, TableBody, TableCell, TableRow, Typography, useTheme} from "@mui/material";
import {PaperDataGridStyles} from "./styles";
import IconTooltip from "../IconTooltip/IconTooltip";
import {useSnackbar} from "notistack";
import {useTranslation} from "react-i18next";
import {chipType} from "../../models/document.model";


export enum TextActionType {
    Copy,
    Download
}


type itemValueType = {
    titleVal?: string,
    titleActionType?: TextActionType,
    titleAfterComponent?: JSX.Element,
    subtitle?: string,
    subtitleActionType?: TextActionType,
    descriptionVal?: string,
    descriptionActionType?: TextActionType,
}

type itemType = {
    label?: string,
    tooltip?: string,
    values?: itemValueType[],
    chips?: chipType[]
}

interface PaperDataGridItem {
    item: itemType,
    smallPadding: boolean
}

export type PaperDataGridItems = itemType[]

export interface PaperDataGridProps {
    items: PaperDataGridItems
    withShadow?: boolean,
    smallPadding?: boolean,
}

const PaperDataGridItem = ({item, smallPadding = false}: PaperDataGridItem) => {
    const {enqueueSnackbar} = useSnackbar()
    const {t} = useTranslation()

    const theme = useTheme();

    const copyToClipBoard = (value?: string) => {
        enqueueSnackbar(t('common.copied'), {
            variant: 'info',
        })
        if (value) {
            navigator.clipboard.writeText(value)
        }

    }

    const onTitleClick = (item: itemValueType) => {
        if (item.titleVal && item.titleActionType === TextActionType.Copy) {
            copyToClipBoard(item.titleVal.replace('uuid: ', ''))
        }
    }

    const onSubtitleClick = (item: itemValueType) => {
        if (item.subtitle && item.subtitleActionType === TextActionType.Copy) {
            copyToClipBoard(item.subtitle.replace('uuid: ', ''))
        }
    }
    const onDescriptionClick = (item: itemValueType) => {
        if (item.descriptionActionType === TextActionType.Copy) {
            copyToClipBoard(item.descriptionVal)
        }
    }

    return <TableRow className={PaperDataGridStyles.item(smallPadding)}>
        {item.label && <TableCell className={PaperDataGridStyles.itemFirstColumn}>
            <div className={PaperDataGridStyles.labelWrapper}>
                <IconTooltip message={item.tooltip}/>
                <Typography variant="h6" color={theme.palette.text.secondary}>
                    {item.label}
                </Typography>
            </div>
        </TableCell>}
        <TableCell className={PaperDataGridStyles.itemSecondColumn}>
            <>  {item.values && item.values.map((value, index) => <div
                key={`PaperDataGridItem_${index}_${item.label}`}>
                <div className={PaperDataGridStyles.titleWrapper}>
                    {value.titleVal && <div
                        className={value.titleActionType === TextActionType.Copy ? PaperDataGridStyles.itemAction(theme.palette.primary.main) : ''}
                        onClick={() => onTitleClick(value)}>
                        <Typography lineHeight={1} mb={1} whiteSpace="pre-line" variant="h6"
                                    color={theme.palette.text.primary}>
                            {value.titleVal}
                        </Typography>

                    </div>}
                    {value.titleAfterComponent}
                </div>
                {value.descriptionVal &&
                    <div
                        className={value.descriptionActionType === TextActionType.Copy ? PaperDataGridStyles.itemAction(theme.palette.primary.main) : ''}
                        onClick={() => onDescriptionClick(value)}>
                        <Typography
                            variant="body2"
                            whiteSpace="break-spaces"
                            color={theme.palette.grey[500]}>
                            {value.descriptionVal}
                        </Typography>
                    </div>}
                {value.subtitle &&
                    <div
                        className={value.subtitleActionType === TextActionType.Copy ? PaperDataGridStyles.itemAction(theme.palette.primary.main) : ''}
                        onClick={() => onSubtitleClick(value)}>
                        <Typography
                            variant="body2"
                            whiteSpace="break-spaces"
                            color={theme.palette.grey[500]}>
                            {value.subtitle}
                        </Typography>
                    </div>}
                {item.values?.length !== index + 1 && <div className={PaperDataGridStyles.columnBreak}/>}
            </div>)}
                <div>
                    {item.chips && item.chips.map((chip, chipIdx) =>
                        <Chip key={`chip_${chip.short}_${chip.variant}_${chipIdx}`} className={PaperDataGridStyles.chip}
                              color={chip.variant} label={chip.short}
                              style={{
                                  marginTop: smallPadding ? 8 : 0
                              }}
                        />
                    )}
                </div>

            </>
        </TableCell>
    </TableRow>
}

const PaperDataGrid = ({items, withShadow = true, smallPadding = false}: PaperDataGridProps) => {
    return (
        <Paper className={PaperDataGridStyles.container} elevation={withShadow ? 12 : 0}>
            <Table>
                <TableBody>
                    {items.map(((item, index) =>
                        <PaperDataGridItem
                            key={`paper_data_${index}`}
                            item={item}
                            smallPadding={smallPadding}/>))}
                </TableBody>
            </Table>
        </Paper>
    );
}

export default PaperDataGrid
