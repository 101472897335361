import {alpha} from "@mui/material";
import {
    CheckboxBlankOutlined as CheckboxIcon,
    CheckboxMarked as CheckboxCheckedIcon,
    IndeterminateCheckbox as CheckboxIndeterminateIcon
} from "../../../components/CustomIcon";

export default ({palette}) => ({
    defaultProps: {
        color: 'primary',
        icon: <CheckboxIcon/>,
        checkedIcon: <CheckboxCheckedIcon/>,
        indeterminateIcon: <CheckboxIndeterminateIcon/>,
    },
    styleOverrides: {
        root: {
            '&$checked': {
                '& + span': {
                    '& a': {
                        textDecoration: 'underline',
                    },
                },
            },
            '&$disabled': {
                '& svg': {
                    filter: 'none !important',
                },
            },
        },
        colorPrimary: {
            color: palette.bottomLineColor,
            '&$checked': {
                color: palette.primary.main,
                '& svg': {
                    filter: `drop-shadow(0 3px 6px ${alpha(palette.primary.main, 0.225)})`,
                },
            },
            '&$disabled': {
                color: palette.action.disabledBackground,
            },
        },
        colorSecondary: {
            color: palette.error.main,
            '&$checked': {
                color: palette.error.main,
                '& svg': {
                    filter: `drop-shadow(0 3px 6px ${alpha(palette.error.main, 0.225)})`,
                },
            },
            '&$disabled': {
                color: palette.action.disabledBackground,
            },
        },
    },
});
