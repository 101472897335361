export default ({palette, shadows, typography}) => ({
    styleOverrides: {
        root: {
            position: 'relative',
            borderRadius: 6,
            backgroundColor: palette.background.paper,
            transition: 'all 0.25s linear',
            '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: palette.primary.main,
                borderWidth: '1px!important',
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: palette.primary.main,
                borderWidth: 1,
                boxShadow: shadows[8],
            },
            '& input': {
                '&[readonly]': {
                    cursor: 'default',
                },
            },
            '&:hover $notchedOutline': {
                borderColor: palette.primary.main,
            },
            '&$focused $notchedOutline': {
                borderColor: palette.primary.main,
                borderWidth: 1,
                boxShadow: shadows[8],
            },
            '&$error $notchedOutline': {
                borderColor: palette.error.main,
            },
            '&$disabled $notchedOutline': {
                borderColor: palette.bottomLineColor,
            },
            '&$disabled': {
                backgroundColor: palette.secondary.light,
            },
            '&.Mui-error': {
                '& input, & .MuiSelect-root': {
                    color: palette.error.main,
                },
            },
        },
        colorSecondary: {
            '&$focused $notchedOutline': {
                borderColor: palette.secondary.main,
            },
        },
        focused: {},
        disabled: {},
        adornedStart: {
            paddingLeft: 12,
        },
        adornedEnd: {
            paddingRight: 12,
        },
        error: {},
        marginDense: {},
        notchedOutline: {
            transition: 'all 0.25s linear',
            borderColor: palette.bottomLineColor,
            borderWidth: 1,
        },
        multiline: {
            padding: '12px',
            minHeight: 48,
            '&$marginDense': {
                paddingTop: 10.5,
                paddingBottom: 10.5,
            },
        },
        input: {
            fontSize: '1rem',
            fontWeight: typography.fontWeightRegular,
            padding: 12,
            minHeight: 48,
            boxSizing: 'border-box',
            '&:-webkit-autofill': {
                borderRadius: 'inherit',
            },
            '&::placeholder': {
                fontWeight: typography.fontWeightRegular,
                color: `${palette.secondary.main} !important`,
                opacity: 1,
            },
        },
        inputMarginDense: {
            paddingTop: 10.5,
            paddingBottom: 10.5,
        },
        inputMultiline: {
            padding: 0,
        },
        inputAdornedStart: {
            paddingLeft: 0,
        },
        inputAdornedEnd: {
            paddingRight: 0,
        },
    },
});
