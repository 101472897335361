import {css} from "@emotion/css";
import {mediaQueryMax, mediaQueryMin} from "../../theme/options/breakpoints";

const root = css`
  padding: 32px;
  top: 0;
  flex: 1;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  position: absolute;
  overflow-x: hidden;
  overflow-y: scroll;
  padding-bottom: 0;
`

const dialogContent = css`
  ${mediaQueryMax.sm} {
    padding: 20px;
  }
`

const inner = css`
  display: flex;
  min-height: 100%;

  ${mediaQueryMin.md} {
    padding-left: 64px;
  }
`
const documentWrapper = css`
  flex: 1;
  outline: 0;
`

const imageEmptyContainer = css``
const imageInner = css`
  width: 100%;
  margin: 0 auto;
  display: block;
  overflow: hidden;
  position: relative;
  max-width: 210mm;
  box-shadow: 0 7px 8px -4px rgb(0 0 0 / 4%), 0 9px 21px 3px rgb(0 0 0 / 2%), 0 5px 20px 4px rgb(0 0 0 / 2%);

  img {
    width: 100%;
    margin: 0;
    display: block;
  }
`
const watermark = css`
  top: 0;
  right: 0;
  width: 60%;
  z-index: 1;
  position: absolute;
  max-width: 152px;
  transform: rotate(45deg);
  pointer-events: none;
`
const watermarkInner = css`
  padding-bottom: 100%;

  span {
    top: 10%;
    color: #FFFFFF;
    width: 120%;
    height: 18%;
    display: flex;
    opacity: 0.7;
    position: absolute;
    font-size: 12px;
    transform: translateY(-50%);
    align-items: center;
    line-height: 1;
    margin-left: -10%;
    justify-content: center;
    background-color: #16B15C;
  }

  ${mediaQueryMin.xs} {
    span {
      top: 13%;
      height: 20%;
    }
  }

  ${mediaQueryMin.sm} {
    span {
      top: 20%;
      height: 22%;
    }
  }

  ${mediaQueryMin.md} {
    span {
      top: 13%;
      height: 20%;
    }
  }

  ${mediaQueryMin.lg} {
    span {
      top: 20%;
      height: 22%;
    }
  }
`

const imageContainer = css`
  display: flex;
  position: relative;
  min-height: auto;
  align-items: center;
  margin-bottom: 48px;
  justify-content: center;

  ${mediaQueryMax.xl} {
    margin-bottom: 32px;
  }

  ${mediaQueryMax.md} {
    margin-bottom: 24px;
  }

  ${mediaQueryMax.sm} {
    margin-bottom: 24px;
  }
`
const imageMaximized = css`
  img, & > div {
    max-width: 100% !important;
  }
`
const emptyImageBoxContainer = css`
  position: relative;
  width: 100%;
`
const closeFab = css`
  position: absolute;
  right: 40px;
  top: 40px;
`

const documentControlsWrapper = css`
  top: 50%;
  left: 0;
  height: 0;
  z-index: 99;
  position: sticky;
  transform: translateY(-50%);

  ${mediaQueryMax.sm} {
    margin-left: -14px;
  }
`
const documentControlsWrapperInner = css`
  display: flex;
  transform: translateY(-50%);
  flex-direction: column;
  justify-content: center;
`
const activeLayersFabButton = css``
const layersMenu = css`
  min-width: 200px;
  margin-left: 8px;
`
const layersMenuCloseButton = css`
  margin-right: -4px;
  width: 24px;
  min-width: 24px;
  height: 24px;
  min-height: 24px;

  svg {
    width: 16px;
    height: 16px
  }
`
const layersMenuItem = css`
  font-size: 12px;
  padding-right: 48px;
`
const layersMenuItemIcon = css`
  display: flex;
  align-items: center;

  svg {
    width: 16px;
    height: 16px;
  }
`
const loaderFile = css`
  position: fixed;
  left: 8px;
  right: 8px;
  bottom: 0;
  padding: 4px;
  pointer-events: none;
`
const firstLoader = css`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`

export const DocumentPreviewStyles = {
    root,
    dialogContent,
    closeFab,
    inner,
    documentWrapper,
    imageEmptyContainer,
    imageInner,
    watermark,
    watermarkInner,
    imageContainer,
    imageMaximized,
    emptyImageBoxContainer,
    documentControlsWrapper,
    documentControlsWrapperInner,
    activeLayersFabButton,
    firstLoader,
    loaderFile,
    layersMenu,
    layersMenuCloseButton,
    layersMenuItem,
    layersMenuItemIcon
}
