import {css} from '@emotion/css';

const imageWrapper = (height?: number, width?: number) => css`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  width: 190px;
  height: 50px;
  margin-top: -2px;
  margin-bottom: -2px;

  ${height && `
    height: ${height}px;
  `}
  ${width && `
    width: ${width}px;
  `}
`
const image = css`
  display: block;
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: auto;
  height: auto;
  max-width: 100%;
  min-height: 100%;
`
export const LogoStyles = {
    imageWrapper,
    image
}
