/* eslint-env browser */
function setCookie(key, value) {
    const expDate = new Date();

    expDate.setTime(expDate.getTime() + 365 * 24 * 60 * 60 * 1000);

    document.cookie = `${key}=${value}; expires=${expDate.toUTCString()}; path=/; domain=doxychain.com; secure; samesite=strict`;
}

function getCookie(key) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${key}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
    return '';
}

function removeCookie(key) {
    document.cookie = `${key}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/; domain=doxychain.com; secure; samesite=strict`;
}

const cookieHelper = {setCookie, getCookie, removeCookie};

export default cookieHelper;
