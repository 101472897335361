import {alpha} from "@mui/material";

export default ({palette, typography}) => ({
    styleOverrides: {
        root: {
            textTransform: typography.button.textTransform,
            fontWeight: typography.fontWeightMedium,
            lineHeight: '1.2',
            minHeight: 25,
            color: palette.text.secondary,
            fontSize: typography.body2.fontSize,
            backgroundColor: alpha(palette.grey[300], 0.5),
        },
        sizeSmall: {
            fontSize: typography.subtitle2.fontSize,
        },
        outlinedSuccess: {
            backgroundColor: alpha(palette.success.main, 0.1),
            color: palette.success.main,
            transition: 'all .3s ease',
            borderRadius: 6,
            '&:hover': {
                backgroundColor: `${palette.success.main}!important`,
                color: `${palette.success.contrastText}!important`,
            }
        },
        outlinedWarning: {
            backgroundColor: alpha(palette.warning.main, 0.1),
            color: palette.warning.main,
            transition: 'all .3s ease',
            borderRadius: 6,
            '&:hover': {
                backgroundColor: `${palette.warning.main}!important`,
                color: `${palette.warning.contrastText}!important`,
            }
        },
        colorSuccess: {
            backgroundColor: alpha(palette.success.main, 0.1),
            color: palette.success.main,
        },
        colorInfo: {
            backgroundColor: alpha(palette.info.main, 0.1),
            color: palette.info.main,
        },
        colorWarning: {
            backgroundColor: alpha(palette.warning.main, 0.1),
            color: palette.warning.main,
        },
        colorError: {
            backgroundColor: alpha(palette.error.main, 0.1),
            color: palette.error.main,
        },
        label: {
            paddingLeft: 14,
            paddingRight: 14,
        },
        labelSmall: {
            paddingLeft: 12,
            paddingRight: 12,
        },
        deleteIconSmall: {
            width: 17,
            height: 17,
        },
    },
});
