import {alpha, Dialog, DialogTitle, Fab, useMediaQuery, useTheme} from "@mui/material";
import React from "react";
import {Close as CloseIcon} from "../CustomIcon";
import {breakpointValues} from "../../theme/options/breakpoints";

type CustomDialogProps = {
    open?: boolean,
    children: React.ReactNode,
    smallTitle?: boolean,
    title?: string | React.ReactNode,
    onClose: () => void;
}

const CustomDialog = ({open = false, smallTitle, title, onClose, children}: CustomDialogProps) => {
    const isMobile = useMediaQuery(`(max-width:${breakpointValues.sm}px)`);
    const {palette} = useTheme();

    return <Dialog style={{backgroundColor: alpha(palette.common.black, 0.6)}} open={open} onClose={onClose}
                   maxWidth={'md'} fullScreen={isMobile}>
        <DialogTitle variant={smallTitle ? 'h6' : 'h4'}>
            {title && title}
            <Fab size={"small"} color={"primary"} onClick={onClose} sx={{
                position: 'absolute',
                right: isMobile ? 15 : 40,
                top: isMobile ? 32 : 38,
            }}>
                <CloseIcon/>
            </Fab>
        </DialogTitle>
        {children}
    </Dialog>
}

export default CustomDialog;
