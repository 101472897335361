import {css} from "@emotion/css";
import {mediaQueryMax} from "../../theme/options/breakpoints";

const container = (isNoData: boolean) => css`
  ${isNoData && `
    background: url("/images/no_matches_found.svg") no-repeat center right;
  `}

  background-size: clamp(10px, 80%, 600px) auto;

  ${mediaQueryMax.sm} {
    background: none;
  }
`

const pageWrapper = css`
  padding: 40px 0;
  max-width: 960px;
  margin: 0 auto;
  min-height: calc(100vh - 300px);
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${mediaQueryMax.md} {
    margin: 0 -8px;
    padding: 15px;
  }
`

const title = css`
  display: flex;
  white-space: pre;
  flex-wrap: wrap;
`


export const SearchPageStyles = {
    pageWrapper,
    title,
    container
}
