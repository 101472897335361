export enum LanguageType {
    PL = 'pl',
    EN = 'en'
}

export enum RequestStatus {
    IDLE = 'idle',
    PENDING = 'pending',
    ERROR = 'error',
    SUCCESS = 'success',
}
