import {alpha, colors, darken, lighten} from "@mui/material";

const common = {
    black: '#09121D',
    white: '#FFFFFF',
    backgroundColor: {
        light: '#FEFEFE',
        dark: '#FAFBFD',
    },
    bluePrimary: {
        lightest: '#99e0ff',
        light: '#00B1FF',
        main: '#005DFF',
        dark: '#024BC6',
    },
    grey: {
        lightest: '#F5F6FA',
        light: '#E7EAF4',
        main: '#A5B4CA',
        dark: '#77869B',
        darkest: '#576273',
    },
    green: '#16B15C',
    orange: '#EE7A0F',
    blue: '#188CE0',
    red: '#EA1C1C',
};

export default {
    type: 'light',
    common: {
        black: common.black,
        white: common.white,
        red: common.red,
        green: common.green,
        orange: common.orange,
        purple: '#ED63D2',
        coral: '#FF7D51',
    },
    primary: {
        lightest: common.bluePrimary.lightest,
        light: common.bluePrimary.light,
        main: common.bluePrimary.main,
        dark: common.bluePrimary.dark,
        contrastText: common.white,
    },
    secondary: {
        light: common.grey.lightest,
        main: common.grey.main,
        dark: common.grey.darkest,
        contrastText: common.white,
    },
    success: {
        light: lighten(common.green, 0.2),
        main: common.green,
        dark: darken(common.green, 0.2),
        contrastText: common.white,
    },
    warning: {
        light: lighten(common.orange, 0.2),
        main: common.orange,
        dark: darken(common.orange, 0.2),
        contrastText: common.white,
    },
    info: {
        light: lighten(common.blue, 0.2),
        main: common.blue,
        dark: darken(common.blue, 0.2),
        contrastText: common.white,
    },
    error: {
        light: lighten(common.red, 0.2),
        main: common.red,
        dark: darken(common.red, 0.2),
        contrastText: common.white,
    },
    text: {
        primary: common.black,
        secondary: common.grey.dark,
        disabled: common.grey.main,
        hint: alpha(common.black, 0.38),
    },
    background: {
        paper: common.backgroundColor.light,
        default: common.backgroundColor.dark,
    },
    action: {
        selected: common.grey.light,
        hover: alpha(common.grey.light, 0.35),
        disabledBackground: common.grey.light,
        disabled: common.grey.main,
        active: 'rgba(0, 0, 0, 0.54)',
        hoverOpacity: 0.08,
    },
    grey: {
        50: common.grey.lightest,
        300: common.grey.light,
        500: common.grey.main,
        600: common.grey.dark,
        800: common.grey.darkest,
    },
    icon: colors.blueGrey[600],
    link: common.bluePrimary.main,
    divider: common.grey.lightest,
    bottomLineColor: common.grey.light,
    tonalOffset: 0.15,
};
