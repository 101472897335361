import {LogoStyles} from "./styles";
import {Link} from "react-router-dom";
import {RoutesObj} from "../../Router";


enum VariantType {
    Black,
    White
}

interface LogoProps {
    variant?: VariantType,
    redirectOutside?: string,
    height?: number,
    width?: number,
}

const logoSrc = {
    [VariantType.Black]: '/images/logo.svg',
    [VariantType.White]: '/images/logo_white.svg',
}

const Logo = ({
                  variant = VariantType.Black,
                  redirectOutside,
                  height,
                  width,
              }: LogoProps) => {
    return (<div className={LogoStyles.imageWrapper(height, width)}>
        {redirectOutside ? <a href={redirectOutside} target="_blank">
                <img className={LogoStyles.image} alt="DoxyChain Logo" src={logoSrc[variant]}/>
            </a> :
            <Link to={RoutesObj.HomePage.toPath()}>
                <img className={LogoStyles.image} alt="DoxyChain Logo" src={logoSrc[variant]}/>
            </Link>}
    </div>)
}

export default Logo;
