export default ({palette}) => ({
    defaultProps: {
        elevation: 8,
        variant: 'elevation',
    },
    styleOverrides: {
        root: {
            position: 'relative',
        },
        outlined: {
            border: `1px solid ${palette.divider}`,
        },
    },
});
