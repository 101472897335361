export default {
    defaultProps: {
        disablePadding: true,
    },
    styleOverrides: {
        root: {
            '& > .MuiListItem-divider:last-child': {
                borderBottom: 'none',
            },
        },
    },
};
