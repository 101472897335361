export default {
    styleOverrides: {
        root: {
            color: 'inherit',
            minWidth: 20,
            marginRight: 12,
            '& > svg': {
                fontSize: 20,
            },
        },
    },
};
