import {breakpointValues} from "../breakpoints";

export default ({palette, typography}) => ({
    styleOverrides: {
        root: {
            color: palette.text.secondary,
            fontSize: typography.htmlFontSize,
            lineHeight: 'inherit',
            height: 'auto',
            minHeight: 48,
            [`@media (min-width: ${breakpointValues.sm}px)`]: {
                minHeight: 48,
            },
            '&:hover': {
                color: palette.primary.main,
                backgroundColor: palette.action.hover,
            },
            '&$selected, &$selected:hover': {
                color: palette.primary.main,
                backgroundColor: 'transparent',
            },
        },
    },
});
