import i18n from 'i18next';
import en from './translations/en.json';
import pl from './translations/pl.json';
import {initReactI18next} from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import {LanguageType} from "../models/global.model";

const resources = {
    en: {
        translation: en,
    },
    pl: {
        translation: pl,
    },
}


const detectionOptions = {
    lookupCookie: 'lang',
    caches: ['localStorage'],
    excludeCacheFor: ['cookie'],
    order: ['cookie', 'navigator'],
    cookieDomain: '.doxychain.com',
}

i18n
    .use(LanguageDetector)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        detection: detectionOptions,
        resources,
        fallbackLng: LanguageType.EN, // use en if detected lng is not available
        supportedLngs: [LanguageType.PL, LanguageType.EN],
        debug: process.env.NODE_ENV !== 'production',
        cleanCode: true,
        react: {
            nsMode: 'default',
            bindI18nStore: 'added removed',
            bindI18n: 'languageChanged loaded',
        },
        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;
