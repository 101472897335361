import {breakpointValues} from "../breakpoints";


export default {
    defaultProps: {
        PaperProps: {
            elevation: 12,
        },
    },
    styleOverrides: {
        container: {
            outline: 'none !important',
            '& *': {
                outline: 'inherit !important',
            },
        },
        paper: {
            width: '100%',
        },
        paperScrollPaper: {
            maxHeight: `calc(100% - 32px)`,
        },
        paperWidthFalse: {},
        paperWidthXs: {
            maxWidth: breakpointValues.xs,
            '&$paperScrollBody': {
                [`@media (max-width: ${breakpointValues.xs + 32}px)`]: {
                    maxWidth: `calc(100% - 32px)`,
                }
            },
        },
        paperWidthSm: {
            maxWidth: breakpointValues.sm,
            '&$paperScrollBody': {
                [`@media (max-width: ${breakpointValues.sm + 32}px)`]: {
                    maxWidth: `calc(100% - 16px)`,
                }
            },
        },
        paperWidthMd: {
            maxWidth: breakpointValues.md,
            '&$paperScrollBody': {
                [`@media (max-width: ${breakpointValues.md + 32}px)`]: {
                    maxWidth: `calc(100% - 16px)`,
                }
            },
        },
        paperWidthLg: {
            maxWidth: breakpointValues.lg,
            '&$paperScrollBody': {
                [`@media (max-width: ${breakpointValues.lg + 32}px)`]: {
                    maxWidth: `calc(100% - 16px)`,
                }
            },
        },
        paperWidthXl: {
            maxWidth: breakpointValues.xl,
            '&$paperScrollBody': {
                [`@media (max-width: ${breakpointValues.xl + 32}px)`]: {
                    maxWidth: `calc(100% - 16px)`,
                }
            },
        },
        paperFullWidth: {
            width: `calc(100% - 32px)`,
        },
    },
};
