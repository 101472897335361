import React from "react";
import {HomePageStyles} from "./styles";
import {Container, Grid, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import Search from "../../components/Search/Search";
import Dropzone from "../../components/Dropzone/Dropzone";

function HomePage() {
    const {t} = useTranslation()
    return <main>
        <Container>
            <div className={HomePageStyles.pageWrapper}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6} className={HomePageStyles.searchWrapper}>
                        <Typography mb="20px" variant="h1">{t("homePage.title")}</Typography>
                        <Typography whiteSpace="break-spaces" mb="14px"
                                    variant="h3">{t("homePage.subTitle")}</Typography>
                        <Typography mb="25px" variant="body1">{t("homePage.description")}</Typography>
                        <Search extraPadding={true} searchButtonPosition="end"/>
                        <Dropzone/>
                    </Grid>
                    <Grid item xs={12} md={6} className={HomePageStyles.sideImageWrapper}>
                        <img className={HomePageStyles.sideImage} alt="certificate" src="/images/cert_home_page.svg"/>
                    </Grid>
                </Grid>
            </div>
        </Container>
    </main>;
}

export default HomePage;
