import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RequestStatus} from "../../models/global.model";
import {documentServices} from "../services/document.services";
import {
    attachedFile,
    DocumentDataModel,
    DocumentHistoryModel,
    DownloadFileType,
    DownloadTokenType
} from "../../models/document.model";

export interface SearchState {
    fetchDocumentHistoryStatus: RequestStatus;
    fetchDocumentDataStatus: RequestStatus;
    fetchDocumentDataAutocompleteStatus: RequestStatus;
    fetchDownloadFileStatus: RequestStatus
    fetchDownloadTokensStatus: RequestStatus
    documentData?: DocumentDataModel[];
    documentDataAutocomplete?: DocumentDataModel[];
    documentHistory?: DocumentHistoryModel;
    documentHistoryDetailsIndex?: number;
    downloadFiles: DownloadFileType[]
    downloadTokens: DownloadTokenType[]
    attachedFiles: attachedFile[],
    allFilesLoadedStatus: boolean,
    activeFileIndex: number
    searchFileName?: string
}

const initialState: SearchState = {
    fetchDocumentHistoryStatus: RequestStatus.IDLE,
    fetchDocumentDataStatus: RequestStatus.IDLE,
    fetchDocumentDataAutocompleteStatus: RequestStatus.IDLE,
    documentData: undefined,
    documentDataAutocomplete: undefined,
    documentHistory: undefined,
    documentHistoryDetailsIndex: undefined,
    fetchDownloadFileStatus: RequestStatus.IDLE,
    fetchDownloadTokensStatus: RequestStatus.IDLE,
    attachedFiles: [],
    downloadFiles: [],
    downloadTokens: [],
    allFilesLoadedStatus: false,
    activeFileIndex: -1,
    searchFileName: undefined
};

export const documentSlice = createSlice({
    name: 'document',
    initialState,
    reducers: {
        setDocumentHistoryDetailsIndex: (state, action: PayloadAction<number | undefined>) => {
            state.documentHistoryDetailsIndex = action.payload;
        },
        setActiveFileIndex: (state, action: PayloadAction<number>) => {
            state.activeFileIndex = action.payload;
        },
        setSearchFileName: (state, action: PayloadAction<string | undefined>) => {
            state.searchFileName = action.payload;
        },
        resetSearch: (state) => {
            state.documentData = undefined;
            state.fetchDocumentDataStatus = RequestStatus.IDLE;
        },
        resetSearchAutoComplete: (state) => {
            state.documentDataAutocomplete = undefined;
            state.fetchDocumentDataAutocompleteStatus = RequestStatus.IDLE;
        },
        resetDocumentPreview: (state) => {
            state.downloadFiles = initialState.downloadFiles;
            state.downloadTokens = initialState.downloadTokens;
            state.allFilesLoadedStatus = initialState.allFilesLoadedStatus;
            state.activeFileIndex = initialState.activeFileIndex;
        },
        clearFilesAndTokens: (state, action: PayloadAction<number>) => {
            state.fetchDownloadTokensStatus = initialState.fetchDownloadTokensStatus;
            state.fetchDownloadFileStatus = initialState.fetchDownloadFileStatus;
            state.downloadFiles = initialState.downloadFiles;
            state.downloadTokens = initialState.downloadTokens;
            state.activeFileIndex = action.payload >= 0 ? action.payload : -1;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(documentServices.fetchDocumentData.pending, (state) => {
            state.fetchDocumentDataStatus = RequestStatus.PENDING;
        }).addCase(documentServices.fetchDocumentData.fulfilled, (state, action) => {
            if (action.payload.length === 1) {
                state.attachedFiles = action.payload[0].attachedFiles;
            }
            state.fetchDocumentDataStatus = RequestStatus.SUCCESS;
            state.documentData = action.payload
        }).addCase(documentServices.fetchDocumentData.rejected, (state) => {
            state.fetchDocumentDataStatus = RequestStatus.ERROR;
        }).addCase(documentServices.fetchDocumentDataAutocomplete.pending, (state) => {
            state.fetchDocumentDataAutocompleteStatus = RequestStatus.PENDING;
        }).addCase(documentServices.fetchDocumentDataAutocomplete.fulfilled, (state, action) => {
            state.fetchDocumentDataAutocompleteStatus = RequestStatus.SUCCESS;
            state.documentDataAutocomplete = action.payload
        }).addCase(documentServices.fetchDocumentDataAutocomplete.rejected, (state) => {
            state.fetchDocumentDataAutocompleteStatus = RequestStatus.ERROR;
            state.documentDataAutocomplete = undefined;
        }).addCase(documentServices.fetchDocumentHistory.pending, (state) => {
            state.fetchDocumentHistoryStatus = RequestStatus.PENDING;
        }).addCase(documentServices.fetchDocumentHistory.fulfilled, (state, action) => {
            state.fetchDocumentHistoryStatus = RequestStatus.IDLE;
            state.documentHistory = action.payload
        }).addCase(documentServices.fetchDocumentHistory.rejected, (state) => {
            state.fetchDocumentHistoryStatus = RequestStatus.ERROR;
        }).addCase(documentServices.getDownloadTokens.pending, (state) => {
            state.fetchDownloadTokensStatus = RequestStatus.PENDING;
        }).addCase(documentServices.getDownloadTokens.fulfilled, (state, action) => {
            const tokens = action.payload.filter(token => state.activeFileIndex === token.indexFile)
            state.fetchDownloadTokensStatus = RequestStatus.SUCCESS;
            state.downloadTokens = [
                ...state.downloadTokens,
                ...tokens
            ]
        }).addCase(documentServices.getDownloadTokens.rejected, (state) => {
            state.fetchDownloadTokensStatus = RequestStatus.ERROR;
        }).addCase(documentServices.downloadFile.pending, (state) => {
            state.fetchDownloadFileStatus = RequestStatus.PENDING;
        }).addCase(documentServices.downloadFile.fulfilled, (state, action) => {
            const fileObj = {
                file: action.payload.file,
                indexFile: action.payload.indexFile,
                page: action.payload.page
            }
            state.fetchDownloadFileStatus = RequestStatus.SUCCESS;
            state.downloadFiles = state.activeFileIndex !== action.payload.indexFile ? [] : [
                ...state.downloadFiles,
                fileObj
            ];

        }).addCase(documentServices.downloadFile.rejected, (state) => {
            state.fetchDownloadFileStatus = RequestStatus.ERROR;
        });
    },
});

export const {
    setDocumentHistoryDetailsIndex,
    resetSearch,
    resetSearchAutoComplete,
    setActiveFileIndex,
    resetDocumentPreview,
    clearFilesAndTokens,
    setSearchFileName
} = documentSlice.actions

export default documentSlice.reducer;
