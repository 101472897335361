import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import CustomDialog from "../CustomDialog/CustomDialog";
import {setDocumentHistoryDetailsIndex} from "../../redux/reducers/document.reducer";
import PaperDataGrid from "../PaperDataGrid/PaperDataGrid";
import React from "react";
import {selectHistoryDetailsActionName, selectHistoryDetailsData} from "../../redux/selectors/document.selector";
import {useTranslation} from "react-i18next";

const DocumentHistoryDetailsDialog = () => {
    const dispatch = useAppDispatch()
    const {t} = useTranslation()
    const title = useAppSelector(state => selectHistoryDetailsActionName(state, t))
    const documentHistoryDetails = useAppSelector(state => selectHistoryDetailsData(state, t))

    const onClose = () => {
        dispatch(setDocumentHistoryDetailsIndex(undefined))
    }
    if (!documentHistoryDetails) return null

    return <CustomDialog open={true} title={title} onClose={onClose}>
        <PaperDataGrid withShadow={false} items={documentHistoryDetails}/>
    </CustomDialog>
}

export default DocumentHistoryDetailsDialog;
