import palette from '../palette';
import {lighten} from "@mui/material";

export default {
    styleOverrides: {
        root: {
            fontSize: '1.2rem',
            width: 40,
            height: 40,
        },
        colorDefault: {
            color: palette.grey["800"],
            backgroundColor: lighten(palette.grey["800"], 0.825),
        },
    },
};
