export default {
    defaultProps: {
        elevation: 12,
    },
    styleOverrides: {
        paperAnchorDockedLeft: {
            borderRight: 'none',
        },
        paperAnchorDockedTop: {
            borderBottom: 'none',
        },
        paperAnchorDockedRight: {
            borderLeft: 'none',
        },
        paperAnchorDockedBottom: {
            borderTop: 'none',
        },
    },
};
