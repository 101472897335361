import React, {useEffect} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import {documentServices} from "../../redux/services/document.services";
import PaperDataGrid from "../../components/PaperDataGrid/PaperDataGrid";
import {Button, Container, Grid, Typography} from "@mui/material";
import {DocumentPageStyles} from "./styles";
import {useTranslation} from "react-i18next";
import DocumentHistoryTable from "../../components/DocumentHistoryTable/DocumentHistoryTable";
import DocumentImage from "../../components/DocumentImage/DocumentImage";
import DocumentHistoryDetailsDialog from "../../components/DocumentHistoryDetailsDialog/DocumentHistoryDetailsDialog";
import {
    selectDocumentDataSection,
    selectIsCertificate,
    selectIsPublicDocument,
    selectIsUserParticipant,
    selectParticipantsSection
} from "../../redux/selectors/document.selector";
import Loader from "../../components/Loader/Loader";
import {RequestStatus} from "../../models/global.model";
import {RoutesObj} from "../../Router";
import IconTooltip from "../../components/IconTooltip/IconTooltip";
import {css} from "@emotion/css";
import {OpenInNew} from "@mui/icons-material";

const DocumentVisibilityInfo = () => {
    const {t} = useTranslation();
    const isParticipant = useAppSelector(selectIsUserParticipant);
    const isPublic = useAppSelector(selectIsPublicDocument);


    if (!isPublic && !isParticipant) return null

    let tooltip;

    if (isPublic) {
        tooltip = t('documentPage.documentVisibilityInfoTooltipPublic')
    } else if (isParticipant) {
        tooltip = t('documentPage.documentVisibilityInfoTooltipRecipient')
    }

    return <div className={css`
      margin-top: 26px;
      padding-left: 40px;
      margin-bottom: -16px;
      display: flex;
      align-items: center;
    `}>
        <Typography mr={1}>
            {t('documentPage.documentVisibilityInfo')}
        </Typography>
        <IconTooltip
            message={tooltip}/>
    </div>
}


function DocumentPage() {
    const {documentId} = useParams();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const {t} = useTranslation()
    const isCertificate = useAppSelector(selectIsCertificate)
    const {fetchUserDataStatus} = useAppSelector(state => state.identityReducer)
    const documentDataSection = useAppSelector(state => selectDocumentDataSection(state, t))
    const isRecipient = useAppSelector(selectIsUserParticipant)
    const {fetchDocumentDataStatus, documentData} = useAppSelector(state => state.documentReducer)
    const participantsSection = useAppSelector(state => selectParticipantsSection(state, t))
    const isLoadingDocData = fetchDocumentDataStatus === RequestStatus.PENDING;
    const isLoadingIdentity = fetchUserDataStatus === RequestStatus.PENDING;

    const onOpenInPlatform = () => {
        window.open(`${process.env.REACT_APP_APP_FRONT_URL}${isCertificate ? 'certificate' : 'document'}/${documentId}`, "_blank")?.focus()
    }
    useEffect(() => {
        if (documentData && documentData.length > 1 && documentId) { // if more than 1 result then show results on search page
            navigate(RoutesObj.SearchPage.toPath(documentId))
        }
    }, [documentData])

    useEffect(() => {
        if (documentId && fetchDocumentDataStatus === RequestStatus.IDLE && [RequestStatus.SUCCESS, RequestStatus.ERROR].includes(fetchUserDataStatus)) { // if no data then fetch
            dispatch(documentServices.fetchDocumentData(documentId))
        }
    }, [documentId, fetchDocumentDataStatus, fetchUserDataStatus])


    return <Container>
        <div className={DocumentPageStyles.pageWrapper}>
            {documentData && documentData.length > 1 || isLoadingDocData || isLoadingIdentity
                ? <div className={DocumentPageStyles.loaderWrapper}>
                    <Loader/>
                </div> : <>
                    <DocumentImage/>
                    {documentDataSection && <>
                        <div className={DocumentPageStyles.titleTextWrapper}>
                            <Typography variant="h3">
                                {t('documentPage.documentDataTitle')}
                            </Typography>
                            {isRecipient &&
                                <Button
                                    onClick={onOpenInPlatform}
                                    variant="text"
                                    endIcon={<OpenInNew/>}>
                                    {t("documentPage.openOnPlatform")}
                                </Button>}
                        </div>
                        <PaperDataGrid items={documentDataSection}/>
                    </>}

                    <DocumentVisibilityInfo/>

                    {participantsSection && participantsSection.length > 0 && <>
                        <Typography variant="h3" mb="16px" mt="64px">
                            {t('documentPage.documentParticipantsTitle')}
                        </Typography>
                        <Grid container spacing={2}>
                            {participantsSection.map((participant, index) => (
                                <Grid key={`participant_grid_${index}`} item xs={12} sm={6}>
                                    <PaperDataGrid smallPadding={true} items={participant}/>
                                </Grid>
                            ))}
                        </Grid>
                    </>}
                    <DocumentHistoryTable/>
                </>}

        </div>

        <DocumentHistoryDetailsDialog/>
    </Container>;
}

export default DocumentPage;
