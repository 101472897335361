export default ({palette}) => ({
    defaultProps: {
        elevation: 0,
        color: 'default',
    },
    styleOverrides: {
        colorDefault: {
            color: palette.text.primary,
            backgroundColor: palette.background.paper,
        },
    },
})
