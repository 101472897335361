import {createSlice} from '@reduxjs/toolkit';
import {fetchTheme} from "../actions/ui.action";
import {PaletteOptions} from "@mui/material/styles/createPalette";
import palette from "../../theme/options/palette";

export interface UiState {
    palette: PaletteOptions;
}

const initialState: UiState = {
    palette: palette,
};

export const uiSlice = createSlice({
    name: 'ui',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchTheme.fulfilled, (state, action) => {
                state.palette = action.payload;
            });
    },
});

export default uiSlice.reducer;
