import {createSlice} from '@reduxjs/toolkit';
import {RequestStatus} from "../../models/global.model";
import {fileServices} from "../services/file.services";

export interface FilesState {
    imagePreviewStatus: RequestStatus;
    imagePreviewError?: string;
    imagePreview?: string;
    tokenPreviewStatus: RequestStatus;
    tokenPreviewError?: string;
    tokenPreview?: string;
}

const initialState: FilesState = {
    imagePreviewStatus: RequestStatus.IDLE,
    imagePreviewError: undefined,
    imagePreview: undefined,
    tokenPreviewStatus: RequestStatus.IDLE,
    tokenPreviewError: undefined,
    tokenPreview: undefined,
};

export const fileSlice = createSlice({
    name: 'file',
    initialState,
    reducers: {
        resetFiles: (state) => {
            Object.assign(state, initialState)
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fileServices.getDownloadToken.pending, (state) => {
            state.tokenPreviewStatus = RequestStatus.PENDING;
            state.tokenPreview = undefined;
        }).addCase(fileServices.getDownloadToken.fulfilled, (state, action) => {
            state.tokenPreviewStatus = RequestStatus.IDLE;
            state.tokenPreview = action.payload.data.token
        }).addCase(fileServices.getDownloadToken.rejected, (state) => {
            state.tokenPreviewStatus = RequestStatus.ERROR;
            state.tokenPreview = undefined;
        }).addCase(fileServices.fetchDocumentImagePreview.pending, (state) => {
            state.imagePreviewStatus = RequestStatus.PENDING;
            state.imagePreview = undefined;
        }).addCase(fileServices.fetchDocumentImagePreview.fulfilled, (state, action) => {
            state.imagePreviewStatus = RequestStatus.IDLE;
            state.imagePreview = action.payload
        }).addCase(fileServices.fetchDocumentImagePreview.rejected, (state) => {
            state.imagePreview = undefined;
            state.imagePreviewStatus = RequestStatus.ERROR;
        });
    },
});

export const {resetFiles} = fileSlice.actions;
export default fileSlice.reducer;
