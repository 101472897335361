import {DocumentImageStyles} from "../styles";
import {Fab, Paper, Typography, useMediaQuery, useTheme} from "@mui/material";
import {useAppDispatch, useAppSelector} from "../../../redux/hooks";
import {RequestStatus} from "../../../models/global.model";
import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import {fileServices} from "../../../redux/services/file.services";
import {selectDocumentUUID, selectIsCertificate} from "../../../redux/selectors/document.selector";
import Loader from "../../Loader/Loader";
import DocumentPreview from "../../DocumentPreview/DocumentPreview";
import {Fullscreen} from "../../CustomIcon";


const PreviewLoggedIn = () => {
    const dispatch = useAppDispatch();
    const {t} = useTranslation()
    const theme = useTheme();
    const {
        imagePreviewStatus,
        imagePreview,
        tokenPreview,
        tokenPreviewStatus,
    } = useAppSelector(state => state.fileReducer)
    const [showPreview, setShowPreview] = useState(false)
    const smallDevice = useMediaQuery(theme.breakpoints.down('sm'));
    const documentUUID = useAppSelector(selectDocumentUUID)
    const isCertificate = useAppSelector(selectIsCertificate)
    const hasError = imagePreviewStatus === RequestStatus.ERROR || tokenPreviewStatus === RequestStatus.ERROR;
    const isLoading = imagePreviewStatus === RequestStatus.PENDING || tokenPreviewStatus === RequestStatus.PENDING || !imagePreview && !hasError;


    useEffect(() => {
        if (documentUUID) {
            dispatch(fileServices.getDownloadToken({
                documentId: documentUUID,
                index: 0,
                page: 0,
                startPage: 0,
                service: isCertificate ? 'document-dsl-service' : 'document-service'
            }))
        }
    }, [documentUUID])

    useEffect(() => {
        if (tokenPreview) {
            dispatch(fileServices.fetchDocumentImagePreview(tokenPreview))
        }
    }, [tokenPreview])

    return <div>
        {isLoading ? <Loader/> : <>
            {hasError ? <div className={DocumentImageStyles.textWrapper}>
                    <Typography color={theme.palette.text.secondary}
                                variant="body1"> <b>{t('documentPage.imageUnavailableTitle')}</b> </Typography>
                </div>
                : <Paper>
                    {smallDevice && <Fab
                        className={DocumentImageStyles.fullScreenIcon}
                        color="secondary"
                        size="large"
                        onClick={() => setShowPreview(true)}
                    >
                        <Fullscreen/>
                    </Fab>}
                    <img className={DocumentImageStyles.image}
                         src={imagePreview}
                         onClick={() => setShowPreview(true)}
                         alt="document image"
                    />
                </Paper>}
        </>}
        {!isLoading && !hasError && showPreview &&
            <DocumentPreview isOpen={showPreview}
                             onClose={() => setShowPreview(false)}/>
        }
    </div>
}

export default PreviewLoggedIn;
