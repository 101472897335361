import {breakpointValues} from '../breakpoints';
import custom from '../custom';

export default ({palette, typography}) => ({
    defaultProps: {
        enterDelay: 100,
        enterTouchDelay: 600,
        disableTouchListener: false,
        leaveTouchDelay: 1000,
        arrow: true,
    },
    styleOverrides: {
        tooltip: {
            backgroundColor: custom.tooltipBackground,
            color: palette.common.white,
            borderRadius: 4,
            padding: '6px 16px',
            fontSize: 12,
            fontWeight: typography.fontWeightRegular,
            lineHeight: 1,
            maxWidth: 300,
            textAlign: 'center',
            userSelect: 'none',
        },
        arrow: {
            fontSize: 8,
            color: custom.tooltipBackground,
        },
        tooltipPlacementLeft: {
            margin: '0 12px',
            [`@media (min-width: ${breakpointValues.sm}px)`]: {
                margin: '0 6px',
            },
        },
        tooltipPlacementRight: {
            margin: '0 12px',
            [`@media (min-width: ${breakpointValues.sm}px)`]: {
                margin: '0 6px',
            },
        },
        tooltipPlacementTop: {
            margin: '12px 0',
            [`@media (min-width: ${breakpointValues.sm}px)`]: {
                margin: '6px 0',
            },
        },
        tooltipPlacementBottom: {
            margin: '12px 0',
            [`@media (min-width: ${breakpointValues.sm}px)`]: {
                margin: '6px 0',
            },
        },
    },
});
