import React from "react";
import {CircularProgress, Typography} from "@mui/material";
import {LoaderStyles} from "./styles";
import {useTranslation} from "react-i18next";


const Loader = () => {
    const {t} = useTranslation()
    return <div className={LoaderStyles.container}>
        <CircularProgress className={LoaderStyles.circular}/>
        <div className={LoaderStyles.textWrapper}>
            <Typography>
                {t("common.loading")}
            </Typography>
        </div>

    </div>
}


export default Loader
