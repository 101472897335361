import {css} from "@emotion/css";
import {breakpointValues, mediaQueryMin} from "../../theme/options/breakpoints";


const wrapper = css`
  color: white;
  font-weight: 500;
  display: flex;
  height: 50px;
  align-items: center;
  font-size: 16px;
  padding-left: 16px;

  ${mediaQueryMin.lg} {
    padding-left: calc(calc(calc(100% - ${breakpointValues.lg}px) / 2) + 20px);
  }

`

const icon = css`
  margin-right: 20px;
  align-items: center;
  display: flex;
`

export const SystemAlertStyles = {
    wrapper,
    icon
}
