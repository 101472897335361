import './theme/fonts'
import './translations/i18n'
import React from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter} from "react-router-dom";
import {Provider} from 'react-redux';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {store} from "./redux/store";
import CustomPaletteThemeProvider from "./theme/CustomPaletteThemeProvider";
import {SnackbarProvider} from "notistack";

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <React.StrictMode>
        <Provider store={store}>
            <CustomPaletteThemeProvider>
                <BrowserRouter>
                    <SnackbarProvider
                        dense={false}
                        autoHideDuration={4000}
                        maxSnack={4}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                    >
                        <App/>
                    </SnackbarProvider>
                </BrowserRouter>
            </CustomPaletteThemeProvider>
        </Provider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
