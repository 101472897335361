export default {
    styleOverrides: {
        root: {
            bottom: 0,
            right: 0,
            top: 0,
            left: 0,
            margin: 0,
            padding: 0,

        },
        legend: {
            display: 'none',
        },
        legendLabelled: {
            display: 'none',
        },
        legendNotched: {
            maxWidth: 1000,
        },
    },
};
