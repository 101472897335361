import {Order} from "../../utils/table";
import React from "react";
import {DocumentStatusType} from "../../models/document.model";
import {TFunction} from "i18next";


export interface Data {
    identifier: string;
    status: DocumentStatusType;
    date: string;
    address: string;
    type: string;
}

export function createData({
                               identifier,
                               status,
                               date,
                               address,
                               type,
                           }: Data
): Data {
    return {
        identifier,
        status,
        date,
        address,
        type,
    };
}

export const headCells = (t: TFunction): readonly HeadCell[] => [
    {
        id: 'identifier',
        disablePadding: true,
        label: t('searchPage.identifier'),
        tooltip: t('searchPage.identifierTooltip'),
    },
    {
        id: 'status',
        disablePadding: false,
        label: t('searchPage.status'),
        tooltip: t('searchPage.statusTooltip'),
    },
    {
        id: 'date',
        disablePadding: false,
        label: t('searchPage.documentCreated'),
        tooltip: t('searchPage.documentCreatedTooltip'),
    },

];


export interface HeadCell {
    disablePadding: boolean;
    id: keyof Data;
    label: string;
    tooltip: string;
}

export interface EnhancedTableProps {
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
    order: Order;
    orderBy: string;
    rowCount: number;
}
