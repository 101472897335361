export default ({palette, typography}) => ({
    styleOverrides: {
        root: {
            padding: '12px 8px',
            borderBottom: `1px solid ${palette.grey[50]}`,
            '&:first-of-type': {
                paddingLeft: 0,
            },
            '&:last-of-type': {
                paddingRight: 0,
            },
        },
        head: {
            paddingTop: 12,
            paddingBottom: 25,
            fontSize: 12,
            fontWeight: typography.fontWeightRegular,
            textTransform: 'none',
            color: palette.text.secondary,
            lineHeight: 1,
        },
        stickyHeader: {
            backgroundColor: palette.common.white,
        },
        body: {
            fontSize: typography.htmlFontSize,
            color: 'inherit',
        },
        footer: {
            paddingTop: 4,
            paddingBottom: 8,
            fontSize: 12,
            fontWeight: typography.fontWeightRegular,
        },
        sizeSmall: {
            padding: 4,
            '&:first-of-type': {
                paddingLeft: 0,
            },
            '&:last-of-type': {
                paddingRight: 0,
            },
        },
    },
});
