import {lighten} from "@mui/material";

export default ({palette}) => ({
    defaultProps: {
        color: 'primary',
    },
    styleOverrides: {
        root: {
            padding: 0,
            width: 28,
            height: 20,
            marginRight: 8,
            overflow: 'unset',
        },
        switchBase: {
            width: 40,
            height: 40,
            padding: 8,
            left: -10,
            top: -10,
            color: palette.action.hover,
            '&$checked': {
                transform: 'translateX(8px)',
            },
            '&$disabled': {
                color: palette.secondary.light,
            },
            '&$checked + $track': {
                opacity: 1,
            },
            '&$disabled + $track, &$disabled $thumb': {
                opacity: 1,
            },
        },
        colorPrimary: {
            '&$checked': {
                color: palette.primary.main,
            },
            '&$disabled': {
                color: palette.secondary.light,
            },
            '&$disabled$checked': {
                color: palette.primary.main,
            },
            '&$checked + $track': {
                backgroundColor: palette.primary.main,
            },
            '&$disabled + $track': {
                backgroundColor: lighten(palette.secondary.main, 0.65),
            },
            '&$disabled$checked + $track': {
                backgroundColor: lighten(palette.secondary.main, 0.65),
            },
        },
        colorSecondary: {
            '& + $track': {
                backgroundColor: palette.error.main,
            },
            '&$checked': {
                color: palette.error.main,
            },
            '&$disabled': {
                color: palette.secondary.main,
            },
            '&$disabled$checked': {
                color: palette.error.main,
            },
            '&$checked + $track': {
                backgroundColor: palette.error.main,
            },
            '&$disabled + $track': {
                backgroundColor: lighten(palette.secondary.main, 0.65),
            },
            '&$disabled$checked + $track': {
                backgroundColor: lighten(palette.secondary.main, 0.65),
            },
        },
        track: {
            width: '100%',
            height: 20,
            position: 'absolute',
            left: 0,
            top: '50%',
            marginTop: -10,
            borderRadius: 10,
            backgroundColor: palette.secondary.main,
            opacity: 1,
        },
        thumb: {
            width: 16,
            height: 16,
            backgroundColor: palette.common.white,
            boxShadow: 'none',
        },
        sizeSmall: {
            padding: 0,
            width: 24,
            height: 16,
            marginRight: 4,
            '& $track': {
                height: 16,
                marginTop: -8,
                borderRadius: 8,
            },
            '& $thumb': {
                width: 12,
                height: 12,
            },
            '& $switchBase': {
                width: 32,
                height: 32,
                top: -8,
                left: -8,
                padding: 4,
                '&$checked': {
                    transform: 'translateX(8px)',
                },
            },
            '& + .MuiFormControlLabel-label': {
                fontSize: 12,
            },
        },
    }

});
