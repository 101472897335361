export default {
    styleOverrides: {
        root: {
            padding: `0 40px 40px`,
            overflowX: 'hidden',
            '&:first-child': {
                paddingTop: 16,
            },
        },
    },
};
