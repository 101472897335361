import {breakpointValues} from '../breakpoints';

export default {
    styleOverrides: {
        root: {
            display: 'flex',
            alignItems: 'center',
            [`@media (min-width: ${breakpointValues.xs}px)`]: {
                flexDirection: 'row',
                alignItems: 'center',
            },

        },
        toLabelDelimiter: {
            margin: `8px 0`,
            [`@media (min-width: ${breakpointValues.sm}px)`]: {
                margin: `0 8px`,
            },
        },
    },
};
