export default ({palette, typography}) => ({
    defaultProps: {
        divider: true,
    },
    styleOverrides: {
        root: {
            color: palette.text.secondary,
            paddingTop: 8,
            paddingBottom: 8,
            fontSize: typography.htmlFontSize,
            lineHeight: 'inherit',
            '&:hover': {
                color: palette.text.primary,
            },
            '&$focusVisible': {
                color: palette.text.primary,
            },
            '&$selected': {
                fontWeight: typography.fontWeightMedium,
                color: palette.primary.main,
                backgroundColor: palette.background.paper,
                '&:hover': {
                    backgroundColor: palette.action.hover,
                },
            },
        },
        divider: {
            borderBottom: `1px solid ${palette.divider}`,
        },
        gutters: {
            paddingLeft: 12,
            paddingRight: 12,
        },
        dense: {
            minHeight: 36,
            paddingTop: 4,
            paddingBottom: 4,
        },
        button: {},
    },
});
