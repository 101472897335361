import {Tooltip, useTheme} from "@mui/material";
import React from "react";
import {Info as InfoIcon} from '../CustomIcon'
import {css} from "@emotion/css";

type IconTooltipProps = {
    message?: string
}

const IconTooltip = ({message}: IconTooltipProps) => {
    const theme = useTheme()

    return message ? (<Tooltip
        className={css`
          margin-right: 10px;
          font-size: 18px;
          color: ${theme.palette.primary.main} !important;
        `}
        title={message}
        placement="top-start">
        <InfoIcon/>
    </Tooltip>) : null
}


export default IconTooltip;
