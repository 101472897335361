import React, {useEffect, useState} from 'react';
import {useAppDispatch, useAppSelector} from "../../../redux/hooks";
import {DocumentPreviewStyles} from "../DocumentPreviewStyles";
import {
    Fab,
    ListItemSecondaryAction,
    ListSubheader,
    Menu,
    MenuItem,
    Tooltip,
    useMediaQuery,
    useTheme
} from "@mui/material";
import {
    ArrowDown as ArrowDownIcon,
    ArrowTop as ArrowTopIcon,
    Fullscreen as FullScreenIcon,
    FullscreenExit as FullScreenExitIcon,
    Layers as LayersIcon,
} from '../../../components/CustomIcon';
import {useTranslation} from "react-i18next";
import clsx from "clsx";
import {clearFilesAndTokens} from "../../../redux/reducers/document.reducer";
import {RequestStatus} from "../../../models/global.model";
import {Close as CloseIcon, File as FileIcon} from "../../CustomIcon";

type DocumentPreviewControlsProps = {
    setDisabledMenu: (isDisabled: boolean) => void
    setDocumentMaximized: (maxmized: boolean) => void
    disabledMenu: boolean
    documentMaximized: boolean
}

const DocumentPreviewControls = ({
                                     setDisabledMenu,
                                     documentMaximized,
                                     disabledMenu,
                                     setDocumentMaximized
                                 }: DocumentPreviewControlsProps) => {
    const {t} = useTranslation();
    const dispatch = useAppDispatch();
    const theme = useTheme();
    const smallDevice = useMediaQuery(theme.breakpoints.down('sm'));
    const largeContent = useMediaQuery(
        theme.breakpoints.up('lg')
    );
    const [activeFileIndexDocument, setActiveFileIndexDocument] = useState(0);
    const [anchorLayersEl, setAnchorLayersEl] = useState<null | HTMLElement>(null);
    const {
        attachedFiles,
        downloadFiles,
        allFilesLoadedStatus,
        fetchDocumentDataStatus,
        fetchDownloadFileStatus,
        fetchDownloadTokensStatus,
        activeFileIndex
    } = useAppSelector((state) => state.documentReducer, undefined);

    const layersButtonSize = smallDevice ? 'medium' : 'large';

    const tooltipPlacement = 'right';
    const availableFiles = attachedFiles;
    const filesEmpty = availableFiles && !availableFiles.length
    const filesIsNotEmpty = availableFiles && availableFiles.length > 0
    const controlArrowsDisabled = availableFiles && availableFiles.length <= 1;

    const handleOpenLayers = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorLayersEl(event.currentTarget);
    };
    const handleCloseLayers = () => {
        setAnchorLayersEl(null);
    };


    const scrollToDocument = (index: number) => {
        const scrollContent = document.getElementById('scroll-element-file');

        if (scrollContent) {
            const box = document.querySelectorAll(`.document-number-${index}`) as NodeListOf<HTMLElement> | null;
            if (box !== null) {
                scrollContent.scrollTop = box[0].offsetTop;
                setActiveFileIndexDocument(index);
            }
        }
    };


    const changeFileIndex = (index: number | 'prev' | 'next') => {
        if (index === 'prev' && activeFileIndexDocument > 0) {
            index = activeFileIndexDocument - 1;
        } else if (index === 'prev') {
            index = attachedFiles.length - 1;
        }

        if (index === 'next' && activeFileIndexDocument < attachedFiles.length - 1) {
            index = activeFileIndexDocument + 1;
        } else if (index === 'next') {
            index = 0;
        }

        if (allFilesLoadedStatus) {
            scrollToDocument(index);
            return;
        }

        dispatch(clearFilesAndTokens(index));
    };


    const checkActiveFileOnScroll = () => {
        const mainNavLinks = attachedFiles?.map((file) => `document-number-${file.index}`);
        const scrollContent = document.getElementById('scroll-element-file') as HTMLElement;
        if (scrollContent !== null) {
            const fromTop = scrollContent.scrollTop;

            mainNavLinks?.forEach((link) => {
                const section = document.querySelectorAll(`.${link}`) as NodeListOf<HTMLElement> | null;
                if (section && section.length) {
                    const sectionFirst = section[0];
                    const sectionLast = section[section.length - 1];

                    if (
                        sectionFirst.offsetTop <= fromTop &&
                        sectionLast.offsetTop + sectionLast.offsetHeight >= fromTop
                    ) {
                        setActiveFileIndexDocument(Number(link.split('-')[2]));
                    } else if (fromTop === 0) {
                        setActiveFileIndexDocument(Number(downloadFiles.length ? downloadFiles[0]?.indexFile : 0));
                    }
                }
            });
        }

    };


    useEffect(() => {
        const scrollContent = document.getElementById('scroll-element-file');
        if (scrollContent !== null && fetchDocumentDataStatus === RequestStatus.SUCCESS) {
            scrollContent.addEventListener('scroll', checkActiveFileOnScroll, false);
        }
        return () => {
            if (scrollContent !== null)
                scrollContent.removeEventListener('scroll', checkActiveFileOnScroll, false);
        };
    }, [fetchDocumentDataStatus]);

    useEffect(() => {
        if (fetchDownloadFileStatus === RequestStatus.SUCCESS) {
            checkActiveFileOnScroll();
            setDisabledMenu(false);
        }
    }, [fetchDownloadFileStatus]);


    if (filesEmpty) return null;

    const layersOpen = Boolean(anchorLayersEl);


    return <>
        <div className={DocumentPreviewStyles.documentControlsWrapper}>
            <div className={DocumentPreviewStyles.documentControlsWrapperInner}>
                {controlArrowsDisabled ? (
                    <Fab color="secondary" size={layersButtonSize} disabled>
                        <ArrowTopIcon/>
                    </Fab>
                ) : (
                    <Tooltip title={t('previewControls.previous')} placement={tooltipPlacement}>
                        <Fab
                            color="secondary"
                            size={layersButtonSize}
                            onClick={() => changeFileIndex('prev')}
                        >
                            <ArrowTopIcon/>
                        </Fab>
                    </Tooltip>
                )}

                <Tooltip title={t('previewControls.fileList')} placement={tooltipPlacement}>
                    <Fab
                        color="secondary"
                        size={layersButtonSize}
                        onClick={handleOpenLayers}
                        classes={{root: clsx(layersOpen && DocumentPreviewStyles.activeLayersFabButton)}}
                    >
                        <LayersIcon/>
                    </Fab>
                </Tooltip>


                {largeContent && (
                    <Tooltip
                        title={documentMaximized ? t('previewControls.zoomOut') : t('previewControls.zoomIn')}
                        placement={tooltipPlacement}
                    >
                        <Fab
                            color="secondary"
                            size={layersButtonSize}
                            onClick={() => setDocumentMaximized(!documentMaximized)}
                        >
                            {documentMaximized ? <FullScreenExitIcon/> : <FullScreenIcon/>}
                        </Fab>
                    </Tooltip>
                )}
                {controlArrowsDisabled ? (
                    <Fab color="secondary" size={layersButtonSize} disabled>
                        <ArrowDownIcon/>
                    </Fab>
                ) : (
                    <Tooltip title={t('previewControls.next')} placement={tooltipPlacement}>
                        <Fab
                            color="secondary"
                            size={layersButtonSize}
                            onClick={() => changeFileIndex('next')}
                        >
                            <ArrowDownIcon/>
                        </Fab>
                    </Tooltip>
                )}
            </div>
        </div>
        <Menu
            classes={{
                paper: DocumentPreviewStyles.layersMenu,
            }}
            open={layersOpen}
            anchorEl={anchorLayersEl}
            onClose={handleCloseLayers}

            anchorOrigin={{
                vertical: 'center',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'center',
                horizontal: 'left',
            }}
        >
            <ListSubheader>
                <>
                    {t('previewControls.fileList')}

                    <ListItemSecondaryAction>
                        <Fab
                            size="small"
                            color="secondary"
                            onClick={handleCloseLayers}
                            classes={{root: DocumentPreviewStyles.layersMenuCloseButton}}
                        >
                            <CloseIcon/>
                        </Fab>
                    </ListItemSecondaryAction>
                </>
            </ListSubheader>
            {filesIsNotEmpty ? (
                attachedFiles.map((item, index) => (
                    <MenuItem
                        key={index}
                        selected={item.index === activeFileIndex}
                        divider
                        classes={{root: DocumentPreviewStyles.layersMenuItem}}
                        disabled={
                            fetchDownloadTokensStatus === RequestStatus.PENDING ||
                            fetchDownloadFileStatus === RequestStatus.PENDING ||
                            disabledMenu
                        }
                        onClick={() => {
                            !allFilesLoadedStatus && setDisabledMenu(true);
                            changeFileIndex(item.index);
                            handleCloseLayers();
                        }}
                    >
                        {item.name}
                        <ListItemSecondaryAction classes={{root: DocumentPreviewStyles.layersMenuItemIcon}}>
                            <FileIcon/>
                        </ListItemSecondaryAction>
                    </MenuItem>
                ))
            ) : (
                <MenuItem classes={{root: DocumentPreviewStyles.layersMenuItem}}>{t('common.empty')}</MenuItem>
            )}
        </Menu>
    </>
}

export default DocumentPreviewControls
