export default {
    defaultProps: {
        textColor: 'primary',
    },
    styleOverrides: {
        root: {
            minHeight: 48,
        },
        scroller: {},
        indicator: {
            display: 'none',
        },
    },
};
