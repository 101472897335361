import React from 'react';
import {RootState} from "../store";
import {PaperDataGridItems, TextActionType} from "../../components/PaperDataGrid/PaperDataGrid";
import {getDocumentActionName, mapDocumentStatusTypeToChipType, mapRoleToChipType} from "../../utils/global";
import {DocumentDataModel, DocumentStatusType, participantsDtoType} from "../../models/document.model";
import {TFunction} from "i18next";
import moment from "moment";
import VerificationButton from "../../components/VerificationButton/VerificationButton";
import i18n from "../../translations/i18n";


export const selectDocumentDataSection = (state: RootState, t: TFunction): PaperDataGridItems | undefined => {
    if (!state.documentReducer?.documentData || state.documentReducer.documentData.length === 0) return undefined
    const data = state.documentReducer.documentData[0];
    const isCertificate = data.type === 'dsl'
    const result = [];

    if (data.name !== '') {
        result.push({
            label: t('documentPage.documentName'),
            tooltip: t('documentPage.documentNameTooltip'),
            values: [{
                titleVal: data.name,
                titleActionType: TextActionType.Copy
            }]
        })
    }
    result.push({
        label: t('documentPage.identifier'),
        tooltip: t('documentPage.identifierTooltip'),
        values: [{
            titleVal: data.uuid,
            titleActionType: TextActionType.Copy
        }]
    })
    result.push({
        label: t('documentPage.blockchainAddress'),
        tooltip: t('documentPage.blockchainAddressTooltip'),
        values: [{
            titleVal: data.address,
            titleActionType: TextActionType.Copy
        }]
    })

    if (data.attachedFiles.length > 0) {
        result.push({
            label: t('documentPage.attachedFiles'),
            tooltip: t('documentPage.attachedFilesTooltip'),
            values: data.attachedFiles.map((file) => ({
                titleVal: file.name,
                descriptionVal: file.hash,
                descriptionActionType: TextActionType.Copy,
            }))
        })
    }
    if (data.issued) {
        result.push({
            label: t('documentPage.issued'),
            tooltip: t('documentPage.issuedTooltip'),
            values: [{
                titleVal: data.issued ? moment(data.issued).format('LLL') : '-',
                titleActionType: TextActionType.Copy,
            }]
        })
    }

    if (data.expiresOn)
        result.push({
            label: t('documentPage.expiresOn'),
            tooltip: t('documentPage.expiresOnTooltip'),
            values: [{
                titleVal: data.expiresOn ? moment(data.expiresOn).format('LLL') : '-',
                titleActionType: TextActionType.Copy,
            }]
        })

    if (isCertificate)
        result.push({
            label: t('documentPage.issuer'),
            tooltip: t('documentPage.issuerTooltip'),
            values: [{
                titleVal: data.issuer.workspaceName,
                titleActionType: TextActionType.Copy,
                titleAfterComponent: data.issuer.verified ? <VerificationButton issuer={data.issuer}/> : <></>
            }]
        })


    const documentStatus = mapDocumentStatusTypeToChipType(data.status, t, data.type);

    result.push({
        label: t('documentPage.status'),
        tooltip: t('documentPage.statusTooltip'),
        chips: [{
            short: documentStatus.short,
            long: documentStatus.short,
            variant: documentStatus.variant
        }]
    })


    return result
}

export const selectIssuerModalData = (state: RootState, t: TFunction): PaperDataGridItems | undefined => {
    if (!state.documentReducer?.documentData || state.documentReducer.documentData.length === 0) return undefined
    const data = state.documentReducer.documentData[0];
    const result = [];

    result.push({
        label: t('documentPage.issuerName'),
        tooltip: t('documentPage.issuerNameTooltip'),
        values: [{
            titleVal: data.issuer.workspaceName,
            titleActionType: TextActionType.Copy,
        }]
    })

    if (data.issuer.verified) {
        result.push({
            label: t('documentPage.issuerAddress'),
            tooltip: t('documentPage.issuerAddressTooltip'),
            values: [{
                titleVal: `
                    ${data.issuer.address},\n
                    ${data.issuer.postcode} ${data.issuer.city},\n
                    ${data.issuer.country}\n
                `,
                titleActionType: TextActionType.Copy,
            }]
        })
    }
    if (data.issuer.verified) {
        result.push({
            label: t('documentPage.additionalNotes'),
            tooltip: t('documentPage.additionalNotesTooltip'),
            values: [{
                titleVal: data.issuer.additionalInfo,
                titleActionType: TextActionType.Copy,
            }]
        })
    }


    return result
}

const getParticipantSubtitle = (participant: participantsDtoType): string | undefined => {
    if (participant.invitation) return i18n.t('documentPage.invitationByMail')
    return participant.name !== '' ? `uuid: ${participant.uuid}` : undefined
}

const getParticipantDescription = (participant: participantsDtoType, data: DocumentDataModel): string | undefined => {
    const workspaceName = data.issuer.workspaceName
    if (participant.group) return `${i18n.t('documentPage.groupFromWorkspace')}: ${workspaceName}`;

    return participant.invitation ? participant.name : participant.info;
}
export const selectParticipantsSection = (state: RootState, t: TFunction): PaperDataGridItems[] | undefined => {
    if (!state.documentReducer.documentData || state.documentReducer.documentData.length === 0) return undefined
    const data = state.documentReducer.documentData[0];
    const result: PaperDataGridItems[] = [];


    if (data.participantsDto) {
        data.participantsDto.forEach(participant => result.push([
            {
                values: [{
                    titleVal: participant.name && participant.name !== '' ? participant.name : `uuid: ${participant.uuid}`,
                    subtitle: getParticipantSubtitle(participant),
                    titleActionType: TextActionType.Copy,
                    subtitleActionType: TextActionType.Copy,
                    descriptionVal: getParticipantDescription(participant, data)
                }]
            }, {
                chips: participant.roles.map(role => {
                    const chip = mapRoleToChipType(role, t)

                    return {
                        short: chip.short,
                        long: chip.long,
                        variant: chip.variant
                    }
                })
            },
        ]))
    }
    
    return result;
}


export const selectHistoryDetailsActionName = (state: RootState, t: TFunction): string | undefined => {
    const {documentHistoryDetailsIndex, documentHistory} = state.documentReducer;
    if (!(documentHistoryDetailsIndex !== undefined && documentHistory && documentHistory.events[documentHistoryDetailsIndex])) return undefined

    return getDocumentActionName(documentHistory.events[documentHistoryDetailsIndex].action, t).long
}

export const selectHistoryDetailsData = (state: RootState, t: TFunction): PaperDataGridItems | undefined => {
    const {documentHistoryDetailsIndex, documentHistory} = state.documentReducer;
    if (!(documentHistoryDetailsIndex !== undefined && documentHistory && documentHistory.events[documentHistoryDetailsIndex])) return undefined
    const documentHistoryDetails = documentHistory.events[documentHistoryDetailsIndex]
    const result: PaperDataGridItems = [];

    result.push({
        label: t('common.date'),
        tooltip: t('common.dateTooltip'),
        values: [{
            titleVal: documentHistoryDetails.time ? moment(documentHistoryDetails.time).format('YYYY-MM-DD HH:mm:ss') : '-',
            titleActionType: TextActionType.Copy
        }]
    })

    if (documentHistoryDetails.executor)
        result.push({
            label: t('documentPage.executor'),
            tooltip: t('documentPage.executorTooltip'),
            values: [{
                titleVal: documentHistoryDetails.executor.name !== '' ? documentHistoryDetails.executor.name : documentHistoryDetails.executor.uuid,
                titleActionType: TextActionType.Copy,
                descriptionVal: `${documentHistoryDetails.executor.email}${documentHistoryDetails.executor.name !== ''
                    ? `\nuuid: ${documentHistoryDetails.executor.uuid}` : ''}`,
                descriptionActionType: TextActionType.Copy
            }]
        })

    result.push({
        label: t('documentPage.blockNumber'),
        tooltip: t('documentPage.blockNumberTooltip'),
        values: [{
            titleVal: documentHistoryDetails.blockNumber.toString(),
            titleActionType: TextActionType.Copy
        }]
    })

    if (documentHistoryDetails.txIndex !== undefined)
        result.push({
            label: t('documentPage.txIndex'),
            tooltip: t('documentPage.txIndexTooltip'),
            values: [{
                titleVal: documentHistoryDetails.txIndex.toString(),
                titleActionType: TextActionType.Copy
            }]
        })

    if (documentHistoryDetails.publicKey)
        result.push({
            label: t('documentPage.publicKey'),
            tooltip: t('documentPage.publicKeyTooltip'),
            values: [{
                titleVal: documentHistoryDetails.publicKey,
                titleActionType: TextActionType.Copy
            }]
        })

    if (documentHistoryDetails.signature)
        result.push({
            label: t('documentPage.signature'),
            tooltip: t('documentPage.signatureTooltip'),
            values: [{
                titleVal: documentHistoryDetails.signature,
                titleActionType: TextActionType.Copy
            }]
        })
    // result.push({
    //     label: 'Document name',
    //     tooltip: 'Document name Document name',
    //     values: [{
    //         titleVal: 'Document #68775',
    //         titleActionType: TitleActionType.Copy
    //     }]
    // })
    return result;
}

export const selectDocumentStatus = (state: RootState): DocumentStatusType | undefined => {
    if (!state.documentReducer.documentData || state.documentReducer.documentData.length === 0) return undefined
    return state.documentReducer.documentData[0].status
}

export const selectDocumentType = (state: RootState): string | undefined => {
    if (!state.documentReducer.documentData || state.documentReducer.documentData.length === 0) return undefined
    return state.documentReducer.documentData[0].type
}

export const selectDocumentUUID = (state: RootState): string | undefined => {
    if (!state.documentReducer.documentData || state.documentReducer.documentData.length === 0) return undefined
    return state.documentReducer.documentData[0].uuid
}

export const selectIsCertificate = (state: RootState): boolean | undefined => {
    if (!state.documentReducer.documentData || state.documentReducer.documentData.length === 0) return undefined
    return state.documentReducer.documentData[0].type === 'dsl'
}

export const selectIsUserParticipant = (state: RootState): boolean => {
    if (!state.documentReducer.documentData || state.documentReducer.documentData.length === 0) return false

    return state.documentReducer.documentData[0].participant
}

export const selectIsPublicDocument = (state: RootState): boolean => {
    if (!state.documentReducer.documentData || state.documentReducer.documentData.length === 0) return false

    return state.documentReducer.documentData[0].publicFile
}

