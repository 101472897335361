export default {
    defaultProps: {
        variant: 'outlined',
        size: 'medium',
        InputProps: {
            rows: 4,
        },
        InputLabelProps: {
            disableAnimation: true,
            shrink: true,
        },
    }
}
