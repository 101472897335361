export default ({palette, typography}) => ({
    styleOverrides: {
        root: {
            fontSize: 12,
            '&$focused': {
                color: palette.primary.main,
            },
            '&$error': {
                fontWeight: typography.fontWeightMedium,
            },
        },
    },
});
