import {alpha} from "@mui/material";

export default ({palette, typography}) => ({
    styleOverrides: {
        html: {
            fontFamily: typography.fontFamily,
            fontSize: typography.htmlFontSize,
            color: palette.text.primary,
        },
        body: {
            'a': {
                textDecoration: 'none',
                color: palette.primary.main,
            },
            'table td, table th': {
                overflowWrap: 'anywhere'
            }, 'strong, b': {
                fontWeight: `${typography.fontWeightBold} !important`,
            },
            'table tbody': {
                fontFamily: 'Inter'
            },
        },
        '*': {
            scrollbarColor: `${palette.primary.main} ${alpha(palette.grey[500], 0.1)}`,
            scrollbarWidth: 'thin',
            '::-moz-selection': {
                background: palette.primary.main,
                color: palette.primary.contrastText,
            },
            '::selection': {
                background: palette.primary.main,
                color: palette.primary.contrastText,
            },
            '::-webkit-scrollbar': {
                width: 6,
                height: 6,
            },
            '::-webkit-scrollbar-thumb': {
                border: 'none',
                background: palette.primary.main,
                borderRadius: 3,
            },
            '::-webkit-scrollbar-thumb:hover': {
                background: palette.primary.dark,
            },
            '::-webkit-scrollbar-track': {
                background: 'transparent',
                borderRadius: 0,
            },
        }
    },
});
