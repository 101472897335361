import React, {useEffect} from "react";
import Router from "./Router";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import SystemAlert from "./components/SystemAlert/SystemAlert";
import {css} from "@emotion/css";
import {useTheme} from "@mui/material";
import CookieConsent from "./components/CookieConsent/CookieConsent";
import {useAppDispatch, useAppSelector} from "./redux/hooks";
import {RequestStatus} from "./models/global.model";
import {authServices} from "./redux/services/auth.services";
import UserComUrlChange from "./components/UserComUrlChange/UserComUrlChange";

const pageWrapperStyle = (bColor: string) => css`
  background-color: ${bColor};
  min-height: 100vh;
  flex-direction: column;
  display: flex;
`

const contentStyle = css`
  flex: 1;
`

function App() {
    const theme = useTheme();
    const dispatch = useAppDispatch();
    const {fetchUserDataStatus} = useAppSelector(state => state.identityReducer)

    useEffect(() => {
        if (fetchUserDataStatus === RequestStatus.IDLE) {
            dispatch(authServices.getProfileData())
        }
    }, [fetchUserDataStatus, dispatch])


    return (
        <div className={pageWrapperStyle(theme.palette.background.default)}>
            <Header/>
            <SystemAlert/>
            <div className={contentStyle}>
                <Router/>
            </div>
            <Footer/>
            <CookieConsent/>
            <UserComUrlChange/>
        </div>
    );
}

export default App;
