import {alpha} from "@mui/material";

export default ({palette}) => ({
    styleOverrides: {
        root: {
            backgroundColor: palette.primary.dark,
            borderBottom: `1px solid ${palette.divider}`,
            color: alpha(palette.common.white, 0.5),
            padding: 16,
        },
        dateTitleContainer: {
            position: 'relative',
        },
        toolbarLandscape: {
            minWidth: 200,
        },
    },
});
