import palette from './palette';
import {alpha} from "@mui/material";

export default {
    leftMenuWidth: 245,
    userMenuMaxWidth: 360,
    fullModalMenuWidth: 350,
    documentEditSidebarWidth: 490,
    headerMobileNavHeight: 76,
    headerNavHeight: 88,
    bottomNavigationHeight: 88,
    tooltipBackground: alpha(palette.common.black, 0.85),
    userMenuHoverBackground: alpha(palette.primary.main, 0.225),
    breadcrumbsMenuHeight: 40,
};
