import Logo from "../Logo/Logo";
import {Container, Typography, useMediaQuery, useTheme} from "@mui/material";
import {FooterStyles} from "./styles";


const Footer = () => {
    const theme = useTheme()
    const smallDevice = useMediaQuery(theme.breakpoints.down('sm'));

    return (<footer>
        <div className={FooterStyles.wrapper(theme.palette.background.paper)}>
            <Container className={FooterStyles.innerWrapper}>
                <Typography variant="body2" className={FooterStyles.text}>
                    Powered by
                </Typography>
                <Logo width={smallDevice ? 100 : 130}
                      redirectOutside="https://doxychain.com"/>
            </Container>
        </div>
    </footer>)
}

export default Footer;
