export default {
    styleOverrides: {
        root: {
            height: 32,
            '&:last-child': {
                marginBottom: '0 !important',
            },
        },
        yearSelected: {
            margin: '8px 0',
        },
    }
};
