import {Action, configureStore, ThunkAction} from '@reduxjs/toolkit';
import thunkMiddleware from 'redux-thunk';
import logger from 'redux-logger';
import uiReducer from "./reducers/ui.reducer";
import documentReducer from "./reducers/document.reducer";
import identityReducer from "./reducers/identity.reducer";
import fileReducer from "./reducers/file.reducer";


export const store = configureStore({
    reducer: {
        ui: uiReducer,
        documentReducer: documentReducer,
        fileReducer: fileReducer,
        identityReducer: identityReducer
    },
    middleware: (getDefaultMiddleware) => {
        if (process.env.NODE_ENV !== 'production') {
            return getDefaultMiddleware().concat(thunkMiddleware).concat(logger)
        }
        return getDefaultMiddleware().concat(thunkMiddleware)
    },
    devTools: process.env.NODE_ENV !== 'production',
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType,
    RootState,
    unknown,
    Action<string>>;
