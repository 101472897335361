import React from 'react';
import {Avatar, lighten, Stack} from "@mui/material";
import {
    amber,
    blue,
    blueGrey,
    cyan,
    deepOrange,
    deepPurple,
    green,
    indigo,
    lightBlue,
    lightGreen,
    lime,
    orange,
    pink,
    purple,
    red,
    teal,
    yellow,
} from "@mui/material/colors";
import {UserData} from "../../models/identity.model";
import cookieHelper from "../../utils/cookieHelper";

const activeIdentity = cookieHelper.getCookie('activeIdentity');

type UserAvatarProps = {
    userData: UserData
}

const UserAvatar = ({userData}: UserAvatarProps) => {

    const defaultColor = blueGrey[600];
    const colors = [
        red[600],
        pink[600],
        purple[600],
        deepPurple[600],
        indigo[600],
        blue[600],
        lightBlue[600],
        cyan[600],
        teal[600],
        green[600],
        lightGreen[600],
        lime[600],
        yellow[600],
        amber[600],
        orange[600],
        deepOrange[600],
    ];

    const colorFromText = (text: string) => {
        const charCodes = String(text)
            .split('')
            .map((char) => char.charCodeAt(0))
            .join('');

        return parseInt(charCodes, 10);
    };

    const returnChildren = () => {
        const text = userData.login || userData.userName
        if (typeof text === 'string') {
            const splitText = text.replace(/[^a-zA-Z0-9À-ž ]/g, '').split(/\s+/);

            return `${splitText[0].charAt(0).toUpperCase()}${
                splitText?.length > 1 ? splitText[1].charAt(0).toUpperCase() : ''
            }`;
        }
        return text;
    };
    const avatarInitials = returnChildren();

    const avatarSrc = `${process.env.REACT_APP_AVATAR_URL}/identity/${activeIdentity}?kind=SMALL`
    const colorByChild = avatarInitials ? colors[colorFromText(avatarInitials) % colors.length] : defaultColor;

    return <Stack direction="row" spacing={2}>
        <Avatar src={avatarSrc}
                sx={{
                    bgcolor: lighten(colorByChild, 0.825), color: colorByChild,
                }}>
            {avatarInitials}
        </Avatar>
    </Stack>
}

export default UserAvatar;