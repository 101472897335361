import {breakpointValues} from '../breakpoints';


export default {
    styleOverrides: {
        toolbarLandscape: {
            padding: 16,
            [`@media (min-width: ${breakpointValues.md}px)`]: {
                padding: 24
            },

        },
    },
};
