import {css} from "@emotion/css";
import {mediaQueryMax} from "../../theme/options/breakpoints";


const wrapper = (bColor: string) => css`
  display: flex;
  align-items: center;
  height: 60px;
  background-color: ${bColor};
  box-shadow: rgb(0 0 0 / 3%) 0px 3px 5px -1px, rgb(0 0 0 / 3%) 0px 6px 10px 0px, rgb(0 0 0 / 5%) 0px 1px 5px 0px;
  margin-top: 40px;

  ${mediaQueryMax.sm} {
    height: 40px;
  }
`
const innerWrapper = css`
  display: flex;
  align-items: center;
`

const text = css`
  margin-right: 8px;
`

export const FooterStyles = {wrapper, text, innerWrapper}
