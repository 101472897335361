import {css} from "@emotion/css";
import {breakpointValues, mediaQueryMin} from "../../theme/options/breakpoints";
import {alpha} from "@mui/material";

const iconBox = css`
  position: relative;
  margin-bottom: 24px;
`
const icon = css`
  font-size: 58px;
`

const smallIconBox = css`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  position: absolute;
  right: 6px;
  bottom: 10px;
`
const smallIcon = (color: string) => css`
  font-size: 12px;
  color: ${color}
`
const textSmall = css`
  font-size: 12px;
`
const dropzone = (background: string, gradientColor: string) => css`
  position: relative;
  padding: 40px 24px;
  border: none !important;
  outline: none !important;
  box-sizing: border-box;
  border-radius: 6px;
  overflow: hidden;
  cursor: grabbing;
  justify-content: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  transition: background .25s ease;
  background: linear-gradient(to right, ${gradientColor} 33%, #fff 0%) top/14px 1px repeat-x,
  linear-gradient(${gradientColor} 33%, #fff 0%) right/1px 14px repeat-y,
  linear-gradient(to right, ${gradientColor} 33%, #fff 0%) bottom/14px 1px repeat-x,
  linear-gradient(${gradientColor} 33%, #fff 0%) left/1px 14px repeat-y;
  background-color: ${background};


  &:focus, &:active {
    border: 0.5px dashed ${gradientColor},
  }

  ${mediaQueryMin.sm} {
    padding: 32px 24px;
  }

  ${mediaQueryMin.xs} {
    padding: 24px 16px;
    min-height: ${breakpointValues.xs * 0.5}px;
  }
`
const dropzoneAccept = (backgroundColor: string) => css`
  background: linear-gradient(to right, ${alpha(
          backgroundColor,
          0.48
  )} 33%, #fff 0%) top/14px 1px repeat-x,
  linear-gradient(${alpha(backgroundColor, 0.48)} 33%, #fff 0%) right/1px 14px repeat-y,
  linear-gradient(to right, ${alpha(
          backgroundColor,
          0.28
  )} 33%, #fff 0%) bottom/14px 1px repeat-x,
  linear-gradient(${alpha(backgroundColor, 0.48)} 33%, #fff 0%) left/1px 14px repeat-y;
  background-color: ${alpha(backgroundColor, 0.08)};
`

export const DropzoneStyles = {
    dropzone,
    dropzoneAccept,
    iconBox,
    icon,
    smallIconBox,
    smallIcon,
    textSmall
}
