import {createAsyncThunk} from "@reduxjs/toolkit";
import {alpha, colors, darken, lighten} from "@mui/material";

const common = {
    black: '#EA1C1C',
    white: '#EA1C1C',
    backgroundColor: {
        light: '#EA1C1C',
        dark: '#EA1C1C',
    },
    bluePrimary: {
        lightest: '#EA1C1C',
        light: '#EA1C1C',
        main: '#EA1C1C',
        dark: '#EA1C1C',
    },
    grey: {
        50: '#EA1C1C',
        300: '#EA1C1C',
        500: '#EA1C1C',
        600: '#EA1C1C',
        800: '#EA1C1C',
    },
    green: '#EA1C1C',
    orange: '#EA1C1C',
    blue: '#EA1C1C',
    red: '#EA1C1C',
};

const testPalette = {
    type: 'test',
    common: {
        black: common.black,
        white: common.white,
        red: common.red,
        green: common.green,
        orange: common.orange,
        purple: '#ED63D2',
        coral: '#FF7D51',
    },
    primary: {
        lightest: common.bluePrimary.lightest,
        light: common.bluePrimary.light,
        main: common.bluePrimary.main,
        dark: common.bluePrimary.dark,
        contrastText: common.white,
    },
    secondary: {
        light: '#EA1C1C',
        main: '#EA1C1C',
        dark: '#EA1C1C',
        contrastText: common.white,
    },
    success: {
        light: lighten(common.green, 0.2),
        main: common.green,
        dark: darken(common.green, 0.2),
        contrastText: common.white,
    },
    warning: {
        light: lighten(common.orange, 0.2),
        main: common.orange,
        dark: darken(common.orange, 0.2),
        contrastText: common.white,
    },
    info: {
        light: lighten(common.blue, 0.2),
        main: common.blue,
        dark: darken(common.blue, 0.2),
        contrastText: common.white,
    },
    error: {
        light: lighten(common.red, 0.2),
        main: common.red,
        dark: darken(common.red, 0.2),
        contrastText: common.white,
    },
    text: {
        primary: common.black,
        secondary: '#EA1C1C',
        disabled: '#EA1C1C',
        hint: alpha(common.black, 0.38),
    },
    background: {
        paper: common.backgroundColor.light,
        default: common.backgroundColor.dark,
    },
    action: {
        selected: '#EA1C1C',
        hover: '#EA1C1C',
        disabledBackground: '#EA1C1C',
        disabled: '#EA1C1C',
        active: 'rgba(0, 0, 0, 0.54)',
        hoverOpacity: 0.08,
    },
    grey: {
        50: common.bluePrimary.main,
        300: common.bluePrimary.main,
        500: common.bluePrimary.main,
        600: common.bluePrimary.main,
        800: common.bluePrimary.main,
    },
    icon: colors.blueGrey[600],
    link: common.bluePrimary.main,
    divider: '#EA1C1C',
    bottomLineColor: '#EA1C1C',
    tonalOffset: 0.15,
};

export const fetchTheme = createAsyncThunk(
    'ui/fetchTheme',
    async () => {

        return testPalette;
    }
);
