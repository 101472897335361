import {css} from "@emotion/css";
import {breakpointValues, mediaQueryMin} from "../../theme/options/breakpoints";

const root = css`
  min-height: 400px;
  padding: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
`
const inner = css`
  min-width: ${breakpointValues.sm};

  & > *:not(:last-child) {
    margin-bottom: 24px;

    ${mediaQueryMin.sm} {
      margin-bottom: 32px;
    }

    ${mediaQueryMin.lg} {
      margin-bottom: 40px;
    }
  }
`
const image = css`
  min-height: 32px;
`
const imageContainer = css`
  img {
    display: block;
    margin: 0 auto;
    width: 100%;
    max-width: 160px;

    ${mediaQueryMin.xs} {
      max-width: 200px;
    }

    ${mediaQueryMin.sm} {
      max-width: 240px;
    }

    ${mediaQueryMin.lg} {
      max-width: 280px;
    }
  }
`
const imageLoader = css`
  margin: 0 auto;

`
const disabled = css`
  opacity: 0;
  display: none !important;
`
const button = css`
  width: 100%;
  max-width: 300px;
`

const emptyImageIcon = css`
  font-size: 64px;
  color: #E7EAF4;

  ${mediaQueryMin.xs} {
    font-size: 88px;
  }

  ${mediaQueryMin.xs} {
    font-size: 112px;
  }
`

export default {
    root,
    inner,
    image,
    imageContainer,
    imageLoader,
    button,
    disabled,
    emptyImageIcon
}
