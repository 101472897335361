import {
    Fab,
    lighten,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TablePagination,
    TableRow,
    useTheme,
} from "@mui/material";
import React from "react";
import {createData, Data} from "./utils";
import EnhancedTableHead from "./components/EnhancedTableHead";
import {ChevronRight} from "../CustomIcon";
import {SearchResultsStyles} from "./styles";
import {getComparator, Order, stableSort} from "../../utils/table";
import {useNavigate} from "react-router-dom";
import {RoutesObj} from "../../Router";
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import {DocumentDataModel} from "../../models/document.model";
import DocumentStatus from "../DocumentStatus/DocumentStatus";
import {resetSearch} from "../../redux/reducers/document.reducer";
import {useTranslation} from "react-i18next";
import moment from "moment";

const SearchResultsTable = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const {t} = useTranslation();
    const {documentData: searchResults} = useAppSelector(state => state.documentReducer)
    const [order, setOrder] = React.useState<Order>('desc');
    const [orderBy, setOrderBy] = React.useState<keyof Data>('date');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const theme = useTheme();

    const rows = searchResults ?
        searchResults.map(((item: DocumentDataModel) =>
            createData({
                identifier: item.uuid,
                date: item.created,
                status: item.status,
                address: item.address,
                type: item.type
            }))) : [];

    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: keyof Data,
    ) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };


    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const onItemClick = (identifier: string) => {
        dispatch(resetSearch())
        navigate(RoutesObj.DocumentPage.toPath(identifier))
    }


    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    return <Paper className={SearchResultsStyles.container} elevation={12}>
        <TableContainer>
            <Table
                sx={{minWidth: 750}}
                aria-labelledby="tableTitle"
                size={'medium'}
            >
                <EnhancedTableHead
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    rowCount={rows.length}
                />
                <TableBody>
                    {stableSort(rows, getComparator(order, orderBy))
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row: Data, index) => {
                            return (
                                <TableRow
                                    hover
                                    role="checkbox"
                                    tabIndex={-1}
                                    className={SearchResultsStyles.row(theme.palette.primary.main, lighten(theme.palette.primary.main, 0.925))}
                                    key={`${row.identifier}_${index}`}
                                    onClick={() => onItemClick(row.identifier)}
                                >
                                    <TableCell>{row.identifier}</TableCell>
                                    <TableCell>
                                        <DocumentStatus status={row.status} type={row.type}/>
                                    </TableCell>
                                    <TableCell>{row.date ? moment(row.date).format('YYYY-MM-DD HH:mm:ss') : '-'}</TableCell>
                                    <TableCell>
                                        <Fab size={"small"} color={"primary"}>
                                            <ChevronRight className={SearchResultsStyles.tableButtonIcon}/>
                                        </Fab>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    {emptyRows > 0 && (
                        <TableRow
                            style={{
                                height: (53) * emptyRows,
                            }}
                        >
                            <TableCell colSpan={6}/>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </TableContainer>
        <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            labelRowsPerPage={t('common.rowsPerPage')}
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
        />
    </Paper>
}

export default SearchResultsTable;
