import {css} from "@emotion/css";
import {mediaQueryMax} from "../../theme/options/breakpoints";


const container = css`
  padding: 32px 40px;
  height: 100%;

  & tr:hover td {
    color: inherit;
  }

  ${mediaQueryMax.sm} {
    padding: 15px;
  }
`

const item = (smallPadding: boolean) => css`

  ${smallPadding && `
    & td {
        padding: 8px 0;
        border: none;
    }
  `}
`

const itemFirstColumn = css`
  width: 1px;
  white-space: nowrap;
  color: #576273;
  padding: 25px 16px;

  ${mediaQueryMax.sm} {
    width: 100%;
    min-width: 100px;
    white-space: pre-line;
    padding: 10px 5px 0;
    float: left;
    border-bottom: none;
  }
`
const itemSecondColumn = css`
  font-family: 'Inter', sans-serif;
  padding: 8px 0 0 0 !important;

  ${mediaQueryMax.sm} {
    padding: 10px 0;
    width: 100%;
    float: left;
    border-bottom: 1px solid #F5F6FA;
  }
`
const labelWrapper = css`
  display: flex;
  align-items: center;

  ${mediaQueryMax.sm} {
    align-items: flex-start;
  }
`
const columnBreak = css`
  height: 16px;
`
const chip = css`
  margin-right: 8px;
`
const titleWrapper = css`
  display: flex;

  ${mediaQueryMax.sm} {
    display: block;
  }
`
const itemAction = (color: string) => css`
  display: flex;
  align-items: center;

  &:hover * {
    cursor: pointer;
    color: ${color};
  }
`


export const PaperDataGridStyles = {
    container,
    item,
    itemFirstColumn,
    itemSecondColumn,
    labelWrapper,
    columnBreak,
    itemAction,
    chip,
    titleWrapper
}
