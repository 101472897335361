export default ({palette}) => ({
    styleOverrides: {
        pickerView: {
            minHeight: 280,
        },
        pickerViewLandscape: {
            padding: 0,
            boxShadow: `inset 0px -1px 0px ${palette.divider}`,
        },
    },
});
