import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {authServices} from "../services/auth.services";
import {UserData} from "../../models/identity.model";
import {RequestStatus} from "../../models/global.model";

export interface IdentityState {
    isLoggedIn: boolean,
    userData?: UserData,
    fetchUserDataStatus: RequestStatus,
}

const initialState: IdentityState = {
    isLoggedIn: false,
    userData: undefined,
    fetchUserDataStatus: RequestStatus.IDLE
};

export const identitySlice = createSlice({
    name: 'identity',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(authServices.getProfileData.pending, (state) => {
            state.isLoggedIn = false;
            state.fetchUserDataStatus = RequestStatus.PENDING
        }).addCase(authServices.getProfileData.fulfilled, (state, action: PayloadAction<UserData>) => {
            state.isLoggedIn = true;
            state.userData = action.payload;
            state.fetchUserDataStatus = RequestStatus.SUCCESS
        }).addCase(authServices.getProfileData.rejected, (state) => {
            state.isLoggedIn = false;
            state.fetchUserDataStatus = RequestStatus.ERROR
        })
    },
});


export default identitySlice.reducer;
