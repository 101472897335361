import {breakpointValues} from '../breakpoints';
import {Slide} from "@mui/material";

export default {
    defaultProps: {
        anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
        },
        transitionDuration: {
            enter: 200,
            exit: 200,
        },
        TransitionComponent: Slide,
    },
    styleOverrides: {
        root: {
            width: '100%',
            maxWidth: breakpointValues.md,
            left: 0,
            right: 0,
            '& > *': {
                width: '100%',
                [`@media (min-width: ${breakpointValues.xs}px)`]: {
                    borderRadius: '0 !important',
                },
            },
            [`@media (min-width: ${breakpointValues.sm}px)`]: {
                left: 12,
                right: 12,
                '& > *': {
                    width: `calc(100% - 24px)`,
                },
            },
            [`@media (min-width: ${breakpointValues.md}px)`]: {
                left: 24,
                right: 24,
                '& > *': {
                    width: `calc(100% - 24px)`,
                },
            },

        },
        anchorOriginTopCenter: {
            top: 0,
            [`@media (min-width: ${breakpointValues.sm}px)`]: {
                top: 12,
                left: '50%',
                right: 'auto',
                transform: 'translateX(-50%)',
            },
            [`@media (min-width: ${breakpointValues.md}px)`]: {
                top: 24,

            },

        },
        anchorOriginBottomCenter: {
            bottom: 0,
            [`@media (min-width: ${breakpointValues.sm}px)`]: {
                bottom: 12,
                left: '50%',
                right: 'auto',
                transform: 'translateX(-50%)',
            },
            [`@media (min-width: ${breakpointValues.md}px)`]: {
                bottom: 24,
            },
        },
        anchorOriginTopRight: {
            top: 0,
            right: 0,
            [`@media (min-width: ${breakpointValues.sm}px)`]: {
                left: 'auto',
                top: 12,
                right: 12,
            },
            [`@media (min-width: ${breakpointValues.md}px)`]: {
                top: 12,
                right: 12,
            },
        },
        anchorOriginBottomRight: {
            bottom: 0,
            right: 0,
            [`@media (min-width: ${breakpointValues.sm}px)`]: {
                left: 'auto',
                bottom: 12,
                right: 12,
            },
            [`@media (min-width: ${breakpointValues.md}px)`]: {
                bottom: 12,
                right: 12,
            },
        },
        anchorOriginTopLeft: {
            top: 0,
            left: 0,
            [`@media (min-width: ${breakpointValues.sm}px)`]: {
                right: 'auto',
                top: 12,
                left: 12,
            },
            [`@media (min-width: ${breakpointValues.md}px)`]: {
                top: 24,
                left: 24,
            },

        },
        anchorOriginBottomLeft: {
            bottom: 0,
            left: 0,
            [`@media (min-width: ${breakpointValues.sm}px)`]: {
                right: 'auto',
                bottom: 12,
                left: 12,
            },
            [`@media (min-width: ${breakpointValues.md}px)`]: {
                bottom: 24,
                left: 24,
            },
        },
    },
};
