export default ({palette}) => ({
    styleOverrides: {
        root: {
            marginLeft: 0,
            marginRight: 0,
            backgroundColor: 'transparent',
            color: palette.text.secondary,
            borderRadius: 2,
            '&:hover, &:focus': {
                backgroundColor: 'transparent',
                color: palette.primary.main,
            },
            '&:active': {
                backgroundColor: 'transparent',
            },
        },
        icon: {
            width: 24,
            height: 14,
        },
    },
});
