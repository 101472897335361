import {css} from "@emotion/css";
import {mediaQueryMax} from "../../theme/options/breakpoints";

const wrapper = css`
  position: fixed;
  width: 100%;
  bottom: 0;
  z-index: 1;
  padding: 15px;
  background-color: #e6f3ff;
`

const content = css`
  display: flex;
  padding-bottom: 10px;


  ${mediaQueryMax.sm} {
    flex-flow: row wrap;
  }
`
const description = css`
  padding: 8px 0;
  flex: 1 1 auto;
  min-width: 0;
`
const button = css`
  flex: 0 0 auto;
`

const link = (color: string) => css`
  color: ${color}
`

export const CookieConsentStyles = {
    wrapper,
    description,
    content,
    button,
    link
}
