import React, {KeyboardEvent, useCallback, useRef, useState} from 'react';
import {
    Avatar,
    Fab,
    InputAdornment,
    List,
    ListItemAvatar,
    ListItemButton,
    ListItemText,
    TextField,
    useTheme
} from "@mui/material";
import {CertificateIcon, FileColor, Search as SearchIcon, Upload} from '../CustomIcon'
import {SearchStyles} from "./styles";
import {useTranslation} from "react-i18next";
import {cx} from "@emotion/css";
import {useNavigate} from "react-router-dom";
import {RoutesObj} from "../../Router";
import {FileRejection, useDropzone} from "react-dropzone";
import {acceptableFileTypes, dropzoneErrors, maxFiles, maxSize} from "../Dropzone/config";
import {useSnackbar} from "notistack";
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import {resetSearch, resetSearchAutoComplete, setSearchFileName} from "../../redux/reducers/document.reducer";
import {documentServices} from "../../redux/services/document.services";
import {resetFiles} from "../../redux/reducers/file.reducer";
import {sha3_256} from "js-sha3";
import moment from "moment";

type DropzoneButtonProps = {
    onDropSuccess: (files: File[]) => void;
}

const DropzoneButton = ({onDropSuccess}: DropzoneButtonProps) => {
    const {enqueueSnackbar} = useSnackbar()


    const onDrop = useCallback((acceptedFiles: File[]) => {
        onDropSuccess(acceptedFiles)
    }, [])

    const onDropRejected = useCallback((rejectedFiles: FileRejection[]) => {
        if (rejectedFiles && rejectedFiles.length) {
            rejectedFiles.forEach((file) => {
                file.errors.forEach((error) => {
                    if (dropzoneErrors[error.code]) {
                        enqueueSnackbar(dropzoneErrors[error.code], {
                            variant: 'error',
                        })
                    }
                });
            });
        }
    }, [])

    const {getRootProps, getInputProps} = useDropzone({
        accept: acceptableFileTypes,
        maxFiles,
        maxSize,
        onDrop, onDropRejected,
        multiple: false,
    })

    return (<InputAdornment {...getRootProps()} position="end">
        <input {...getInputProps()} />
        <Fab
            size={"small"}
            color={"primary"}
        >
            <Upload/>
        </Fab>
    </InputAdornment>)
}

type SearchType = {
    extraPadding?: boolean,
    showUploadIcon?: boolean,
    searchButtonPosition?: 'start' | 'end'
}

const Search = ({extraPadding = false, showUploadIcon = false, searchButtonPosition = 'start'}: SearchType) => {
    const {t} = useTranslation()
    const theme = useTheme();
    const dispatch = useAppDispatch()
    const [value, setValue] = useState('')
    const navigate = useNavigate()
    const inputRef = useRef<HTMLInputElement>(null);
    const [openDropdown, setOpenDropdown] = useState(false)
    const {documentDataAutocomplete} = useAppSelector(state => state.documentReducer)


    const onSearch = (value: string) => {
        if (value.length > 0) {
            dispatch(resetSearch())
            dispatch(resetFiles())
            dispatch(setSearchFileName())
            dispatch(documentServices.fetchDocumentData(value.trim()))
            navigate(RoutesObj.SearchPage.toPath(value.trim()))
            setValue('')
        }
    }

    const onKeyUp = (e: KeyboardEvent) => {
        if (e.key === "Enter") {
            onSearch(value)
            inputRef.current?.blur();
            dispatch(resetSearchAutoComplete())
        } else {
            dispatch(documentServices.fetchDocumentDataAutocomplete(value.trim()))
        }
    }

    const onDropSuccess = (files: File[]) => {
        const file = files[0]
        file.arrayBuffer().then(res => {
            const hash = sha3_256(res).toString().toUpperCase()
            dispatch(resetSearch())
            dispatch(resetFiles())
            dispatch(setSearchFileName(file.name))
            dispatch(documentServices.fetchDocumentData(hash))
            navigate(RoutesObj.SearchPage.toPath(hash))
        })

    }

    return (<div style={{position: 'relative'}}>
        <TextField
            value={value}
            onKeyUp={onKeyUp}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setValue(event.target.value)
            }}
            onFocus={() => setOpenDropdown(true)}
            onBlur={() => {
                setTimeout(() => {
                    setOpenDropdown(false)
                }, 200);
            }}
            inputRef={inputRef}
            placeholder={t("search.placeholder")}
            className={cx({[SearchStyles.searchPadding]: extraPadding}, SearchStyles.search)}
            InputProps={{
                startAdornment: searchButtonPosition === 'start' ? (<InputAdornment position="start">
                    <Fab onClick={() => onSearch(value)}
                         size={"small"}
                    >
                        <SearchIcon/>
                    </Fab>
                </InputAdornment>) : null,
                endAdornment: <>
                    {searchButtonPosition === 'end' ? (<InputAdornment position="start">
                        <Fab onClick={() => onSearch(value)}
                             size={"small"}
                        >
                            <SearchIcon/>
                        </Fab>
                    </InputAdornment>) : null}
                    {showUploadIcon ?
                        <DropzoneButton onDropSuccess={(files: File[]) => onDropSuccess(files)}/> : null}
                </>
            }}
        />

        {documentDataAutocomplete && openDropdown && <div className={SearchStyles.dropdown}>
            <List>
                {documentDataAutocomplete?.map((item, index) => {

                    return <div key={`autocompletelist_${index}`}
                                className={SearchStyles.dropdownItem}>
                        <ListItemButton onClick={() => {
                            dispatch(resetSearchAutoComplete())
                            onSearch(item.uuid)
                        }
                        }>
                            <ListItemAvatar>
                                <Avatar sx={{
                                    border: `1px solid ${theme.palette.grey.A100}`,
                                    backgroundColor: 'white'
                                }}>
                                    {item.type === 'dsl' ? <CertificateIcon/> : <FileColor/>}
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText disableTypography={false} primary={item.uuid}
                                          secondary={moment(item.created).format('YYYY-MM-DD HH:mm:ss')}/>
                        </ListItemButton>
                    </div>
                })}
            </List>

        </div>}
    </div>)
}

export default Search;
