export default {
    styleOverrides: {
        root: {
            justifyContent: 'flex-end',
            paddingBottom: 16,
            paddingLeft: 16,
            paddingRight: 16,
        },
    },
};
