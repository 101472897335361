import {css} from "@emotion/css";
import {mediaQueryMax} from "../../theme/options/breakpoints";

const pageWrapper = css`
  padding: 90px 50px;
  margin: 0 -8px;

  ${mediaQueryMax.md} {
    padding: 15px;
  }
`
const sideImageWrapper = css`
  z-index: 0;
  display: flex;
  position: relative;
`
const searchWrapper = css`
  z-index: 1;
`
const sideImage = css`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);

  ${mediaQueryMax.md} {
    display: none;
  }
`

export const HomePageStyles = {
    pageWrapper,
    searchWrapper,
    sideImage,
    sideImageWrapper
}
