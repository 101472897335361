import {Alert as AlertIcon,} from '../../../components/CustomIcon';
import {colors} from "@mui/material";


import typography from '../typography';

export default {
    defaultProps: {
        variant: 'filled',
        elevation: 0,
        iconMapping: {
            error: <AlertIcon sx={{color: colors.common.white}}/>,
            info: <AlertIcon sx={{color: colors.common.white}}/>,
            success: <AlertIcon sx={{color: colors.common.white}}/>,
            warning: <AlertIcon sx={{color: colors.common.white}}/>,
        },
    },
    styleOverrides: {
        root: {
            borderRadius: 2,
            fontWeight: typography.fontWeightRegular,
        },
        filledSuccess: {
            fontWeight: typography.fontWeightRegular,
        },
        filledInfo: {
            fontWeight: typography.fontWeightRegular,
        },
        filledWarning: {
            fontWeight: typography.fontWeightRegular,
        },
        filledError: {
            fontWeight: typography.fontWeightRegular,
        },
        icon: {
            padding: '8px 0',
            fontSize: 21,
            '& > svg': {
                fontSize: 'inherit',
            },
        },
        action: {
            display: 'block',
            marginRight: -9,
            '& button': {
                margin: '3px 0 0 0 !important',
                color: 'inherit',
            },
        },
        message: {
            fontSize: 14,
        },
    },
};
