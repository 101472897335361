export default ({palette}) => ({
    styleOverrides: {
        root: {},
        rangeCalendarContainer: {
            '&:not(:last-child)': {
                borderRight: `1px solid ${palette.divider}`,
            },
        },
        calendar: {
            minWidth: 280,
            minHeight: 240,
        },
        arrowSwitcher: {
            padding: 16,
            backgroundColor: palette.background.default,
            borderBottom: `1px solid ${palette.divider}`,
        },
    },
});
