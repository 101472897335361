import {breakpointValues} from "../breakpoints";

export default ({typography}) => ({
    styleOverrides: {
        root: {
            padding: '40px 40px 25px 40px',
            [`@media (max-width: ${breakpointValues.sm}px)`]: {
                padding: '35px 15px 20px 15px',
            },
            '& h2': {
                fontSize: typography.h2.fontSize,
            },
        },
    },
});
