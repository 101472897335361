import {DocumentPreviewStyles} from "../DocumentPreviewStyles";
import {CircularProgress, LinearProgress} from "@mui/material";
import React from "react";
import {useAppSelector} from "../../../redux/hooks";
import {RequestStatus} from "../../../models/global.model";

const DocumentPreviewLoader = () => {
    const {
        downloadFiles,
        fetchDownloadFileStatus
    } = useAppSelector((state) => state.documentReducer, undefined);
    const isLoading = fetchDownloadFileStatus === RequestStatus.PENDING;
    if (!isLoading) return null;

    return downloadFiles.length === 0 ? (
        <div className={DocumentPreviewStyles.firstLoader}>
            <CircularProgress size={48}/>
        </div>
    ) : (
        <div className={DocumentPreviewStyles.loaderFile}>
            <LinearProgress/>
        </div>
    )
}

export default DocumentPreviewLoader;
