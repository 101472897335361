import {DocumentImageStyles} from "./styles";
import PreviewLoggedIn from "./subcomponents/PreviewLoggedIn";
import PreviewNotLoggedIn from "./subcomponents/PreviewNotLoggedIn";
import {useAppSelector} from "../../redux/hooks";
import {selectIsPublicDocument, selectIsUserParticipant} from "../../redux/selectors/document.selector";

const DocumentImage = () => {
    const {documentData} = useAppSelector(state => state.documentReducer)
    const isParticipant = useAppSelector(selectIsUserParticipant);
    const isPublic = useAppSelector(selectIsPublicDocument);

    if (!documentData || documentData.length == 0) return null;

    return <div className={DocumentImageStyles.wrapper}>
        {(isParticipant || isPublic) ? <PreviewLoggedIn/> : <PreviewNotLoggedIn/>}
    </div>
}

export default DocumentImage;
