import {breakpointValues} from '../breakpoints';

export default {
    styleOverrides: {
        root: {
            paddingTop: 16,
            paddingLeft: 16,
            paddingRight: 16,
            paddingBottom: 16,
            '&:last-child': {
                paddingBottom: 16,
            },
            [`@media (min-width: ${breakpointValues.sm}px)`]: {
                paddingTop: 16,
                paddingLeft: 16,
                paddingRight: 16,
                paddingBottom: 16,
            },
            [`@media (min-width: ${breakpointValues.md}px)`]: {
                paddingTop: 24,
                paddingLeft: 24,
                paddingRight: 24,
                paddingBottom: 24,
                '&:last-child': {
                    paddingBottom: 24,
                },
            },
            [`@media (min-width: ${breakpointValues.lg}px)`]: {
                paddingTop: 40,
                paddingLeft: 40,
                paddingRight: 40,
                paddingBottom: 40,
                '&:last-child': {
                    paddingBottom: 40,
                },
                '& + [class^="MuiCardActions-root"], & + [class*="MuiCardActions-root"]': {
                    marginTop: -16,
                },
            },
        },
    },
};
