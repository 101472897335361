import React from "react";
import {Navigate, Route, Routes} from "react-router-dom";
import HomePage from "./pages/HomePage/HomePage";
import SearchPage from "./pages/SearchPage/SearchPage";
import DocumentPage from "./pages/DocumentPage/DocumentPage";

export const RoutesObj = {
    HomePage: {
        toPath: () => '/',
        path: '/',
    },
    SearchPage: {
        toPath: (term: string) => `/search/${term}`,
        path: '/search/:term',

    },
    DocumentPage: {
        toPath: (documentId: string) => `/document/${documentId}`,
        path: '/document/:documentId',

    }
}

function Router() {
    return (
        <Routes>
            <Route path={RoutesObj.HomePage.path} element={<HomePage/>}/>
            <Route path={RoutesObj.SearchPage.path} element={<SearchPage/>}/>
            <Route path={RoutesObj.DocumentPage.path} element={<DocumentPage/>}/>
            <Route path="*" element={<Navigate to={RoutesObj.HomePage.toPath()} replace/>}/>
        </Routes>
    );
}

export default Router;
